import { AxiosError } from 'axios';
import { useCallback, useState } from 'react';

const defaultResponseParser = (response: any) => response;

export const useApi = <T = any>(
  axiosMethod: (...args: any[]) => Promise<T | AxiosError>,
  responseParser: (response: any) => T = defaultResponseParser
) => {
  const [data, setData] = useState<T | null>(null);
  const [error, setError] = useState<AxiosError | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const sendRequest = useCallback<typeof axiosMethod>(
    async (...params: Parameters<typeof axiosMethod>) => {
      try {
        setLoading(true);
        setError(null);
        const response = await axiosMethod(...params);
        const result = responseParser(response);
        setData(result as T);
        return result;
      } catch (err) {
        setError(err as AxiosError);
        return err as AxiosError;
      } finally {
        setLoading(false);
      }

      return new Promise<T>(() => {});
    },
    [axiosMethod, responseParser]
  );

  return { data, error, loading, sendRequest };
};
