import React, { FC, useCallback } from 'react';
import { IAccessPoint, ITreeEntityKey, TreeNodeType } from '../../../../typings/treeNode';
import { findTreeNodeByKey, resolveTreeKey } from '../../../../utils/tree';
import EquipmentTree from '../../../ui/equipmentTree';
import Loader from '../../../ui/loader';
import { ELoaderColor } from '../../../ui/loader/types';
import Select from '../../../ui/select';
import { ISelectOption } from '../../../ui/select/types';
import { SubscriberEditMode } from '../../types';
import { IEquipmentData } from './types';
import { ESystemTabsIds } from '../../../../pages/systems/item/types';
import Input from '../../../ui/input';
import { useAppSelector } from '../../../../hooks/hooks';
import { checkIsAdmin } from '../../../../store/selectors/profile';

const EquipmentData: FC<IEquipmentData> = (props) => {
  const {
    editMode = SubscriberEditMode.viewing,
    keys = [],
    selectedKey,
    onChangeSelectedKey = () => {},
    tree,
    onChangeTree = () => {},
    goToSystemTab = () => {},
    loading = false,
    isHiddenPoints = true,
    selectedKeyIsObject = false,
    permissionObject = {},
    keyName,
    onChangeName = () => {},
    onBlurName = () => {},
  } = props;

  const isAdmin = useAppSelector(checkIsAdmin);

  const handleOnEditTree = useCallback(
    (key: string, updatedNode: IAccessPoint) => {
      if (tree) {
        const resolveKeys: ITreeEntityKey[] = resolveTreeKey(key);
        const updatedTree = [...tree];
        const { parent, nodeIndex } = findTreeNodeByKey(resolveKeys, updatedTree);

        if (parent) {
          const lastKey = resolveKeys[resolveKeys.length - 1];
          if (lastKey.type === TreeNodeType.accessPoint && parent?.accessPoints) {
            parent.accessPoints[nodeIndex] = updatedNode;
          }
        }

        onChangeTree(updatedTree);
      }
    },
    [onChangeTree, tree]
  );

  return (
    <div className="equipment-data">
      {isHiddenPoints &&
        (!permissionObject.edit ? (
          <div className="equipment-data__sync-notify">
            Есть несинхронизированное оборудование. Нужно синхронизировать оборудование в объекте.
          </div>
        ) : (
          <div className="equipment-data__sync-notify">
            Есть несинхронизированное оборудование. Синхронизируйте оборудование на вкладке{' '}
            <span
              role="presentation"
              onClick={() => goToSystemTab(ESystemTabsIds.configurationFiles)}
              className="equipment-data__sync-notify-link"
            >
              «Конфигурирование»
            </span>{' '}
            объекта.
          </div>
        ))}
      <div className="equipment-data__tools-container">
        <Select
          title="Тип настройки"
          containerClassName="equipment-data__select"
          value={selectedKey?.id}
          onChange={onChangeSelectedKey}
          disabled={editMode === SubscriberEditMode.viewing || isAdmin}
          options={keys.map<ISelectOption>((key) => ({
            value: key.id || '',
            title: key.key || '',
          }))}
        />
        {selectedKeyIsObject && (
          <Input
            title="Псевдоним"
            value={keyName?.value}
            isRequired
            placeholder="Псевдоним"
            onChange={onChangeName}
            maxLength={25}
            status={keyName?.status}
            errorText={keyName?.errorText}
            onBlur={onBlurName}
            disabled={isAdmin}
          />
        )}
      </div>
      <div className="equipment-data__content-wrapper">
        {loading && (
          <div className="tabs-custom__loader-wrapper">
            <Loader color={ELoaderColor.blue} />
          </div>
        )}
        {!loading && (
          <EquipmentTree
            data={tree}
            isEditable={editMode !== SubscriberEditMode.viewing}
            onEdit={handleOnEditTree}
            onClickErrorButton={() => goToSystemTab(ESystemTabsIds.accessGroups)}
            permissionObject={permissionObject}
          />
        )}
      </div>
    </div>
  );
};

export default EquipmentData;
