import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useApi } from '../../hooks/useApi';
import { defaultPeriod, defaultTariffSetting, versionPositions } from '../../constants/tariffs';
import { ITariffCommission, ITariffSetting, ITariffSettingsDiscount } from '../../typings/systems/tariff';
import { getTariffs } from '../../api/tariffs';
import { IConfirmData } from '../ui/universalModal/types';
import { defaultConfirm, deleteModal, infoConfirmModal, simpleConfirmModal } from '../ui/universalModal/config';
import { calculateFixedCost, calculateFixedCostElts } from '../../pages/management/tariffsCost/calculate';
import { ITariffCustomSettings, ITariffInfo, ITariffPeriod } from '../../pages/management/tariffsCost/types';
import { tariffCommissionUrl, tariffSettingsUrl } from '../../constants/api';
import UniversalModal from '../ui/universalModal';
import Loader from '../ui/loader';
import { ELoaderColor } from '../ui/loader/types';
import Button from '../ui/button';
import { ButtonType } from '../ui/button/types';
import TariffCost from './tariffCost';
import TariffsPeriods from './tariffPeriod';
import { ITariffItem } from './types';
import Checkbox from '../ui/checkbox';
import { InputStatus } from '../ui/input/types';
import NumberInput from '../ui/input/numberInput';

const TariffItem: FC<ITariffItem> = ({
  systemId = '',
  permissions = {},
  wasChange = false,
  isMaintenance = false,
  maintenancePrice = 0,
  setMaintenance = () => {},
  setMaintenancePrice = () => {},
  customTariffSettings = null,
  setWasChange = () => {},
  onChangeSettings = () => {},
  onSave = () => false,
  organizationId = '',
  isAdmin = false,
}) => {
  const {
    sendRequest: getTariffSettings,
    data: tariffSettings,
    loading: tariffSettingsLoading,
  } = useApi<ITariffSetting[]>(getTariffs);

  const {
    data: tariffCommission,
    sendRequest: getTariffCommission,
    loading: tariffCommissionLoading,
  } = useApi<ITariffCommission[]>(getTariffs);

  const [confirmData, setConfirmData] = useState<IConfirmData>(defaultConfirm);

  const closeConfirm = useCallback(() => setConfirmData(defaultConfirm), []);

  const [maintenancePriceError, setMaintenancePriceError] = useState(false);

  const [commissionRefreshed, setCommissionRefreshed] = useState(false);
  const [settingsRefreshed, setSettingsRefreshed] = useState(false);

  const calculateCostForOrg = useCallback(
    (
      index: number,
      discount: number,
      monthCount: number,
      tariffs: ITariffInfo[],
      maintenanceValue = maintenancePrice
    ): number =>
      calculateFixedCost(
        (tariffCommission && tariffCommission[index].fixCommission) || 0,
        tariffs[index].additionalCompanyPrice || 0,
        monthCount,
        discount,
        maintenanceValue
      ),
    [maintenancePrice, tariffCommission]
  );

  const calculateCostForEllis = useCallback(
    (index: number, monthCount: number): number =>
      calculateFixedCostElts((tariffCommission && tariffCommission[index].fixCommission) || 0, monthCount),
    [tariffCommission]
  );

  const getNewPeriod = useCallback(
    (data: ITariffPeriod, tariffs: ITariffInfo[], maintenanceValue = maintenancePrice): ITariffPeriod => ({
      ...data,
      isMountError: false,
      isDiscountError: false,
      liteCost: calculateCostForOrg(0, data.discount || 0, data.monthCount || 0, tariffs, maintenanceValue),
      mediumCost: calculateCostForOrg(1, data.discount || 0, data.monthCount || 0, tariffs, maintenanceValue),
      comfortCost: calculateCostForOrg(2, data.discount || 0, data.monthCount || 0, tariffs, maintenanceValue),
    }),
    [calculateCostForOrg, maintenancePrice]
  );

  const wasRefreshed = useCallback(() => {
    setCommissionRefreshed(false);
    setSettingsRefreshed(false);
  }, []);

  useEffect(() => {
    setSettingsRefreshed(true);
  }, [tariffSettings]);

  useEffect(() => {
    setCommissionRefreshed(true);
  }, [tariffCommission]);

  const setData = useCallback(
    (copy: any) => {
      if (tariffSettings && tariffCommission && tariffSettings?.length > 0) {
        tariffSettings.sort(
          (a: ITariffSetting, b: ITariffSetting) =>
            (versionPositions.get(a.version) || 0) - (versionPositions.get(b.version) || 0)
        );

        tariffCommission.sort(
          (a: ITariffCommission, b: ITariffCommission) =>
            (versionPositions.get(a.version) || 0) - (versionPositions.get(b.version) || 0)
        );
        copy.periods = tariffSettings[0].objectTariffDiscounts.map((item) =>
          getNewPeriod(
            { ...defaultPeriod, ...item, isNew: false },
            tariffSettings,
            tariffSettings[0].maintenancePrice || 0
          )
        );
        copy.periods.sort(
          (a: ITariffSettingsDiscount, b: ITariffSettingsDiscount) => (a.monthCount || 0) - (b.monthCount || 0)
        );
        tariffSettings.forEach((item, index) => {
          copy.tariffs[index].additionalCompanyPrice = item.additionalCompanyPrice;
          copy.tariffs[index].version = item.version;
          copy.tariffs[index].isMaintenance = item.isMaintenanceOn;
          copy.tariffs[index].maintenancePrice = item.maintenancePrice;
        });

        setMaintenance(tariffSettings[0].isMaintenanceOn);

        setMaintenancePrice(tariffSettings[0].maintenancePrice || 0);
        setWasChange(false);
      }
      onChangeSettings(copy, false);
    },
    [
      getNewPeriod,
      onChangeSettings,
      setMaintenance,
      setMaintenancePrice,
      setWasChange,
      tariffCommission,
      tariffSettings,
    ]
  );

  useEffect(() => {
    if (settingsRefreshed && commissionRefreshed) {
      const copy = JSON.parse(JSON.stringify(defaultTariffSetting));
      setData(copy);
      wasRefreshed();
    }
  }, [settingsRefreshed, commissionRefreshed]);

  const getSettings = useCallback(() => {
    getTariffSettings(tariffSettingsUrl(systemId), isAdmin ? { params: { organizationId } } : null);
  }, [getTariffSettings, isAdmin, organizationId, systemId]);

  const getCommission = useCallback(() => {
    getTariffCommission(tariffCommissionUrl(), isAdmin ? { params: { organizationId } } : null);
  }, [getTariffCommission, isAdmin, organizationId]);

  const getData = useCallback(() => {
    getCommission();
    getSettings();
  }, [getCommission, getSettings]);

  useEffect(() => {
    if (systemId) {
      getData();
    }
  }, [systemId, organizationId]);

  const recalculateSettings = useCallback(
    (settings: ITariffCustomSettings) => {
      settings.periods = settings.periods.map((item) => getNewPeriod(item, settings.tariffs));
      onChangeSettings(settings);
    },
    [getNewPeriod, onChangeSettings]
  );

  const addPeriod = useCallback(() => {
    if (customTariffSettings) {
      const newSettings = { ...customTariffSettings };
      newSettings.periods.unshift({ ...defaultPeriod });
      onChangeSettings(newSettings);
    }
  }, [onChangeSettings, customTariffSettings]);

  const deletePeriod = useCallback(
    (index: number) => {
      if (customTariffSettings) {
        onChangeSettings({
          ...customTariffSettings,
          periods: customTariffSettings.periods.filter((_, i) => i !== index),
        });
      }
    },
    [onChangeSettings, customTariffSettings]
  );

  const tryToDeletePeriod = useCallback(
    (index: number) => () => {
      const element = customTariffSettings?.periods[index];
      if (element) {
        if (element.isNew) {
          deletePeriod(index);
        } else {
          setConfirmData(
            deleteModal(
              'этот период',
              () => {
                deletePeriod(index);
                closeConfirm();
              },
              closeConfirm
            )
          );
        }
      }
    },
    [closeConfirm, deletePeriod, customTariffSettings]
  );

  const handleOnSave = useCallback(async () => {
    if ((isMaintenance && maintenancePrice) || !isMaintenance) {
      if (await onSave()) {
        getSettings();
      }
    } else {
      setMaintenancePriceError(true);
    }
  }, [getSettings, isMaintenance, maintenancePrice, onSave]);

  const findEmptyFields = useCallback(() => {
    let invalidTariffs = false;
    if (tariffCommission && customTariffSettings) {
      const newTariffs = { ...customTariffSettings };
      newTariffs.periods.forEach((item) => {
        if (!item.monthCount) {
          item.isMountError = true;
          invalidTariffs = true;
        }
      });
    }

    return invalidTariffs;
  }, [tariffCommission, customTariffSettings]);

  const checkInvalidPeriods = useCallback(() => {
    let invalidTariffs = false;
    if (tariffCommission && customTariffSettings) {
      const newTariffs = { ...customTariffSettings };
      newTariffs.periods.forEach((item) => {
        if ((item.liteCost || 0) < calculateCostForEllis(0, item.monthCount || 0)) {
          item.isDiscountError = true;
          return (invalidTariffs = true);
        }
        if ((item.mediumCost || 0) < calculateCostForEllis(1, item.monthCount || 0)) {
          item.isDiscountError = true;
          return (invalidTariffs = true);
        }
        if ((item.comfortCost || 0) < calculateCostForEllis(2, item.monthCount || 0)) {
          item.isDiscountError = true;
          return (invalidTariffs = true);
        }
      });
    }

    return invalidTariffs;
  }, [calculateCostForEllis, tariffCommission, customTariffSettings]);

  const tryToSave = useCallback(() => {
    if (findEmptyFields()) {
      setConfirmData(infoConfirmModal('Заполните обязательные поля', closeConfirm));
    } else if (checkInvalidPeriods()) {
      setConfirmData(
        infoConfirmModal(
          'Вы не можете установить данную скидку, так как стоимость месяца не может быть меньше минимальной',
          closeConfirm
        )
      );
    } else {
      handleOnSave();
      closeConfirm();
    }
  }, [checkInvalidPeriods, closeConfirm, findEmptyFields, handleOnSave]);

  const getEmptyTariffs = useCallback((data: ITariffInfo[]) => {
    const emptyTariffs: string[] = [];
    data.forEach((item) => !item.additionalCompanyPrice && emptyTariffs.push(item.version));
    return emptyTariffs;
  }, []);

  const checkEmptyTariffs = useCallback(() => {
    if (customTariffSettings) {
      const emptyTariffs = getEmptyTariffs(customTariffSettings.tariffs);
      if (emptyTariffs.length) {
        const str = emptyTariffs.map((item) => `«${item}»`).join(', ');
        return setConfirmData(
          simpleConfirmModal(
            `Вы уверены, что хотите установить для тарифа ${str} наценку в 0 рублей 0 копеек?`,
            tryToSave,
            closeConfirm
          )
        );
      }

      tryToSave();
    }
  }, [closeConfirm, getEmptyTariffs, customTariffSettings, tryToSave]);

  const onCheck = useCallback(
    (checked: boolean) => {
      setMaintenance(checked);
      if (!checked) {
        setMaintenancePrice(0);
      }
      setMaintenancePriceError(false);
      if (customTariffSettings) {
        recalculateSettings(customTariffSettings);
      }
    },
    [setMaintenance, customTariffSettings, setMaintenancePrice, recalculateSettings]
  );

  const onChangeInput = useCallback(
    (value: number) => {
      setMaintenancePrice(Math.abs(value));
      setMaintenancePriceError(false);
      if (customTariffSettings) {
        recalculateSettings(customTariffSettings);
      }
    },
    [setMaintenancePrice, customTariffSettings, recalculateSettings]
  );

  return (
    <div className="tariff-item">
      <UniversalModal data={confirmData} onClose={closeConfirm} />
      {tariffSettingsLoading || tariffCommissionLoading ? (
        <Loader color={ELoaderColor.blue} />
      ) : !tariffSettings?.length && !tariffCommission?.length ? null : (
        <>
          <div className="tariff-item__maintenance">
            <Checkbox
              label="Техническое обслуживание"
              disabled={!permissions.edit}
              checked={isMaintenance}
              onChange={onCheck}
            />
            <NumberInput
              title="Стоимость техобслуживания (₽)"
              placeholder="0"
              value={maintenancePrice}
              onChange={onChangeInput}
              disabled={!permissions.edit || !isMaintenance}
              status={maintenancePriceError ? InputStatus.error : InputStatus.normal}
              errorText={maintenancePriceError ? 'Введите значение больше 0' : ''}
              isDisabledStyle
            />
          </div>
          <TariffCost
            settings={customTariffSettings}
            permissions={permissions}
            commission={tariffCommission || []}
            maintenancePrice={maintenancePrice}
            isMaintenance={isMaintenance}
            onChange={recalculateSettings}
          />
          <TariffsPeriods
            settings={customTariffSettings}
            permissions={permissions}
            commission={tariffCommission || []}
            onChange={onChangeSettings}
            maintenancePrice={maintenancePrice}
            getNewDiscountItem={getNewPeriod}
            onDeletePeriod={tryToDeletePeriod}
            isMaintenance={isMaintenance}
            addPeriod={addPeriod}
          />
          {permissions.edit && (
            <div className="tab-nav-buttons">
              {wasChange && (
                <Button type={ButtonType.tertiary} onClick={getData}>
                  Отменить
                </Button>
              )}
              <Button onClick={checkEmptyTariffs}>Сохранить</Button>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default TariffItem;
