import React, { FC, useCallback, useEffect, useState } from 'react';
import moment, { Moment } from 'moment';
import { IDeviceTabComponent } from '../types';
import { useApi } from '../../../hooks/useApi';
import { postRequest } from '../../../api';
import { dateFormatNoTime } from '../../../constants/date';
import PeriodDatePicker from '../../ui/periodDatePicker';
import DeviceJournalTable from './journalTable';
import { IApiResponse, IApiSortField } from '../../../typings/api';
import { IDeviceJournalApiParams, IDeviceJournalApiSettings } from '../../../api/deviceJournal/types';
import { getDevicePageApiSettings } from '../../../api/deviceJournal/config';
import { dispatcherPanelEventsUrl } from '../../../constants/api';
import { IDispatcherPanelJournal } from '../../../typings/dispatcherPanel';
import { IDispatcherPanelApiSettings } from '../../../api/dispatcherPanel/types';

const DeviceJournalTab: FC<IDeviceTabComponent> = (props) => {
  const { deviceId = '', tabId, activeTabKey, mainInfo, showInfo } = props;

  // const { data: devices, sendRequest: getDevices, loading: devicesLoading } = useApi<IFlatsPageDevice[]>(getRequest);

  const {
    data: events,
    sendRequest: getEvents,
    loading: eventsLoading,
  } = useApi<IApiResponse<IDispatcherPanelJournal>>(postRequest);

  const [eventsApiParams, setEventsApiParams] = useState<IDeviceJournalApiParams>({
    dateTo: moment()?.toISOString() || '',
    dateFrom: moment()?.subtract(1, 'month')?.startOf('day')?.toISOString() || '',
  });

  const [apiSettings, setApiSettings] = useState<IDeviceJournalApiSettings<IDispatcherPanelJournal>>(
    getDevicePageApiSettings()
  );

  const requestData = useCallback(
    async (newApiSettings = apiSettings, newApiParams = eventsApiParams) => {
      setApiSettings(newApiSettings);
      setEventsApiParams(newApiParams);
      await getEvents(dispatcherPanelEventsUrl(deviceId), newApiSettings, { params: newApiParams });
    },
    [apiSettings, eventsApiParams, getEvents, deviceId]
  );

  useEffect(() => {
    if (tabId === activeTabKey) {
      requestData();
      // getDevices(getFlatDevicesUrl(deviceId));
    }
  }, [tabId, activeTabKey]);

  const handleOnChangeDate = useCallback(
    async (firstDate?: Moment, secondDate?: Moment) => {
      const newEventsParams = {
        ...eventsApiParams,
        dateFrom: firstDate?.startOf('day').toISOString() || '',
        dateTo: secondDate?.endOf('day').toISOString() || '',
      };
      const newApiSettings = { ...apiSettings, page: 0 };
      await requestData(newApiSettings, newEventsParams);
    },
    [apiSettings, eventsApiParams, requestData]
  );

  const handleOnChangeTablePage = useCallback(
    async (page: number) => {
      const newApiSettings = { ...apiSettings, page: page - 1 };
      await requestData(newApiSettings);
    },
    [apiSettings, requestData]
  );

  const handleOnSort = useCallback(
    async (sortResults: IApiSortField<IDispatcherPanelJournal>[]) => {
      const newApiSettings: IDispatcherPanelApiSettings<IDispatcherPanelJournal> = {
        ...apiSettings,
        sortFields: sortResults,
      };
      await requestData(newApiSettings);
    },
    [apiSettings, requestData]
  );

  // const handleOnChangeSelect = useCallback(
  //   (key: string) => (val: any) => {
  //     const newEventsParams = {
  //       ...eventsApiParams,
  //       [key]: val,
  //       page: 0,
  //     };
  //     requestData(newEventsParams);
  //   },
  //   [eventsApiParams, requestData]
  // );

  // const devicesOptions = useMemo(
  //   () =>
  //     devices?.map((object) => ({
  //       value: object.id || '',
  //       title: object.name || '',
  //     })) || [],
  //   [devices]
  // );

  return (
    <div className="device-journal-tab">
      {showInfo && mainInfo}
      <div className="device-journal-tab__filters">
        {/* <Select
            isAllOption
            title="Обрудование"
            value={eventsApiParams?.deviceId || ''}
            onChange={handleOnChangeSelect('deviceId')}
            options={devicesOptions}
          /> */}
        <PeriodDatePicker
          title="Период"
          placeholder="Выберите период"
          firstDate={eventsApiParams?.dateFrom}
          secondDate={eventsApiParams?.dateTo}
          onChange={handleOnChangeDate}
          showTime={false}
          format={dateFormatNoTime}
        />
      </div>
      <DeviceJournalTable
        onSort={handleOnSort}
        sortOrders={apiSettings.sortFields}
        loading={eventsLoading}
        pageSize={events?.pageSize}
        total={events?.totalCount}
        currentPage={events?.page}
        onChangePage={handleOnChangeTablePage}
        journal={events?.items || []}
        deviceId={deviceId}
      />
    </div>
  );
};

export default DeviceJournalTab;
