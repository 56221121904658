import { Moment } from 'moment';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { getDeviceEventEntity } from '../../api/deviceEvent';
import { deviceEventsDefaultApiParams } from '../../api/deviceEvent/config';
import { IDeviceEventApiParams } from '../../api/deviceEvent/types';
import CommonHead from '../../components/commonHead';
import DeviceEventTable from '../../components/deviceEventTable';
import PeriodDatePicker from '../../components/ui/periodDatePicker';
import Select from '../../components/ui/select';
import { ISelectOption } from '../../components/ui/select/types';
import { getDeviceEventAccessPointsUrl, getDeviceEventEventUrl, getDeviceEventSystemUrl } from '../../constants/api';
import { selectAllOptionKey } from '../../constants/select';
import { useAppDispatch } from '../../hooks/hooks';
import { useApi } from '../../hooks/useApi';
import { setHeaderTitle } from '../../store/slices/header';
import { IApiResponse } from '../../typings/api';
import { IDeviceEventAccessPoint, IDeviceEvent, IDeviceEventSystem } from '../../typings/deviceEvent';
import { dateFormatNoTime } from '../../constants/date';

const DeviceEvent: FC = () => {
  const dispatch = useAppDispatch();

  const { data: deviceSystems, sendRequest: sendRequestSystem } = useApi<IDeviceEventSystem[]>(getDeviceEventEntity);
  const { data: accessPoints, sendRequest: sendRequestAccessPoints } =
    useApi<IDeviceEventAccessPoint[]>(getDeviceEventEntity);
  const {
    data: events,
    sendRequest: sendRequestEvents,
    loading: eventsLoading,
  } = useApi<IApiResponse<IDeviceEvent>>(getDeviceEventEntity);
  const [eventsApiParams, setEventsApiParams] = useState<IDeviceEventApiParams>(deviceEventsDefaultApiParams);

  const [selectedDeviceSystemId, setSelectedDeviceSystemId] = useState<string>('');
  const [selectedAccessPointId, setSelectedAccessPointId] = useState<string>(selectAllOptionKey);

  const accessPointsData = useMemo(
    () => [
      { value: selectAllOptionKey, data: 'Все', title: 'Все' },
      ...(accessPoints?.map<ISelectOption>((accessPoint) => ({
        value: accessPoint.id || '',
        title: accessPoint.name || '',
      })) || []),
    ],
    [accessPoints]
  );

  const requestEvents = useCallback(
    async (newApiParams: IDeviceEventApiParams) => {
      setEventsApiParams(newApiParams);
      await sendRequestEvents(getDeviceEventEventUrl(), { params: newApiParams });
    },
    [sendRequestEvents]
  );

  useEffect(() => {
    dispatch(setHeaderTitle('Логирование событий оборудования'));
  }, [dispatch]);

  useEffect(() => {
    sendRequestSystem(getDeviceEventSystemUrl());
  }, []);

  useEffect(() => {
    if (deviceSystems && deviceSystems.length > 0) {
      const systemId = deviceSystems[0].id || '';
      setSelectedDeviceSystemId(systemId);
      sendRequestAccessPoints(getDeviceEventAccessPointsUrl(systemId));
      requestEvents({ ...eventsApiParams, intercomComplexId: systemId });
    }
  }, [deviceSystems]);

  const handleOnChangeDeviceSystem = useCallback(
    async (value: string | number) => {
      const newSystemId: string = value.toString();
      setSelectedDeviceSystemId(newSystemId);
      setSelectedAccessPointId(selectAllOptionKey);
      await sendRequestAccessPoints(getDeviceEventAccessPointsUrl(newSystemId));
      await requestEvents({ ...eventsApiParams, intercomComplexId: newSystemId, accessPointId: undefined });
    },
    [eventsApiParams, requestEvents, sendRequestAccessPoints]
  );

  const handleOnChangeAccessPoint = useCallback(
    async (value: string | number) => {
      const newAccessPointId: string = value.toString();
      setSelectedAccessPointId(newAccessPointId);
      await requestEvents({
        ...eventsApiParams,
        accessPointId: newAccessPointId === selectAllOptionKey ? undefined : newAccessPointId,
      });
    },
    [eventsApiParams, requestEvents]
  );

  const handleOnChangeTablePage = useCallback(
    async (page: number) => {
      await requestEvents({ ...eventsApiParams, pagenumber: page - 1 });
    },
    [eventsApiParams, requestEvents]
  );

  const handleOnChangeDate = useCallback(
    async (firstDate?: Moment, secondDate?: Moment) => {
      await requestEvents({
        ...eventsApiParams,
        dateFrom: firstDate?.startOf('day').toISOString() || '',
        dateTo: secondDate?.endOf('day').toISOString() || '',
      });
    },
    [eventsApiParams, requestEvents]
  );

  return (
    <>
      <CommonHead seo={{ title: 'Логирование событий оборудования' }} />
      <div className="device-event">
        <div className="device-event__tools">
          <Select
            title="Краткое наименование объекта"
            onChange={handleOnChangeDeviceSystem}
            value={selectedDeviceSystemId}
            options={deviceSystems?.map<ISelectOption>((system) => ({
              value: system.id || '',
              title: system.name || '',
            }))}
          />
          <Select
            title="Точка доступа"
            onChange={handleOnChangeAccessPoint}
            value={selectedAccessPointId}
            options={accessPointsData}
          />
          <PeriodDatePicker
            title="Период"
            placeholder="Выберите период"
            firstDate={eventsApiParams.dateFrom || ''}
            secondDate={eventsApiParams.dateTo || ''}
            onChange={handleOnChangeDate}
            showTime={false}
            format={dateFormatNoTime}
          />
        </div>
        <div className="device-event__table">
          <DeviceEventTable
            events={events?.items || []}
            loading={eventsLoading}
            pagination={{
              pageSize: events?.pageSize || 0,
              currentPage: events?.page || 0,
              total: events?.totalCount || 0,
              onChangePage: handleOnChangeTablePage,
            }}
          />
        </div>
      </div>
    </>
  );
};

export default DeviceEvent;
