import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { getSystem } from '../../../api/systems';
import { getObjectBuildingsWithSectionsUrl } from '../../../constants/api';
import { useApi } from '../../../hooks/useApi';
import { ISystemBuilding } from '../../../typings/systems/building';
import { sortBuildingsByName } from '../../../utils/object';
import TabNavButtons from '../../tabs/tabNavButtons';
import { tabNavButtonsDefault } from '../../tabs/tabNavButtons/utils';
import TabsCustom from '../../tabsCustom';
import { ICustomTab } from '../../tabsCustom/types';
import { ISystemTabComponent } from '../types';
import SectionObjectKeys from './sectionObjectKeys';
import KeysUtility from '../../ui/keysUtility';
import { EPagesWithKeys } from '../../ui/keysUtility/types';

const ObjectKeysTab: FC<ISystemTabComponent> = (props) => {
  const {
    systemId = '',
    onNextTab = () => {},
    tabId = '',
    activeTabKey = '',
    updateAvailableTabs = () => {},
    nextTabIsAvailable,
    permissions = {},
  } = props;
  const [activeTabId, setActiveTabId] = useState<string>('');
  const { data: buildings, sendRequest, loading } = useApi<ISystemBuilding[]>(getSystem, sortBuildingsByName);

  const updateBuildings = useCallback(() => {
    if (systemId) {
      sendRequest(getObjectBuildingsWithSectionsUrl(systemId));
    }
  }, [sendRequest, systemId]);

  const resetActiveTabId = useCallback((currBuildings: ISystemBuilding[]) => {
    setActiveTabId(currBuildings.find((item) => item.flatCount > 0)?.id || '');
  }, []);

  useEffect(() => {
    if (tabId === activeTabKey) {
      updateBuildings();
    }
  }, [activeTabKey, tabId]);

  useEffect(() => {
    if (buildings) {
      if (!activeTabId || (activeTabId && (buildings.find((item) => item.id === activeTabId)?.flatCount || 0) < 1)) {
        resetActiveTabId(buildings);
      }
    }
  }, [buildings]);

  return (
    <div className="call-codes-tab">
      <KeysUtility pageStyle={EPagesWithKeys.keysPage} />
      <TabsCustom
        activeTabKey={activeTabId}
        onChangeActiveTab={setActiveTabId}
        loading={loading}
        tabs={buildings?.map<ICustomTab>((building) => ({
          tabId: building.id,
          disabled: !(building.flatCount > 0),
          data: [
            { fieldTitle: 'Строение', fieldData: building.name },
            { fieldTitle: 'Код строения', fieldData: building.prefix },
            { fieldTitle: 'Кол-во подъездов', fieldData: building.sectionCount || '–' },
            { fieldTitle: 'Кол-во квартир', fieldData: building.flatCount || '–' },
          ],
          children: (
            <SectionObjectKeys
              systemId={systemId}
              tabId={tabId}
              activeTabKey={activeTabKey}
              buildingId={building.id}
              sections={building.sections}
              updateAvailableTabs={updateAvailableTabs}
              permissions={permissions}
            />
          ),
        }))}
      />
      <TabNavButtons
        buttons={tabNavButtonsDefault(
          { isHidden: true },
          { isHidden: true },
          {
            isHidden: !permissions.edit,
            callBack: onNextTab,
            classNameOption: 'call-codes-tab__button-next',
            disabled: !nextTabIsAvailable,
          }
        )}
      />
    </div>
  );
};

export default ObjectKeysTab;
