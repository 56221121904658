import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Moment } from 'moment';
import { useAppDispatch, useAppSelector } from '../../hooks/hooks';
import { useApi } from '../../hooks/useApi';
import { getRequest, postRequest, postRequestWithArrayInParams } from '../../api';
import { setHeaderTitle } from '../../store/slices/header';
import CommonHead from '../../components/commonHead';
import InputSearch from '../../components/ui/inputSearch';
import { getFeedbacksCategoryListUrl, getFeedbacksListUrl, getUsersListUrl } from '../../constants/api';
import { IApiResponse, IApiSortField } from '../../typings/api';
import Select from '../../components/ui/select';
import { ISelectOption } from '../../components/ui/select/types';
import { getProfilePermission } from '../../store/selectors/profile';
import { ESidebarItemIds } from '../../typings/sidebar';
import {
  IFeedback,
  IFeedbackCategory,
  mapFeedbackPriorityToText,
  mapFeedbackStatusToText,
} from '../../typings/feedback';
import { IFeedbackApiParams, IFeedbackApiSettings } from '../../api/feedback/types';
import { getFeedbackApiSettings } from '../../api/feedback/config';
import PeriodDatePicker from '../../components/ui/periodDatePicker';
import { dateFormatNoTime } from '../../constants/date';
import { IUser } from '../../typings/users';
import Button from '../../components/ui/button';
import { ButtonType } from '../../components/ui/button/types';
import FeedbacksTable from '../../components/feedback/feedbackTable';
import AddAppealModal from '../../components/feedback/addAppealModal';

const FeedbackTablePage: FC = () => {
  const dispatch = useAppDispatch();

  const [modalIsOpen, setModalIsOpen] = useState(false);

  const [searchValue, setSearchValue] = useState<string>('');

  const {
    data: categories,
    sendRequest: sendCategoriesRequest,
    loading: categoryLoading,
  } = useApi<IFeedbackCategory[]>(getRequest);

  const {
    data: feedbacks,
    sendRequest: getFeedbacks,
    loading: feedbacksLoading,
  } = useApi<IApiResponse<IFeedback>>(postRequestWithArrayInParams);

  const { data: users, sendRequest: getUsers, loading: usersLoading } = useApi<IApiResponse<IUser>>(postRequest);

  const permissions = useAppSelector(getProfilePermission(ESidebarItemIds.feedback));

  const [apiSettings, setApiSettings] = useState<IFeedbackApiSettings<IFeedback>>(getFeedbackApiSettings('createDate'));
  const [feedbackApiParams, setFeedbackApiParams] = useState<IFeedbackApiParams>({});

  const requestData = useCallback(
    async (
      reqSettings: IFeedbackApiSettings<IFeedback> = apiSettings,
      apiParams: IFeedbackApiParams = feedbackApiParams
    ) => {
      setApiSettings(reqSettings);
      setFeedbackApiParams(apiParams);
      await getFeedbacks(getFeedbacksListUrl(), reqSettings, { params: apiParams });
    },
    [apiSettings, feedbackApiParams, getFeedbacks]
  );

  useEffect(() => {
    requestData();
    dispatch(setHeaderTitle('Обращения'));
    sendCategoriesRequest(getFeedbacksCategoryListUrl());
    getUsers(getUsersListUrl(), { page: 0, pageSize: -1 }, { params: { count: 0 } });
  }, []);

  const handleOnChangeTablePage = useCallback(
    async (page: number) => {
      const newApiSettings = { ...apiSettings, page: page - 1 };
      await requestData(newApiSettings);
    },
    [apiSettings, requestData]
  );

  const handleOnSearch = useCallback(
    async (value: string) => {
      setSearchValue(value);
      const newApiSettings: IFeedbackApiSettings<IFeedback> = {
        ...apiSettings,
        page: 0,
      };
      const newApiParams: IFeedbackApiParams = { ...feedbackApiParams, search: value };
      requestData(newApiSettings, newApiParams);
    },
    [apiSettings, feedbackApiParams, requestData]
  );

  const handleOnSort = useCallback(
    async (sortResults: IApiSortField<IFeedback>[]) => {
      const newApiSettings: IFeedbackApiSettings<IFeedback> = {
        ...apiSettings,
        sortFields: sortResults,
      };
      await requestData(newApiSettings);
    },
    [apiSettings, requestData]
  );

  const handleOnChangeDate = useCallback(
    async (firstDate?: Moment, secondDate?: Moment) => {
      const newEventsParams = {
        ...feedbackApiParams,
        dateFrom: firstDate?.startOf('day').toISOString() || '',
        dateTo: secondDate?.endOf('day').toISOString() || '',
      };
      const newApiSettings = { ...apiSettings, page: 0 };
      await requestData(newApiSettings, newEventsParams);
    },
    [apiSettings, feedbackApiParams, requestData]
  );

  const onCloseModal = useCallback(() => {
    setModalIsOpen(false);
    requestData();
  }, [requestData]);

  const handleOnChangeSelect = useCallback(
    (name: string) => async (val: any) => {
      requestData(apiSettings, { ...feedbackApiParams, [name]: val });
    },
    [apiSettings, feedbackApiParams, requestData]
  );

  const optionsInToMap = useCallback((map: Map<any, string>) => {
    const result: ISelectOption[] = [];
    map.forEach((item, key) =>
      result.push({
        value: key,
        title: item,
      })
    );
    return result;
  }, []);

  const priorityOptions = useMemo(() => optionsInToMap(mapFeedbackPriorityToText), [optionsInToMap]);

  const statusOptions = useMemo(() => optionsInToMap(mapFeedbackStatusToText), [optionsInToMap]);

  const categorySelectItems = useMemo(
    () =>
      categories?.map<ISelectOption>((item) => ({
        value: item.id,
        title: item.name,
      })),
    [categories]
  );

  const usersSelectItems = useMemo(
    () =>
      users?.items?.map<ISelectOption>((item) => ({
        value: item.id,
        title: `${item.lastName} ${item.firstName}`,
      })),
    [users?.items]
  );

  return (
    <>
      <CommonHead seo={{ title: 'Обращения' }} />
      <AddAppealModal isOpen={modalIsOpen} onCancel={() => setModalIsOpen(false)} onOk={onCloseModal} />
      <div className="feedback-table-page">
        <div className="feedback-table-page__tools">
          <div className="feedback-table-page__tools-row">
            <PeriodDatePicker
              title="Период"
              placeholder="Выберите период"
              firstDate={feedbackApiParams?.dateFrom}
              secondDate={feedbackApiParams?.dateTo}
              onChange={handleOnChangeDate}
              showTime={false}
              format={dateFormatNoTime}
            />
            <Select
              showClear
              loading={categoryLoading}
              isAllOption
              title="Категория"
              value={feedbackApiParams.category}
              onChange={handleOnChangeSelect('category')}
              options={categorySelectItems}
            />
            <Select
              showClear
              isAllOption
              title="Приоритет"
              value={feedbackApiParams.priority}
              onChange={handleOnChangeSelect('priority')}
              options={priorityOptions}
            />
            <Select
              showClear
              isAllOption
              title="Статус"
              value={feedbackApiParams.status}
              onChange={handleOnChangeSelect('status')}
              options={statusOptions}
            />
          </div>

          <div className="feedback-table-page__tools-row">
            <InputSearch
              containerClassName="feedback-table-page__search"
              placeholder="Поиск по ID, абоненту, e-mail исполнителя"
              value={searchValue}
              onSearch={handleOnSearch}
            />
            <Select
              loading={usersLoading}
              showClear
              isAllOption
              title="Исполнитель"
              value={feedbackApiParams.assignedUserId}
              onChange={handleOnChangeSelect('assignedUserId')}
              options={usersSelectItems}
            />
            {permissions?.create && (
              <Button type={ButtonType.outline} onClick={() => setModalIsOpen(true)}>
                Создать обращение
              </Button>
            )}
          </div>
        </div>
        <div className="feedback-table-page__content">
          <FeedbacksTable
            onSort={handleOnSort}
            sortOrders={apiSettings.sortFields}
            permissions={permissions}
            feedbacks={feedbacks?.items || []}
            loading={feedbacksLoading}
            pageSize={feedbacks?.pageSize}
            total={feedbacks?.totalCount}
            currentPage={feedbacks?.page}
            onChangePage={handleOnChangeTablePage}
            isSearch={!!searchValue}
            requestData={requestData}
          />
        </div>
      </div>
    </>
  );
};

export default FeedbackTablePage;
