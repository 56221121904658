import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import { useAppDispatch, useAppSelector } from '../../hooks/hooks';
import { checkIsAdmin, getProfilePermission } from '../../store/selectors/profile';
import { ESidebarItemIds } from '../../typings/sidebar';
import { useApi } from '../../hooks/useApi';
import { getRequest, postFileRequest, postRequest } from '../../api';
import { setHeaderTitle } from '../../store/slices/header';
import CommonHead from '../../components/commonHead';
import InputSearch from '../../components/ui/inputSearch';
import Button from '../../components/ui/button';
import { ButtonType } from '../../components/ui/button/types';
import DownloadIcon from '../../assets/svg/icons/download';
import { paths } from '../../constants/paths';
import { companyUrl, exportUserUrl, getUsersListUrl } from '../../constants/api';
import { IApiResponse } from '../../typings/api';
import { IUser } from '../../typings/users';
import { IUsersApiSettings } from '../../api/users/types';
import { getUsersApiSettings } from '../../api/users/config';
import UsersTable from '../../components/usersTable';
import { ISystem } from '../../typings/systems/system';
import { ISelectOption } from '../../components/ui/select/types';
import Select from '../../components/ui/select';

const UsersPage: FC = () => {
  const dispatch = useAppDispatch();

  const [searchValue, setSearchValue] = useState<string>('');

  const permissions = useAppSelector(getProfilePermission(ESidebarItemIds.users));

  const isAdmin = useAppSelector(checkIsAdmin);

  const { data: companies, sendRequest: getCompanies } = useApi<ISystem[]>(getRequest);
  const [companyId, setCompanyId] = useState<string | number>('');

  const { data: users, sendRequest: getUsers, loading: usersLoading } = useApi<IApiResponse<IUser>>(postRequest);
  const { data: exportData, sendRequest: getExportData } = useApi<any>(postFileRequest);

  const navigate = useNavigate();

  const [apiSettings, setApiSettings] = useState<IUsersApiSettings>(getUsersApiSettings());

  const requestData = useCallback(
    async (reqSettings: IUsersApiSettings = apiSettings, company = companyId) => {
      setApiSettings(reqSettings);
      await getUsers(getUsersListUrl(), reqSettings, company ? { params: { companyId: company } } : {});
    },
    [apiSettings, getUsers]
  );

  useEffect(() => {
    requestData();
    dispatch(setHeaderTitle('Пользователи'));
  }, []);

  useEffect(() => {
    if (isAdmin) {
      getCompanies(companyUrl());
    }
  }, [isAdmin]);

  const saveFile = useCallback(async () => {
    if (exportData) {
      const fileOptions = {
        suggestedName: 'Users.xls',
        types: [
          {
            description: 'xls',
            accept: {
              'application/msexcel': '.xls',
            },
          },
        ],
        excludeAcceptAllOption: true,
      };
      const fileHandle = await (window as any).showSaveFilePicker(fileOptions);
      const writableStream = await fileHandle.createWritable();
      await writableStream.write(exportData.data);
      await writableStream.close();
    }
  }, [exportData]);

  useEffect(() => {
    saveFile();
  }, [exportData]);

  const handleOnChangeTablePage = useCallback(
    async (page: number) => {
      const newApiSettings = { ...apiSettings, page: page - 1 };
      await requestData(newApiSettings);
    },
    [apiSettings, requestData]
  );

  const handleOnSearch = useCallback(
    async (value: string) => {
      setSearchValue(value);
      const newApiSettings: IUsersApiSettings = { ...apiSettings, page: 0, search: value };
      requestData(newApiSettings);
    },
    [apiSettings, requestData]
  );

  const onExport = useCallback(() => {
    getExportData(exportUserUrl());
  }, [getExportData]);

  const onCreateClick = useCallback(() => {
    navigate(`${paths.users}/create`);
  }, [navigate]);

  const handleOnChangeCompanyId = useCallback(
    async (id: string | number) => {
      setCompanyId(id);
      requestData(apiSettings, id);
    },
    [apiSettings, requestData]
  );

  const organizationOptions = useMemo(
    () =>
      isAdmin && companies
        ? companies.map<ISelectOption>((object) => ({
            value: object.id || '',
            title: object.companyName || '',
          }))
        : [],
    [companies, isAdmin]
  );

  return (
    <>
      <CommonHead seo={{ title: 'Пользователи' }} />
      <div className="users">
        <div className="users__tools">
          {isAdmin && (
            <Select
              isAllOption
              title="Организация"
              value={companyId}
              onChange={handleOnChangeCompanyId}
              options={organizationOptions}
            />
          )}
          <InputSearch
            containerClassName="users__search"
            placeholder="Поиск по ФИО, E–mail"
            value={searchValue}
            onSearch={handleOnSearch}
          />
          {permissions?.export && (
            <Button onClick={onExport} type={ButtonType.outline} rightIcon={<DownloadIcon />}>
              Экспорт
            </Button>
          )}
          {permissions?.create && <Button onClick={onCreateClick}>Создать пользователя</Button>}
        </div>
        <UsersTable
          isAdmin={isAdmin}
          permissions={permissions}
          users={users?.items || []}
          loading={usersLoading}
          pageSize={users?.pageSize}
          total={users?.totalCount}
          currentPage={users?.page}
          onChangePage={handleOnChangeTablePage}
          isSearch={!!searchValue}
          requestData={requestData}
        />
      </div>
    </>
  );
};

export default UsersPage;
