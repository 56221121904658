import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { AxiosError } from 'axios';
import moment from 'moment';
import { useNavigate } from 'react-router';
import { IFaqTableQuestions } from './types';
import Input from '../../../ui/input';
import { EFieldTypes, IInputValue, InputStatus } from '../../../ui/input/types';
import TabNavButtons from '../../../tabs/tabNavButtons';
import { tabNavButtonsDefault } from '../../../tabs/tabNavButtons/utils';
import SelectMultiple from '../../../ui/selectMultiple';
import { selectAllOptionKey } from '../../../../constants/select';
import { useApi } from '../../../../hooks/useApi';
import { postRequest, putRequest } from '../../../../api';
import { createFAQQuestionUrl, editFAQQuestionUrl } from '../../../../constants/api';
import Message from '../../../message';
import { EFAQLvl, EFAQStatus } from '../../../../typings/faq';
import TrashIcon from '../../../../assets/svg/icons/trash';
import { dateDefaultFormat } from '../../../../constants/date';
import DragAndDropIcon from '../../../../assets/svg/icons/dragAndDrop';
import { FAQLevelsMap } from '../types';
import InfoIcon from '../../../../assets/svg/icons/info';
import StatusSelect from '../../../statusSelect';
import { StatusIconColor } from '../../../statusSelect/types';
import { useAppDispatch, useAppSelector } from '../../../../hooks/hooks';
import { getClickedSidebarTab } from '../../../../store/selectors/sidebar';
import { setClickedSidebarTab } from '../../../../store/slices/sidebar';
import { IConfirmData } from '../../../ui/universalModal/types';
import { defaultConfirm, saveChangesModal } from '../../../ui/universalModal/config';
import UniversalModal from '../../../ui/universalModal';

const defaultValue = {
  value: '',
  status: InputStatus.normal,
  errorText: '',
};

const FaqQuestionEdit: FC<IFaqTableQuestions> = ({
  permissions,
  showIcon = true,
  isAdmin = false,
  editItem = null,
  systems = [],
  onChancelCreate = () => {},
  onCreated = () => {},
  activeSection = '',
  onDelete = () => {},
}) => {
  const [question, setQuestion] = useState<IInputValue>({ ...defaultValue });
  const [answer, setAnswer] = useState<IInputValue>({ ...defaultValue });
  const [status, setStatus] = useState<EFAQStatus>(EFAQStatus.active);
  const [selectedActionTypeList, setSelectedActionTypeList] = useState<string[]>([selectAllOptionKey]);

  const { sendRequest: createQuestion, loading: createQuestionLoading } = useApi(postRequest);
  const { sendRequest: editQuestion, loading: editQuestionLoading } = useApi(putRequest);

  const clickedSidebarTab = useAppSelector(getClickedSidebarTab);

  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const [confirmData, setConfirmData] = useState<IConfirmData>(defaultConfirm);

  const closeConfirm = useCallback(() => setConfirmData(defaultConfirm), []);

  useEffect(() => {
    if (editItem) {
      setQuestion({ ...defaultValue, value: editItem?.question || '' });
      setAnswer({ ...defaultValue, value: editItem?.answer || '' });
      setStatus(editItem.status);
      if (editItem.subscriberFaqQuestionObjects.length) {
        setSelectedActionTypeList(editItem.subscriberFaqQuestionObjects.map((item) => item.objectId));
      }
    }
  }, [editItem]);

  const validateInputs = useCallback(() => {
    let isValid = true;

    if (!question.value || !question.value.trim()) {
      isValid = false;
      setQuestion({ ...question, errorText: 'Поле должно быть заполнено', status: InputStatus.error });
    }

    if (!answer.value || !answer.value.trim()) {
      isValid = false;
      setAnswer({ ...answer, errorText: 'Поле должно быть заполнено', status: InputStatus.error });
    }

    return isValid;
  }, [answer, question]);

  const onSave = useCallback(async () => {
    if (validateInputs()) {
      const saveObject = {
        ...editItem,
        ...{
          question: question.value,
          answer: answer.value,
          status,
        },
      } as any;

      if (!isAdmin && selectedActionTypeList[0] !== selectAllOptionKey) {
        saveObject.subscriberFaqQuestionObjects = selectedActionTypeList;
        saveObject.isAllObjects = false;
      } else {
        saveObject.isAllObjects = true;
        delete saveObject.subscriberFaqQuestionObjects;
      }

      let resError;
      if (editItem) {
        resError = (await editQuestion(editFAQQuestionUrl(), saveObject)) as AxiosError;
      } else {
        resError = (await createQuestion(createFAQQuestionUrl(activeSection), saveObject)) as AxiosError;
      }

      if (!resError?.response?.data) {
        onCreated();
      } else {
        setQuestion({ ...question, errorText: 'Такой вопрос уже существует', status: InputStatus.error });
        return false;
      }
      return true;
    }
  }, [
    activeSection,
    answer.value,
    createQuestion,
    editItem,
    editQuestion,
    isAdmin,
    onCreated,
    question,
    selectedActionTypeList,
    status,
    validateInputs,
  ]);

  const checkChanges = useCallback(
    (callBack = () => {}) => {
      setConfirmData(
        saveChangesModal(
          async () => {
            closeConfirm();
            const result = await onSave();
            if (result) {
              callBack();
            }
          },
          () => {
            callBack();
            closeConfirm();
          }
        )
      );
    },
    [closeConfirm, onSave]
  );

  useEffect(() => {
    if (clickedSidebarTab) {
      checkChanges(() => {
        onChancelCreate();
        navigate(clickedSidebarTab);
      });
      dispatch(setClickedSidebarTab(null));
    }
  }, [clickedSidebarTab]);

  const onChangeStatus = useCallback((e: number | string) => {
    setStatus(e as EFAQStatus);
    if (e === EFAQStatus.active) {
      Message.success({
        content: 'Вопрос будет отображаться в мобильном приложении',
      });
    } else {
      Message.success({
        content: 'Вопрос не будет отображаться в мобильном приложении',
      });
    }
  }, []);

  const handleOnChangeInput = useCallback(
    (setter: React.Dispatch<React.SetStateAction<IInputValue>>, value: string) => setter({ ...defaultValue, value }),
    []
  );

  const objectOptions = useMemo(
    () => [
      { value: selectAllOptionKey, title: 'Все' },
      ...systems.map((item) => ({
        value: item.id || '',
        title: item.objectName || '',
      })),
    ],
    [systems]
  );

  return (
    <div className="faq-question-edit faq-table__tr">
      <UniversalModal data={confirmData} onClose={closeConfirm} />
      <div className="faq-table__td">{editItem && showIcon && <DragAndDropIcon />}</div>
      <div className="faq-table__td">
        {editItem
          ? FAQLevelsMap.get(editItem.level)
          : isAdmin
          ? FAQLevelsMap.get(EFAQLvl.platform)
          : FAQLevelsMap.get(EFAQLvl.company)}
      </div>
      <div className="faq-table__td">
        <div className="faq-question-edit__content">
          <Input
            title="Вопрос"
            value={question?.value}
            status={question.status}
            onChange={(value) => handleOnChangeInput(setQuestion, value)}
            placeholder="Введите вопрос"
            autoSize={{ minRows: 4, maxRows: 4 }}
            maxLength={200}
            fieldType={EFieldTypes.textArea}
            errorText={question.errorText}
          />

          <Input
            title="Ответ"
            value={answer?.value}
            status={answer.status}
            onChange={(value) => handleOnChangeInput(setAnswer, value)}
            placeholder="Введите ответ"
            autoSize={{ minRows: 8, maxRows: 8 }}
            maxLength={600}
            fieldType={EFieldTypes.textArea}
            errorText={answer.errorText}
          />
          <div className="faq-question-edit__selects">
            <StatusSelect
              title="Статус"
              value={status}
              onChange={onChangeStatus}
              isDisabledStyle
              options={[
                { value: EFAQStatus.active, title: 'Активен', color: StatusIconColor.green },
                { value: EFAQStatus.inactive, title: 'Неактивен', color: StatusIconColor.red },
              ]}
            />
            {isAdmin ? (
              <div className="faq-question-edit__admin-notify">
                <div className="faq-question-edit__admin-notify-icon">
                  <InfoIcon />
                </div>
                <div className="faq-question-edit__admin-label">
                  Вопрос будет отображаться для всех объектов и организаций
                </div>
              </div>
            ) : (
              <SelectMultiple
                isSelectedAllDefault={!editItem?.subscriberFaqQuestionObjects.length}
                title="Объекты"
                values={selectedActionTypeList}
                onChange={setSelectedActionTypeList}
                options={objectOptions}
              />
            )}
          </div>
          {editItem && (
            <div className="faq-table-question__answer-date">
              Изменен: {editItem && moment(editItem.updateDate || editItem.createDate).format(dateDefaultFormat)}
            </div>
          )}
        </div>
      </div>
      <div className="faq-table__td">
        {editItem && permissions?.delete && (
          <div onClick={onDelete} role="presentation" className="faq-table__table-icon faq-table__table-icon_trash">
            <TrashIcon />
          </div>
        )}
      </div>
      <TabNavButtons
        containerClassName="faq-table__button-container"
        buttons={tabNavButtonsDefault(
          {
            text: 'Отмена',
            callBack: onChancelCreate,
            disabled: createQuestionLoading || editQuestionLoading,
          },
          {
            text: 'Сохранить',
            callBack: onSave,
            disabled: createQuestionLoading || editQuestionLoading,
          },
          { isHidden: true }
        )}
      />
    </div>
  );
};

export default FaqQuestionEdit;
