import { Key } from 'antd/lib/table/interface';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { getHardwareSettingsTree } from '../../../api/hardwareSettings';
import { getHardwareSettingsTreeUrl } from '../../../constants/api';
import { useApi } from '../../../hooks/useApi';
import { ITreeNode, TreeNodeType } from '../../../typings/treeNode';
import { getAccessGroupTreeKey, resolveAccessGroupTreeKey } from '../../../utils/tree';
import { ISystemTabComponent } from '../types';
import HardwareSettingsTree from './hardwareSettingsTree';
import { ISelectedNode } from './types';
import { IValidHardware } from './validateButtons/types';
import HardwareValidateButtons from './validateButtons';
import AccessDoor from '../../../assets/svg/icons/accessDoor';
import SevenThousandDevice from './hardware/device/sevenThousand';
import HardwareCamera from './hardware/camera';
import FiveThousandDevice from './hardware/device/fiveThousand';

const HardwareSettingsTab: FC<ISystemTabComponent> = (props) => {
  const {
    system,
    systemId = '',
    tabId = '',
    activeTabKey = '',
    isWasChange = false,
    setChange = () => {},
    nextTabIsAvailable,
    permissions = {},
    getSystemData = () => {},
    isFiveThousandth,
  } = props;

  const { data: tree, sendRequest: getTree, loading: treeLoading } = useApi<ITreeNode>(getHardwareSettingsTree);

  const [selectedNode, setSelectedNode] = useState<ISelectedNode | null>(null);
  const [clickedNode, setClickedNode] = useState<ISelectedNode | null>(null);

  const [invalidHardwareList, setInvalidHardwareList] = useState<IValidHardware[]>([]);
  const [activeInvalidHardware, setActiveInvalidHardware] = useState<IValidHardware | null>(null);

  const hardwarePages = useMemo(
    () => [
      {
        id: TreeNodeType.device,
        component: isFiveThousandth ? FiveThousandDevice : SevenThousandDevice,
      },
      {
        id: TreeNodeType.keyDevice,
        component: isFiveThousandth ? FiveThousandDevice : SevenThousandDevice,
      },
      {
        id: TreeNodeType.camera,
        component: HardwareCamera,
      },
      {
        id: TreeNodeType.commutator,
        component: FiveThousandDevice,
      },
      {
        id: TreeNodeType.securityPostConsole,
        component: FiveThousandDevice,
      },
    ],
    [isFiveThousandth]
  );

  useEffect(() => {
    if (tabId === activeTabKey) {
      setSelectedNode(null);
      getTree(getHardwareSettingsTreeUrl(systemId));
      setInvalidHardwareList([]);
      setActiveInvalidHardware(null);
    }
  }, [activeTabKey]);

  const onSelect = useCallback(
    async (selectedKeys: Key[]) => {
      const key = selectedKeys[0];
      const [itemId, itemType] = resolveAccessGroupTreeKey(key);
      if (isWasChange) {
        setClickedNode({ itemId, itemType, originalKey: key.toString() });
      } else {
        setSelectedNode({ itemId, itemType, originalKey: key.toString() });
      }
    },
    [isWasChange]
  );

  const onChangeActiveInvalidHardware = useCallback(
    (hardware: IValidHardware) => {
      setActiveInvalidHardware(hardware);
      onSelect([getAccessGroupTreeKey(hardware.id, TreeNodeType.device, '0')]);
    },
    [onSelect]
  );

  const getSystemAndHardwareData = useCallback(async () => {
    await getSystemData();
    // await requestData(true);
  }, [getSystemData]);

  const repeatSelectNode = useCallback(() => {
    if (clickedNode) {
      setSelectedNode(clickedNode);
      setChange(false);
      setClickedNode(null);
    }
  }, [clickedNode, setChange]);

  const updateAfterSave = useCallback(
    async (id: string) => {
      if (invalidHardwareList.length) {
        setInvalidHardwareList(invalidHardwareList.filter((item) => item.id !== id));
        setActiveInvalidHardware(null);
      }
      getTree(getHardwareSettingsTreeUrl(systemId));
    },
    [getTree, invalidHardwareList, systemId]
  );

  return (
    <div className="hardware-settings">
      <HardwareValidateButtons
        isFiveThousandth={isFiveThousandth}
        permissions={permissions}
        system={system}
        systemId={systemId}
        selectedNode={selectedNode}
        invalidHardware={invalidHardwareList}
        setInvalidHardware={setInvalidHardwareList}
        onChangeActive={onChangeActiveInvalidHardware}
        getSystemData={getSystemAndHardwareData}
      />
      <div className="hardware-settings__content-wrapper">
        {tabId === activeTabKey && (
          <HardwareSettingsTree
            invalidHardware={invalidHardwareList}
            selectedNode={selectedNode?.originalKey || ''}
            data={tree ? [tree] : []}
            onSelect={onSelect}
            loading={Boolean(treeLoading)}
          />
        )}
        {selectedNode ? (
          hardwarePages
            .filter((item) => item.id === selectedNode.itemType)
            .map((page) => (
              <page.component
                key={page.id}
                {...props}
                nextTabIsAvailable={nextTabIsAvailable}
                invalidHardwareList={invalidHardwareList}
                activeInvalidHardware={activeInvalidHardware}
                selectedNode={selectedNode}
                clickedNode={clickedNode}
                repeatSelectNode={repeatSelectNode}
                updateAfterSave={updateAfterSave}
              />
            ))
        ) : (
          <div className="hardware-settings__content">
            <div className="hardware-settings__error">
              <div className="hardware-settings__error-icon">
                <AccessDoor />
              </div>
              <div className="hardware-settings__error-title">Для настройки выберите оборудование точки доступа</div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default HardwareSettingsTab;
