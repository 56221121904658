import React, { FC, useCallback } from 'react';
import { ITariffCost } from './types';
import { ITariffCustomSettings } from '../../../pages/management/tariffsCost/types';
import Input from '../../ui/input';
import { InputType } from '../../ui/input/types';
import NumberInput from '../../ui/input/numberInput';

const TariffCost: FC<ITariffCost> = ({
  commission = [],
  settings = null,
  isMaintenance = false,
  maintenancePrice = 0,
  onChange = () => {},
  permissions = {},
}) => {
  const onChangeInput = useCallback(
    (index: number) => (value: number) => {
      if (settings) {
        const newSettings: ITariffCustomSettings = { ...settings };
        newSettings.tariffs[index].additionalCompanyPrice = value;
        onChange(newSettings);
      }
    },
    [onChange, settings]
  );

  return (
    <div className="tariff-cost">
      <div className="tariff-cost__title">Стоимость первого месяца</div>
      <div className="tariff-cost__tariffs">
        {commission.map((item, index) => (
          <div key={item.version} className="tariff-cost__tariffs-item">
            <div className="tariff-cost__tariffs-item-title">Тариф «{item.version}»</div>
            <div className="tariff-cost__tariffs-inputs">
              <Input
                title="Стоимость ELTIS (₽)"
                inputType={InputType.number}
                placeholder="0,00"
                value={item.fixCommission?.toFixed(2)}
                disabled
                isDisabledStyle
              />
              <div className="tariff-cost__tariffs-inputs-sign">+</div>
              <NumberInput
                title="Наценка организации (₽)"
                placeholder="0,00"
                onChange={onChangeInput(index)}
                value={settings?.tariffs[index]?.additionalCompanyPrice || null}
                disabled={!permissions.edit}
                isDisabledStyle
              />
              {isMaintenance && (
                <>
                  <div className="tariff-cost__tariffs-inputs-sign">+</div>
                  <Input
                    title="Стоимость техобслуживания (₽)"
                    inputType={InputType.number}
                    placeholder="0,00"
                    value={maintenancePrice?.toFixed(2)}
                    disabled
                    isDisabledStyle
                  />
                </>
              )}
              <div className="tariff-cost__tariffs-inputs-sign">=</div>
              <Input
                title="Итоговая стоимость для абонента (₽)"
                inputType={InputType.number}
                placeholder="0,00"
                value={(
                  item.fixCommission +
                  maintenancePrice +
                  (settings?.tariffs[index].additionalCompanyPrice || 0)
                ).toFixed(2)}
                disabled
                isDisabledStyle
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TariffCost;
