import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import classNames from 'classnames';
import { IFaqTable } from './types';
import ErrorPlaceholder from '../../ui/errorPlaceholder';
import Scrollbar from '../../ui/scrollbar';
import PlusIcon from '../../../assets/svg/icons/plus';
import FaqTableQuestions from './faqTableQuestion';
import FaqQuestionEdit from './questionEdit';
import { EFAQAdminTabs, IFAQQuestion } from '../../../typings/faq';
import { useApi } from '../../../hooks/useApi';
import { postRequest } from '../../../api';
import { sortFAQQuestionsUrl } from '../../../constants/api';
import CloseIcon from '../../../assets/svg/icons/close';
import Message from '../../message';
import SearchFailIcon from '../../../assets/svg/icons/searchFail';
import EmptyFAQList from '../../../assets/svg/icons/emptyFAQList';

const FaqTable: FC<IFaqTable> = ({
  blockDragAndDrop = false,
  permissions = {},
  isChangeMode = false,
  setIsChangeMode = () => {},
  currPage = 0,
  isLastList = false,
  activeSection = '',
  requestQuestions = () => {},
  handleOnChangeTablePage = () => {},
  systems = [],
  questions = [],
  isAdmin = false,
  isSearch = false,
  activeTab,
}) => {
  const listInnerRef = useRef<HTMLDivElement | null>(null);

  const [isCreate, setIsCreate] = useState(false);

  const [currentQuestions, setCurrentQuestions] = useState<IFAQQuestion[]>([]);

  const { sendRequest: sortQuestions } = useApi(postRequest);

  useEffect(() => {
    setCurrentQuestions(questions);
  }, [questions]);

  const onChangeCreate = useCallback(
    (val: boolean) => {
      setIsCreate(val);
      setIsChangeMode(val);
    },
    [setIsChangeMode]
  );

  const reorder = useCallback((list: IFAQQuestion[], startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  }, []);

  const onDragEnd = useCallback(
    (result: any) => {
      if (!result.destination) {
        return;
      }
      const newQuestions = reorder(currentQuestions, result.source.index, result.destination.index);
      setCurrentQuestions(newQuestions);
      sortQuestions(
        sortFAQQuestionsUrl(),
        newQuestions.map((item, index) => ({ id: item.id, order: index }))
      );
    },
    [currentQuestions, reorder, sortQuestions]
  );

  const onScroll = useCallback(() => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      if (scrollTop + clientHeight === scrollHeight && !isLastList) {
        handleOnChangeTablePage(currPage + 1);
      }
    }
  }, [currPage, handleOnChangeTablePage, isLastList]);

  const onChancelCreate = useCallback(() => {
    onChangeCreate(false);
  }, [onChangeCreate]);

  const onCreated = useCallback(() => {
    requestQuestions();
    onChangeCreate(false);
    Message.success({
      content: 'Новый вопрос создан',
    });
  }, [onChangeCreate, requestQuestions]);

  const getEmptyMessage = useCallback(
    () =>
      !isSearch ? (
        <ErrorPlaceholder
          text={
            <>
              <span>Пока нет вопросов в разделе.</span>
              <span>Нажмите на «+» в таблице, чтобы добавить.</span>
            </>
          }
          icon={<EmptyFAQList />}
        />
      ) : (
        <ErrorPlaceholder text="По вашему запросу ничего не найдено" icon={<SearchFailIcon />} />
      ),
    [isSearch]
  );

  const isSimpleMode = useMemo(() => isAdmin && activeTab === EFAQAdminTabs.all, [activeTab, isAdmin]);

  return (
    <div className={classNames('faq-table', { 'faq-table_short': isChangeMode })}>
      <Scrollbar>
        <div
          className={classNames('faq-table__table default-scrollbar-override', { 'faq-table__table_create': isCreate })}
        >
          <div className="faq-table__thead">
            <div className="faq-table__tr">
              <div
                className={classNames('faq-table__th', { 'faq-table__th_hide': !permissions.edit || isSimpleMode })}
              />
              <div className="faq-table__th">Уровень</div>
              <div className="faq-table__th">Вопросы</div>
              <div
                className={classNames('faq-table__th', {
                  'faq-table__th_hide': !permissions?.create || isSimpleMode || isChangeMode,
                })}
              >
                {isCreate ? (
                  <div
                    className="faq-table__close close-icon"
                    role="presentation"
                    onClick={() => onChangeCreate(false)}
                  >
                    <CloseIcon />
                  </div>
                ) : (
                  <div className="faq-table__plus" role="presentation" onClick={() => onChangeCreate(true)}>
                    <PlusIcon />
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="faq-table__separator" />
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
              {(provided) => (
                <div
                  onScroll={onScroll}
                  className="faq-table__tbody default-scrollbar-override"
                  ref={(current) => {
                    provided.innerRef(current);
                    listInnerRef.current = current;
                  }}
                  {...provided.droppableProps}
                >
                  {isCreate ? (
                    <FaqQuestionEdit
                      permissions={permissions}
                      isAdmin={isAdmin}
                      activeSection={activeSection}
                      systems={systems}
                      onChancelCreate={onChancelCreate}
                      onCreated={onCreated}
                    />
                  ) : currentQuestions.length && activeSection ? (
                    currentQuestions.map((item, index) => (
                      <FaqTableQuestions
                        blockDragAndDrop={blockDragAndDrop}
                        isLastElement={index === currentQuestions.length - 1}
                        isChangeMode={isChangeMode}
                        setIsChangeMode={setIsChangeMode}
                        showIcon={currentQuestions.length !== 1}
                        isSimpleMode={isSimpleMode}
                        isCreate={isCreate}
                        isAdmin={isAdmin}
                        key={`${item.id}-${index}`}
                        question={item}
                        index={index}
                        requestQuestions={requestQuestions}
                        activeSection={activeSection}
                        systems={systems}
                        permissions={permissions}
                      />
                    ))
                  ) : (
                    getEmptyMessage()
                  )}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
      </Scrollbar>
    </div>
  );
};

export default FaqTable;
