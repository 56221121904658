import { ISystemSection } from '../../../../typings/systems/section';

export interface IAddFlatsModal {
  systemId?: string;
  buildingId?: string;
  sections?: ISystemSection[];
  isOpen?: boolean;
  onCancel?: () => void;
  onOk?: () => void;
  isFiveThousandth?: boolean;
}

export enum NumerationType {
  sequential = 'sequential',
  arbitrary = 'arbitrary',
}
