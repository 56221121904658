import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { AxiosError } from 'axios';
import { useNavigate } from 'react-router';
import { IRoleColumn, IRoleCustomizationTable } from './types';
import Loader from '../../ui/loader';
import { ELoaderColor } from '../../ui/loader/types';
import { useApi } from '../../../hooks/useApi';
import { getRequest, postRequest } from '../../../api';
import { createRoleUrl, defaultRolesUrl, updateRoleUrl } from '../../../constants/api';
import { IRole, IRoleSetting } from '../../../typings/roles';
import Checkbox from '../../ui/checkbox';
import Select from '../../ui/select';
import Input from '../../ui/input';
import TabNavButtons from '../../tabs/tabNavButtons';
import { tabNavButtonsDefault } from '../../tabs/tabNavButtons/utils';
import Message from '../../message';
import { IConfirmData } from '../../ui/universalModal/types';
import { defaultConfirm, saveChangesModal } from '../../ui/universalModal/config';
import UniversalModal from '../../ui/universalModal';
import { paths } from '../../../constants/paths';
import { ButtonType } from '../../ui/button/types';
import { useAppDispatch, useAppSelector } from '../../../hooks/hooks';
import { getClickedSidebarTab } from '../../../store/selectors/sidebar';
import { setClickedSidebarTab } from '../../../store/slices/sidebar';

const roleColumns: IRoleColumn[] = [
  { label: 'Просмотр', name: 'view', disabledName: 'isViewDisabled' },
  { label: 'Создание', name: 'create', disabledName: 'isCreateDisabled' },
  { label: 'Редактирование', name: 'edit', disabledName: 'isEditDisabled' },
  { label: 'Удаление', name: 'delete', disabledName: 'isDeleteDisabled' },
  { label: 'Экспорт списка', name: 'export', disabledName: 'isExportDisabled' },
  { label: 'Импорт списка', name: 'import', disabledName: 'isImportDisabled' },
];

const RoleCustomizationTable: FC<IRoleCustomizationTable> = (props) => {
  const {
    permissions = {},
    role = null,
    isCreate = false,
    roleLoading = false,
    onSaved = () => {},
    wasChange = false,
    setWasChange = () => {},
    nextTabId = '',
    resetNextTabId = () => {},
  } = props;

  const [currentRole, setCurrentRole] = useState<IRole | null>(null);

  const navigate = useNavigate();

  const [selectedDefaultRole, setSelectedDefaultRole] = useState('');

  const { data: defaultRoles, sendRequest: getDefaultRoles } = useApi<IRole[]>(getRequest);
  const { data: updatedRole, sendRequest: updateRole, loading: updateLoading } = useApi<IRole>(postRequest);

  const [confirmData, setConfirmData] = useState<IConfirmData>(defaultConfirm);
  const closeConfirm = useCallback(() => setConfirmData(defaultConfirm), []);

  const clickedSidebarTab = useAppSelector(getClickedSidebarTab);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (updatedRole && currentRole) {
      onSaved({ ...updatedRole, roles: currentRole.roles });
    }
  }, [updatedRole]);

  useEffect(() => {
    getDefaultRoles(defaultRolesUrl());
  }, []);

  useEffect(() => {
    setCurrentRole(JSON.parse(JSON.stringify(role)));
  }, [role]);

  const handleOnChangeDefaultRole = useCallback(
    (value: string | number) => {
      setSelectedDefaultRole(value.toString());
      setWasChange(true);
      const newRole = defaultRoles?.find((item) => item.id === value);
      if (newRole) {
        setCurrentRole({ ...newRole });
      }
    },
    [defaultRoles, setWasChange]
  );

  const handleOnChangeCheckbox = useCallback(
    (index: number, name: string) => async (isEnabled: boolean) => {
      if (currentRole) {
        const newCurrentRole: IRole = { ...currentRole };
        if (newCurrentRole.roles) {
          const roleData: any = newCurrentRole.roles[index];

          if (name === 'view' && !isEnabled) {
            roleColumns.forEach((item) => {
              roleData[item.name as keyof IRoleSetting] = false;
            });
          } else {
            roleData[name] = isEnabled;
            if (name === 'create' && isEnabled) {
              roleData.edit = true;
            }
            if (name === 'edit' && !isEnabled) {
              roleData.create = false;
            }
            if (!roleData.view) {
              roleData.view = true;
            }
          }
        }
        setCurrentRole(newCurrentRole);
        setWasChange(true);
      }
    },
    [currentRole, setWasChange]
  );

  const changeRoleName = useCallback(
    (value: string) => {
      if (currentRole) {
        const newCurrentRole: IRole = { ...currentRole };
        newCurrentRole.name = value;
        setCurrentRole(newCurrentRole);
        setWasChange(true);
      }
    },
    [currentRole, setWasChange]
  );

  const onSaveRole = useCallback(async (): Promise<boolean> => {
    if (currentRole?.name) {
      const resError = (await updateRole(isCreate ? createRoleUrl() : updateRoleUrl(), currentRole)) as AxiosError;
      if (resError?.response?.data) {
        Message.error({
          content: (resError?.response?.data as any).message,
        });

        return false;
      }
      if (currentRole) {
        if (isCreate) {
          Message.success({
            content: `Новая роль ${currentRole.name} создана`,
          });
        } else {
          onSaved(currentRole);
          Message.success({
            content: 'Изменения сохранены',
          });
        }
        setWasChange(false);
      }
      return true;
    }

    Message.error({
      content: 'Укажите название роли!',
    });
    return false;
  }, [currentRole, isCreate, onSaved, setWasChange, updateRole]);

  const checkChanges = useCallback(
    (callBack = (flag?: boolean) => {}) => {
      setConfirmData(
        saveChangesModal(
          async () => {
            closeConfirm();
            const res = await onSaveRole();
            callBack(res);
          },
          () => {
            closeConfirm();
            callBack();
          }
        )
      );
    },
    [closeConfirm, onSaveRole]
  );

  useEffect(() => {
    if (nextTabId) {
      checkChanges((flag = true) => resetNextTabId(flag));
    }
  }, [nextTabId]);

  useEffect(() => {
    if (clickedSidebarTab) {
      checkChanges((isSuccess = true) => {
        if (isSuccess) {
          setWasChange(false);
          navigate(clickedSidebarTab);
        }
      });
      dispatch(setClickedSidebarTab(null));
    }
  }, [clickedSidebarTab]);

  const onChancel = useCallback(() => {
    if (isCreate) {
      navigate(paths.roles);
    } else {
      setCurrentRole(JSON.parse(JSON.stringify(role)));
      setWasChange(false);
    }
  }, [isCreate, navigate, role, setWasChange]);

  const columns = useMemo<ColumnsType<IRoleSetting>>(
    () => [
      {
        title: 'Функции',
        dataIndex: 'functions',
        key: 'functions',
        fixed: 'left',
        render: (_: any, record: IRoleSetting) => (
          <div className="editable-table__data-container editable-table__data-container_name">{record.displayName}</div>
        ),
      },
      ...roleColumns.map((item) => ({
        title: item.label,
        dataIndex: item.name,
        key: item.name,
        render: (_: any, record: IRoleSetting, index: number) => (
          <div className="editable-table__data-container">
            <Checkbox
              checked={record[item.name as keyof IRoleSetting] as boolean}
              onChange={handleOnChangeCheckbox(index, item.name)}
              disabled={
                !permissions.edit || role?.isReadOnly || (record[item.disabledName as keyof IRoleSetting] as boolean)
              }
            />
          </div>
        ),
      })),
    ],
    [permissions, handleOnChangeCheckbox, role?.isReadOnly]
  );

  const data = useMemo(
    () =>
      currentRole?.roles?.map((item, index) => ({
        ...item,
        key: index,
      })) || [],
    [currentRole]
  );

  const options = useMemo(
    () =>
      defaultRoles?.map((item) => ({
        value: item.id || '',
        title: item.name || '',
      })),
    [defaultRoles]
  );

  return (
    <div className="editable-table roles-customization-table">
      <UniversalModal data={confirmData} onClose={closeConfirm} />
      <div className="roles-customization-table__inputs">
        {isCreate && (
          <Select
            title="Предзаполнить как"
            value={selectedDefaultRole}
            onChange={handleOnChangeDefaultRole}
            options={options}
          />
        )}
        <Input
          isDisabledStyle
          title="Название роли"
          disabled={role?.isReadOnly || !permissions.edit}
          placeholder="Название роли"
          value={currentRole?.name}
          onChange={changeRoleName}
          isRequired
          maxLength={50}
        />
      </div>
      <Table
        columns={columns.map((column) => ({ ...column, width: `${100 / columns.length}%` }))}
        dataSource={data}
        loading={{
          spinning: roleLoading || updateLoading,
          indicator: <Loader color={ELoaderColor.blue} />,
        }}
        showSorterTooltip={false}
        pagination={false}
      />
      <TabNavButtons
        buttons={tabNavButtonsDefault(
          {
            isHidden: !permissions.edit,
            text: 'Отмена',
            loading: updateLoading || roleLoading,
            callBack: onChancel,
            disabled: !wasChange,
          },
          {
            isHidden: !permissions.edit,
            type: ButtonType.primary,
            text: isCreate ? 'Создать' : 'Сохранить',
            loading: updateLoading || roleLoading,
            callBack: onSaveRole,
            disabled: !wasChange || !currentRole?.name || !currentRole?.name?.trim(),
          },
          { isHidden: true }
        )}
      />
    </div>
  );
};

export default RoleCustomizationTable;
