import { Tabs } from 'antd';
import classNames from 'classnames';
import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { ELoaderColor } from '../ui/loader/types';
import Loader from '../ui/loader';
import { ICustomTab, ITabsCustom } from './types';
import ChevronLeft from '../../assets/svg/icons/chevronLeft';
import ChevronRight from '../../assets/svg/icons/chevronRight';
import ErrorPlaceholder from '../ui/errorPlaceholder';
import SearchFailIcon from '../../assets/svg/icons/searchFail';

const defaultOnChangeActiveTab = (key: string, tabs: ICustomTab[]): ICustomTab =>
  tabs.find((tab) => tab.tabId === key) || tabs[0];

const TabsCustom: FC<ITabsCustom> = (props) => {
  const {
    tabs,
    activeTabKey = tabs?.[0].tabId || '',
    onChangeActiveTab,
    tabsWrapperClassName = '',
    tabsContainerClassName = '',
    tabPaneContainerClassName = '',
    tabPaneFieldTitleClassName = '',
    tabPaneFieldDataClassName = '',
    loading = false,
    emptyText = '',
    emptyIcon = <SearchFailIcon />,
  } = props;

  const [activeKey, setActiveKey] = useState<string>(activeTabKey);

  const tabsRef = useRef<HTMLDivElement | null>(null);
  const scrollElementRef = useRef<HTMLDivElement | null>(null);

  const chevronLeftRef = useRef<HTMLDivElement | null>(null);
  const chevronLefIsHidden = useRef<boolean>(true);
  const chevronRightRef = useRef<HTMLDivElement | null>(null);
  const chevronRightIsHidden = useRef<boolean>(true);

  const showButton = useCallback((element: HTMLDivElement | null, ref: React.MutableRefObject<boolean>) => {
    if (element && ref.current) {
      element.classList.remove('tabs-custom__tabs-button_hide');
      ref.current = false;
    }
  }, []);

  const hideButton = useCallback((element: HTMLDivElement | null, ref: React.MutableRefObject<boolean>) => {
    if (element && !ref.current) {
      element.classList.add('tabs-custom__tabs-button_hide');
      ref.current = true;
    }
  }, []);

  useEffect(() => {
    if (!scrollElementRef.current && tabsRef.current) {
      const element = tabsRef.current.getElementsByClassName('ant-tabs-nav-list')[0];
      if (element) {
        scrollElementRef.current = element as HTMLDivElement;
        if (tabsRef.current.offsetWidth < element.scrollWidth && chevronRightRef) {
          showButton(chevronRightRef.current, chevronRightIsHidden);
        }
      }
    }
  }, [tabsRef, tabsRef.current]);

  useEffect(() => {
    setActiveKey(activeTabKey);
  }, [activeTabKey]);

  const handleOnChangeTab = useCallback(
    (key: string) => {
      if (onChangeActiveTab) {
        onChangeActiveTab(key);
      } else {
        setActiveKey(defaultOnChangeActiveTab(key, tabs || []).tabId);
      }
    },
    [onChangeActiveTab, tabs]
  );

  const getOffsetValue = useCallback((): number => {
    if (scrollElementRef.current) {
      const style = window.getComputedStyle(scrollElementRef.current);
      const matrix = new DOMMatrixReadOnly(style.transform);
      return matrix.m41;
    }
    return 0;
  }, [scrollElementRef]);

  const onScroll = useCallback(() => {
    if (scrollElementRef.current && tabsRef.current) {
      const offset = getOffsetValue();
      const gap = tabsRef.current.scrollWidth - scrollElementRef.current.scrollWidth;
      if (offset > -20) {
        showButton(chevronRightRef.current, chevronRightIsHidden);
        hideButton(chevronLeftRef.current, chevronLefIsHidden);
      } else if (offset < gap + 50) {
        hideButton(chevronRightRef.current, chevronRightIsHidden);
        showButton(chevronLeftRef.current, chevronLefIsHidden);
      } else {
        showButton(chevronRightRef.current, chevronRightIsHidden);
        showButton(chevronLeftRef.current, chevronLefIsHidden);
      }
    }
  }, [getOffsetValue, hideButton, showButton]);

  const scrollTo = useCallback(
    (toRight = true) =>
      (e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
        e.preventDefault();
        if (scrollElementRef.current && tabsRef.current) {
          const gap = tabsRef.current.scrollWidth - scrollElementRef.current.scrollWidth;
          const offset = getOffsetValue();
          let translate = offset + 300 * (toRight ? -1 : 1);

          if (toRight) {
            if (translate < gap) {
              translate = gap;
              hideButton(chevronRightRef.current, chevronRightIsHidden);
              showButton(chevronLeftRef.current, chevronLefIsHidden);
            } else {
              showButton(chevronLeftRef.current, chevronLefIsHidden);
            }
          } else if (!toRight) {
            if (translate >= 0) {
              translate = 0;
              hideButton(chevronLeftRef.current, chevronLefIsHidden);
              showButton(chevronRightRef.current, chevronRightIsHidden);
            } else {
              showButton(chevronRightRef.current, chevronRightIsHidden);
            }
          }

          scrollElementRef.current.style.transform = `translate(${translate}px, 0px)`;
        }
      },
    [getOffsetValue, hideButton, showButton]
  );

  const OperationsSlot = {
    left: (
      <div
        ref={chevronLeftRef}
        role="presentation"
        onClick={scrollTo(false)}
        className="tabs-custom__tabs-button tabs-custom__tabs-button_left tabs-custom__tabs-button_hide"
      >
        <ChevronLeft />
      </div>
    ),
    right: (
      <div
        ref={chevronRightRef}
        role="presentation"
        onClick={scrollTo()}
        className="tabs-custom__tabs-button tabs-custom__tabs-button_right tabs-custom__tabs-button tabs-custom__tabs-button_hide"
      >
        <ChevronRight />
      </div>
    ),
  };

  if (!tabs || tabs.length === 0) {
    return emptyText ? (
      <ErrorPlaceholder containerClassName="device-event-table__error-wrapper" text={emptyText} icon={emptyIcon} />
    ) : (
      <div className="tabs-custom__loader-wrapper">
        <Loader color={ELoaderColor.blue} />
      </div>
    );
  }

  return (
    <div ref={tabsRef} className={classNames('tabs-custom', tabsWrapperClassName)}>
      {loading && (
        <div className="tabs-custom__loader-wrapper">
          <Loader color={ELoaderColor.blue} />
        </div>
      )}
      <Tabs
        tabBarExtraContent={OperationsSlot}
        activeKey={activeKey}
        onChange={handleOnChangeTab}
        className={classNames('tabs-custom__tabs', tabsContainerClassName, {
          'tabs-custom__tabs_hidden': loading,
        })}
        tabBarGutter={24}
        defaultActiveKey={tabs.find((tab) => !tab.disabled)?.tabId || ''}
        onTabScroll={onScroll}
      >
        {tabs.map((tab) => (
          <Tabs.TabPane
            key={tab.tabId}
            disabled={tab.disabled}
            tab={
              <div className={classNames('tabs-custom__tab-container', tabPaneContainerClassName)}>
                {tab.data?.map((item, index) => (
                  <React.Fragment key={index}>
                    <span className={classNames('tabs-custom__tab__text', tabPaneFieldTitleClassName)}>
                      {item.fieldTitle}
                    </span>
                    <span className={classNames('tabs-custom__tab__data-text', tabPaneFieldDataClassName)}>
                      {item.fieldData}
                    </span>
                  </React.Fragment>
                ))}
              </div>
            }
          >
            {tab.children}
          </Tabs.TabPane>
        ))}
      </Tabs>
    </div>
  );
};

export default TabsCustom;
