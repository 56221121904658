import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { IFlatDisablingPayment } from './types';
import FLatDisablingPaymentItem from './disablingPaymentItem';
import Button from '../../../ui/button';
import { ButtonType } from '../../../ui/button/types';
import { useApi } from '../../../../hooks/useApi';
import { IFlatDisablePayment } from '../../../../typings/flat';
import { deleteRequest, getRequest } from '../../../../api';
import { endFlatDisableUrl, getFlatPaymentDisableListUrl } from '../../../../constants/api';
import DisablingPaymentModal from './disablingPaymentModal';
import { IConfirmData } from '../../../ui/universalModal/types';
import { defaultConfirm } from '../../../ui/universalModal/config';
import UniversalModal from '../../../ui/universalModal';

const FLatDisablingPayment: FC<IFlatDisablingPayment> = (props) => {
  const { permissions, flatId, tabId, activeTabKey } = props;

  const [modalIsOpen, setModalIsOpen] = useState(false);

  const { data: disablePayments, sendRequest: getDisablePayments } = useApi<IFlatDisablePayment[] | null>(getRequest);
  const { sendRequest: setUpdateDisablePeriod } = useApi(deleteRequest);

  const [periods, setPeriods] = useState<IFlatDisablePayment[]>();

  const [confirmData, setConfirmData] = useState<IConfirmData>(defaultConfirm);

  const closeConfirm = useCallback(() => setConfirmData(defaultConfirm), []);

  useEffect(() => {
    if (tabId === activeTabKey && flatId) {
      getDisablePayments(getFlatPaymentDisableListUrl(flatId));
    }
  }, [tabId, activeTabKey]);

  useEffect(() => {
    setPeriods((disablePayments || []).slice().reverse());
  }, [disablePayments]);

  const onAddPeriod = useCallback(() => {
    setModalIsOpen(true);
  }, []);

  const onCloseModal = useCallback(() => setModalIsOpen(false), []);

  const onAccept = useCallback(() => {
    onCloseModal();
    if (flatId) {
      getDisablePayments(getFlatPaymentDisableListUrl(flatId));
    }
  }, [flatId, getDisablePayments, onCloseModal]);

  const changeActivePeriod = useCallback(
    async (id: string) => {
      setConfirmData({
        isOpen: true,
        description: 'Вы действительно хотите завершить период отключения оплаты?',
        buttons: [
          {
            label: 'Да',
            type: ButtonType.primary,
            onClick: async () => {
              closeConfirm();
              if (flatId) {
                await setUpdateDisablePeriod(endFlatDisableUrl(flatId, id));
                getDisablePayments(getFlatPaymentDisableListUrl(flatId));
              }
            },
          },
          {
            label: 'Нет',
            type: ButtonType.secondary,
            onClick: closeConfirm,
          },
        ],
      });
    },
    [closeConfirm, flatId, getDisablePayments, setUpdateDisablePeriod]
  );

  const isAnActivePeriod = useMemo(() => !!periods?.find((item) => !item?.actualEndDate), [periods]);

  return (
    <div className="disabling-payment">
      <UniversalModal data={confirmData} onClose={closeConfirm} />
      <DisablingPaymentModal isOpen={modalIsOpen} onClose={onCloseModal} flatId={flatId} onAccept={onAccept} />
      <div className="disabling-payment__title">
        <div className="disabling-payment__title-label">Отключение оплаты</div>
        <div className="disabling-payment__title-btn">
          <Button disabled={!permissions?.edit || isAnActivePeriod} type={ButtonType.outline} onClick={onAddPeriod}>
            Добавить период
          </Button>
        </div>
      </div>
      <div>
        {periods?.map((item, index) => (
          <FLatDisablingPaymentItem
            isAnActivePeriod={isAnActivePeriod}
            index={index}
            changeActivePeriod={changeActivePeriod}
            permissions={permissions}
            key={item.id}
            data={item}
          />
        ))}
      </div>
    </div>
  );
};

export default FLatDisablingPayment;
