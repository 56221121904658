import React, { FC, useCallback, useMemo } from 'react';
import moment from 'moment';
import { Switch } from 'antd';
import { IFlatDisablingPaymentItem } from './types';
import PeriodDatePicker from '../../../../ui/periodDatePicker';
import { dateFormatNoTime } from '../../../../../constants/date';
import Input from '../../../../ui/input';

const FLatDisablingPaymentItem: FC<IFlatDisablingPaymentItem> = (props) => {
  const { data, permissions, changeActivePeriod = () => {}, index, isAnActivePeriod } = props;

  const isActive = useMemo(() => !data?.actualEndDate, [data]);

  const onChange = useCallback(() => {
    if (data?.id) {
      changeActivePeriod(data?.id);
    }
  }, [changeActivePeriod, data?.id]);

  return (
    <>
      {isAnActivePeriod && index === 1 && (
        <div className="disabling-payment__sub-title">История предыдущих отключений</div>
      )}

      <div className="disabling-payment-item">
        <div className="disabling-payment-item__date-picker">
          <PeriodDatePicker
            showCalendarIcon={false}
            isCanUnlimited
            title="Период действия"
            firstDate={data?.startDate || ''}
            secondDate={data?.endDate || ''}
            format={dateFormatNoTime}
            placeholder="Не ограничен"
            isDisabledStyle
            disabled
          />
          <div className="disabling-payment-item__date-picker-status">
            {isActive ? (
              moment().diff(moment(data?.startDate), 'minutes') < 0 ? (
                <div className="disabling-payment-item__date-picker-status_not-active-yet">
                  Дата начала: {moment(data?.startDate || '').format(dateFormatNoTime)}
                </div>
              ) : (
                <div className="disabling-payment-item__date-picker-status_active">Действует</div>
              )
            ) : (
              <div className="disabling-payment-item__date-picker-status_inactive">
                Дата завершения: {moment(data?.actualEndDate || '').format(dateFormatNoTime)}
              </div>
            )}
          </div>
        </div>
        <div className="disabling-payment-item__comment">
          <Input title="Комментарий" value={data?.description} isDisabledStyle disabled />
          <div
            className={`disabling-payment-item__comment-status disabling-payment-item__comment-status_${
              isActive ? 'active' : 'inactive'
            }`}
          >
            Автор отключения: {data?.creatorEmail}
          </div>
        </div>
        {isActive && (
          <div className="disabling-payment-item__switch">
            <Switch disabled={!permissions?.edit} checked={isActive} onChange={onChange} />
          </div>
        )}
      </div>
    </>
  );
};

export default FLatDisablingPaymentItem;
