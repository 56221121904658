import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import moment, { Moment } from 'moment';
import { IFlatTabComponent } from '../types';
import { useApi } from '../../../hooks/useApi';
import { getRequest, getRequestWithArrayInParams } from '../../../api';
import {
  getFlatDevicesUrl,
  getFlatEventsUrl,
  getFlatKeysUrl,
  getFlatsSubscribersUrl,
  getSubscriberDeviceLogActionTypeListUrl,
} from '../../../constants/api';
import { dateFormatNoTime, dateISOFormatNoTimeZone } from '../../../constants/date';
import Select from '../../ui/select';
import PeriodDatePicker from '../../ui/periodDatePicker';
import { IFlatEvent, IFlatKey, IFlatSubscriber, IFlatsPageDevice } from '../../../typings/flat';
import { IFlatDeviceEventsApiParams } from '../../../api/deviceEvent/types';
import SelectMultiple from '../../ui/selectMultiple';
import { selectAllOptionKey } from '../../../constants/select';
import FlatsJournalTable from './journalTable';
import { IApiResponse } from '../../../typings/api';
import { IFlatEventActionType } from '../../../typings/subscribers/deviceLog';
import { getEntitiesForSubscriber } from '../../../api/subscribers';
import { ISelectMultipleOption } from '../../ui/selectMultiple/types';

const FlatJournalTab: FC<IFlatTabComponent> = (props) => {
  const { flatId = '', tabId, activeTabKey } = props;

  const {
    data: subscribers,
    sendRequest: getSubscribers,
    loading: subscribersLoading,
  } = useApi<IFlatSubscriber[]>(getRequest);

  const { data: devices, sendRequest: getDevices, loading: devicesLoading } = useApi<IFlatsPageDevice[]>(getRequest);

  const { data: keys, sendRequest: sendRequestKeys, loading: keysLoading } = useApi<IFlatKey[]>(getRequest);

  const {
    data: events,
    sendRequest: getEvents,
    loading: eventsLoading,
  } = useApi<IApiResponse<IFlatEvent>>(getRequestWithArrayInParams);

  const {
    data: actionTypeList,
    sendRequest: sendRequestActionTypeList,
    loading: actionTypeListLoading,
  } = useApi<IFlatEventActionType[]>(getEntitiesForSubscriber);

  const [eventsApiParams, setEventsApiParams] = useState<IFlatDeviceEventsApiParams>({
    page: 0,
    count: 10,
    dateTo: moment()?.toISOString() || '',
    dateFrom: moment()?.subtract(1, 'month')?.startOf('day').toISOString() || '',
  });

  const requestData = useCallback(
    async (newApiParams = eventsApiParams) => {
      setEventsApiParams(newApiParams);
      await getEvents(getFlatEventsUrl(flatId), { params: newApiParams });
    },
    [eventsApiParams, flatId, getEvents]
  );

  useEffect(() => {
    if (tabId === activeTabKey) {
      requestData();
      sendRequestKeys(getFlatKeysUrl(flatId));
      getSubscribers(getFlatsSubscribersUrl(flatId));
      getDevices(getFlatDevicesUrl(flatId));
      sendRequestActionTypeList(getSubscriberDeviceLogActionTypeListUrl());
    }
  }, [tabId, activeTabKey]);

  const handleOnChangeDate = useCallback(
    async (firstDate?: Moment, secondDate?: Moment) => {
      const newEventsParams = {
        ...eventsApiParams,
        dateFrom: firstDate?.startOf('day').format(dateISOFormatNoTimeZone) || '',
        dateTo: secondDate?.endOf('day').format(dateISOFormatNoTimeZone) || '',
        page: 0,
      };
      await requestData(newEventsParams);
    },
    [eventsApiParams, requestData]
  );

  const handleOnChangeTablePage = useCallback(
    async (page: number) => {
      const newEventsParams = {
        ...eventsApiParams,
        page: page - 1,
      };
      await requestData(newEventsParams);
    },
    [eventsApiParams, requestData]
  );

  const handleOnChangeSelect = useCallback(
    (key: string) => (val: any) => {
      const newEventsParams = {
        ...eventsApiParams,
        [key]: val,
        page: 0,
      };
      requestData(newEventsParams);
    },
    [eventsApiParams, requestData]
  );

  const subscribersOptions = useMemo(
    () =>
      subscribers?.map((object) => ({
        value: object.id || '',
        title: `${object.lastName} ${object.firstName} ${object.middleName}`,
      })) || [],
    [subscribers]
  );

  const keysOptions = useMemo(
    () =>
      keys?.map((object) => ({
        value: object.id || '',
        title: object.keyAlias || '',
      })) || [],
    [keys]
  );

  const devicesOptions = useMemo(
    () =>
      devices?.map((object) => ({
        value: object.id || '',
        title: object.name || '',
      })) || [],
    [devices]
  );

  const selectActionTypeListOptions = useMemo(
    () =>
      actionTypeList?.map<ISelectMultipleOption>((action) => ({
        value: action.type || '',
        title: action.displayName || '',
      })) || [],
    [actionTypeList]
  );

  return (
    <div className="flat-journal-tab subscriptions">
      <div className="payments-filters">
        <div className="payments-filters__inputs">
          <SelectMultiple
            isSelectedAllDefault
            title="Абонент"
            values={eventsApiParams?.subscriberIds}
            options={subscribersOptions}
            onChange={handleOnChangeSelect('subscriberIds')}
            notAllowAllChoice
          />
          <Select
            isAllOption
            title="Обрудование"
            value={eventsApiParams?.deviceId || ''}
            onChange={handleOnChangeSelect('deviceId')}
            options={devicesOptions}
          />
          <SelectMultiple
            isSelectedAllDefault
            title="Уникальный номер / псевдоним"
            values={eventsApiParams?.keys}
            options={keysOptions}
            onChange={handleOnChangeSelect('keys')}
            notAllowAllChoice
          />
        </div>
        <div className="payments-filters__inputs">
          <PeriodDatePicker
            title="Период"
            placeholder="Выберите период"
            firstDate={eventsApiParams?.dateFrom}
            secondDate={eventsApiParams?.dateTo}
            onChange={handleOnChangeDate}
            showTime={false}
            format={dateFormatNoTime}
          />
          <Select
            isAllOption
            title="Тип события"
            value={eventsApiParams.type || ''}
            onChange={handleOnChangeSelect('type')}
            options={selectActionTypeListOptions}
          />
        </div>
      </div>
      <FlatsJournalTable
        loading={subscribersLoading || keysLoading || eventsLoading || devicesLoading || actionTypeListLoading}
        pageSize={events?.pageSize}
        total={events?.totalCount}
        currentPage={events?.page}
        onChangePage={handleOnChangeTablePage}
        journal={events?.items || []}
      />
    </div>
  );
};

export default FlatJournalTab;
