import { DataNode } from 'antd/es/tree';
import { Key } from 'antd/lib/table/interface';
import React from 'react';
import { ITreeEntityKey, TreeNodeType, ITreeNode, IFoundNode, IAccessPoint, ICameraPoint } from '../typings/treeNode';
import { ISettings } from '../typings/profile';

export const getAccessGroupTreeKey = (id: string, type: string, index: string): string => `${id}_${type}_${index}`;

export const resolveAccessGroupTreeKey = (key: Key) => key.toString().split('_');

export const getTreeKey = (key: string, index: number, type: TreeNodeType): string =>
  `${key}${key ? '_' : ''}${index}-${type}`;

export const resolveTreeKey = (key = ''): ITreeEntityKey[] =>
  key.split('_').map((item: string) => {
    const splitItem = item.split('-');
    return {
      index: Number(splitItem[0]),
      type: splitItem[1] as TreeNodeType,
    };
  });

export const findTreeNodeByKey = (
  keys: ITreeEntityKey[],
  nodes: ITreeNode[],
  keysCounter = 0,
  parentKeys: string[] = [],
  parent: ITreeNode | null = null
): IFoundNode => {
  const key = keys[keysCounter];
  const node = nodes[key.index];
  let result: IFoundNode = { node, parentKeys, parent, nodeIndex: key.index };

  if (keys.length - 1 !== keysCounter) {
    parentKeys.push(getTreeKey(parentKeys.length ? parentKeys[parentKeys.length - 1] : '', key.index, key.type));

    if (keys[keysCounter + 1].type === TreeNodeType.accessPoint) {
      result = {
        node: node.accessPoints?.[keys[keysCounter + 1].index] || null,
        parentKeys,
        parent: node,
        nodeIndex: keys[keysCounter + 1].index,
      };
    } else if (keys[keysCounter + 1].type === TreeNodeType.camera) {
      result = {
        node: node.cameras?.[keys[keysCounter + 1].index] || null,
        parentKeys,
        parent: node,
        nodeIndex: keys[keysCounter + 1].index,
      };
    } else if (node?.childItems) {
      result = findTreeNodeByKey(keys, node.childItems || [], keysCounter + 1, parentKeys, node);
    }
  }

  return result;
};

export const setDataNodePropsToTreeNodes = (
  dataTreeNode: ITreeNode[],
  nodeProps?: DataNode,
  accessPointProps?: DataNode
): ITreeNode[] =>
  dataTreeNode.map<ITreeNode>((item) => ({
    ...item,
    dataNodeProps: nodeProps,
    childItems: setDataNodePropsToTreeNodes(item.childItems || [], nodeProps, accessPointProps),
    accessPoints: item.accessPoints?.map<IAccessPoint>((point) => ({
      ...point,
      dataNodeProps: accessPointProps,
    })),
  }));

export type TRenderNodeFunction<T> = (node: T, key: string, deep: number) => React.ReactNode;

const renderTreeAccessPoint = (
  points: IAccessPoint[],
  renderAccessPoint: TRenderNodeFunction<IAccessPoint>,
  key: string,
  deep: number
): DataNode[] =>
  points.map((point, index) => {
    const pointKey = getTreeKey(key, index, TreeNodeType.accessPoint);
    return {
      ...point.dataNodeProps,
      title: renderAccessPoint({ ...point }, pointKey, deep),
      key: pointKey,
      isLeaf: true,
    };
  });

const renderTreeCamera = (
  cameras: ICameraPoint[],
  renderCamera: TRenderNodeFunction<ICameraPoint>,
  key: string,
  deep: number
): DataNode[] =>
  cameras.map((camera, index) => {
    const pointKey = getTreeKey(key, index, TreeNodeType.camera);
    return {
      title: renderCamera({ ...camera }, pointKey, deep),
      key: pointKey,
      isLeaf: true,
    };
  });

export const renderTreeDataNodes = (
  data: ITreeNode[],
  renderAccessPoint: TRenderNodeFunction<IAccessPoint>,
  renderCameras: TRenderNodeFunction<ICameraPoint>,
  renderNode: TRenderNodeFunction<ITreeNode> = (node) => node.name,
  settings: ISettings = {},
  key = '',
  deep = 0
): DataNode[] =>
  data.map((node: ITreeNode, index: number) => {
    const newKey = getTreeKey(key, index, node.type || TreeNodeType.accessPoint);
    return {
      ...node.dataNodeProps,
      title: renderNode({ ...node }, newKey, deep),
      key: newKey,
      isLeaf: !(
        (node.childItems && node.childItems.length > 0) ||
        (node.accessPoints && node.accessPoints.length > 0) ||
        (node.cameras && node.cameras.length > 0)
      ),
      children: [
        ...renderTreeDataNodes(
          node.childItems || [],
          renderAccessPoint,
          renderCameras,
          renderNode,
          settings,
          newKey,
          deep + 1
        ),
        ...renderTreeAccessPoint(node.accessPoints || [], renderAccessPoint, newKey, deep),
        ...(settings.camera ? renderTreeCamera(node.cameras || [], renderCameras, newKey, deep) : []),
      ],
    };
  });

export type TRenderAccessPointFunction<T> = (node: T, key: string, field: string) => React.ReactNode;

const renderTreeDeviceAccessPoint = (
  points: IAccessPoint[],
  renderAccessPoint: TRenderAccessPointFunction<IAccessPoint>,
  key: string
): DataNode[] => {
  const result: DataNode[] = [];
  points.forEach((point, index) => {
    const pointKey = getTreeKey(key, index, TreeNodeType.accessPoint);
    if (point.inputDevice) {
      result.push({
        ...point.dataNodeProps,
        title: renderAccessPoint({ ...point }, pointKey, 'inputDevice'),
        key: `${pointKey}__inputDevice`,
        isLeaf: true,
      });
    }
    if (point.outputDevice) {
      result.push({
        ...point.dataNodeProps,
        title: renderAccessPoint({ ...point }, pointKey, 'outputDevice'),
        key: `${pointKey}__outputDevice`,
        isLeaf: true,
      });
    }
  });
  return result;
};

export const renderDeviceTreeDataNodes = (
  data: ITreeNode[],
  renderAccessPoint: TRenderAccessPointFunction<IAccessPoint>,
  renderNode: TRenderNodeFunction<ITreeNode> = (node) => node.name,
  key = '',
  deep = 0
): DataNode[] =>
  data.map((node: ITreeNode, index: number) => {
    const newKey = getTreeKey(key, index, node.type || TreeNodeType.accessPoint);
    return {
      ...node.dataNodeProps,
      title: renderNode({ ...node }, newKey, deep),
      key: newKey,
      isLeaf: !((node.childItems && node.childItems.length > 0) || (node.accessPoints && node.accessPoints.length > 0)),
      children: [
        ...renderDeviceTreeDataNodes(node.childItems || [], renderAccessPoint, renderNode, newKey, deep + 1),
        ...renderTreeDeviceAccessPoint(node.accessPoints || [], renderAccessPoint, newKey),
      ],
    };
  });

export const getDefaultExpandedNodes = (nodes: DataNode[], keys: Key[] = []): Key[] => {
  nodes.forEach((node) => {
    const key = resolveTreeKey(node.key.toString());
    const { type } = key[key.length - 1];
    if (type === TreeNodeType.restrictedArea || node.key === TreeNodeType.object) {
      keys.push(node.key);
      getDefaultExpandedNodes(node.children || [], keys);
    }
  });
  return keys;
};
