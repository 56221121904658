import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import moment from 'moment';
import { getHardwareSettings, setHardwareSettings } from '../../../../../../api/hardwareSettings';
import { getSystem } from '../../../../../../api/systems';
import { getDeviceCategoriesUrl, getHardwareSettingsUrl, sendDeviceSettingsUrl } from '../../../../../../constants/api';
import { useApi } from '../../../../../../hooks/useApi';
import {
  IHardwareParam,
  IHardwareSettings,
  IHardwareShortParam,
} from '../../../../../../typings/systems/hardwareSettings';
import { IAccessPointDeviceCategory, TreeNodeType } from '../../../../../../typings/treeNode';
import Message from '../../../../../message';
import TabNavButtons from '../../../../../tabs/tabNavButtons';
import { tabNavButtonsDefault } from '../../../../../tabs/tabNavButtons/utils';
import { InputStatus } from '../../../../../ui/input/types';
import Loader from '../../../../../ui/loader';
import { ELoaderColor } from '../../../../../ui/loader/types';
import UniversalModal from '../../../../../ui/universalModal';
import { IConfirmData } from '../../../../../ui/universalModal/types';
import SettingsSection from '../advancedOptions';
import { IHardwarePage, IHardwareOptionValue } from '../../../types';
import { defaultConfirm, saveChangesModal } from '../../../../../ui/universalModal/config';
import { ESystemTabErrorCodes } from '../../../../../../api/systems/types';
import { useAppDispatch, useAppSelector } from '../../../../../../hooks/hooks';
import { getClickedSidebarTab } from '../../../../../../store/selectors/sidebar';
import { setClickedSidebarTab } from '../../../../../../store/slices/sidebar';
import {
  findHardwareInInvalidList,
  replacedOptionValues,
  setInvalidParams,
  updateOptionValues,
  validateDeviceAdvancedOptions,
  validateOption,
} from '../../../calculations';
import { defaultFiveThousandOptions, defaultRequiredValue, IFiveThousandthOptionsData } from '../../../config';
import FiveThousandOptions from './fiveThousandOptions';
import { postRequest } from '../../../../../../api';
import { EFiveThousandSettingsTab } from './types';
import Tabs from '../../../../../tabs';
import TractsOptions from './tractsOptions';
import { dateDefaultFormat } from '../../../../../../constants/date';
import EditSquareIcon from '../../../../../../assets/svg/icons/editSquare';
import TractsModal from './tractsModal';
import { ButtonType } from '../../../../../ui/button/types';
import { checkIsAdmin } from '../../../../../../store/selectors/profile';

const FiveThousandDevice: FC<IHardwarePage> = (props) => {
  const {
    systemId,
    tabId = '',
    activeTabKey = '',
    updateAvailableTabs = () => {},
    isWasChange = false,
    setChange = () => {},
    repeatChangeTab = () => {},
    chancelChangeTab = () => {},
    clickedTab = '',
    permissions = {},
    getSystemData = () => {},
    selectedNode,
    clickedNode,
    repeatSelectNode = () => {},
    activeInvalidHardware,
    invalidHardwareList = [],
    updateAfterSave = () => {},
  } = props;

  const {
    data: settings,
    sendRequest: requestSettings,
    loading: settingsLoading,
  } = useApi<IHardwareSettings>(getHardwareSettings);

  const { sendRequest: sendSettings } = useApi(postRequest);

  const { sendRequest: updateSettings } = useApi(setHardwareSettings);
  const { data: deviceCategories, sendRequest: requestDeviceCategories } =
    useApi<IAccessPointDeviceCategory[]>(getSystem);

  const [settingsCopy, setSettingsCopy] = useState<IHardwareSettings | null>(null);

  const [wasSelected, setSelected] = useState<boolean>(false);
  const [isDeviceOptionsChange, setDeviceOptionsChange] = useState<boolean>(false);

  const [confirmData, setConfirmData] = useState<IConfirmData>(defaultConfirm);

  const [fiveThousandOptions, setFiveThousandOptionsOptions] = useState<IFiveThousandthOptionsData>(
    JSON.parse(JSON.stringify(defaultFiveThousandOptions))
  );

  const navigate = useNavigate();

  const clickedSidebarTab = useAppSelector(getClickedSidebarTab);

  const dispatch = useAppDispatch();

  const [activeTab, setActiveTab] = useState<string>(EFiveThousandSettingsTab.main);

  const nodeType = useMemo(() => selectedNode?.itemType || TreeNodeType.device, [selectedNode]);

  const [isTractsModalOpen, setTractsModalOpen] = useState(false);

  const isAdmin = useAppSelector(checkIsAdmin);

  const changeSettings = useCallback(
    (data: any, wasChange = true) => {
      if (!isWasChange && wasChange) {
        setChange(true);
      }
      setSettingsCopy({ ...data });
    },
    [isWasChange, setChange]
  );

  const updateFiveThousandOptions = useCallback(
    (values: IHardwareOptionValue[], wasChange = true, optionsValue = fiveThousandOptions) => {
      const [newOptionsCopy, newSettingsCopy] = updateOptionValues(values, optionsValue, settingsCopy);
      if (nodeType === TreeNodeType.device && values.find((item) => item.fieldName === 'deviceCategory')) {
        newOptionsCopy.selectedDeviceTypeId = { ...defaultRequiredValue };
      }
      if (!isDeviceOptionsChange && wasChange) {
        setDeviceOptionsChange(true);
      }
      changeSettings(newSettingsCopy, wasChange);
      setFiveThousandOptionsOptions(newOptionsCopy);
    },
    [fiveThousandOptions, settingsCopy, nodeType, isDeviceOptionsChange, changeSettings]
  );

  const onChangeSevenThousandOptions = useCallback(
    (value: any, fieldName: string) => updateFiveThousandOptions([{ value, fieldName }]),
    [updateFiveThousandOptions]
  );

  const resetChanges = useCallback(() => {
    if (isWasChange) {
      setChange(false);
    }
    if (isDeviceOptionsChange) {
      setDeviceOptionsChange(false);
    }
    if (wasSelected) {
      setSelected(false);
    }
  }, [isDeviceOptionsChange, isWasChange, setChange, wasSelected]);

  useEffect(() => {
    if (tabId === activeTabKey) {
      setSettingsCopy(null);
      resetChanges();
    }
  }, [activeTabKey]);

  const getInvalidData = useCallback(
    (id: string) =>
      activeInvalidHardware && id === activeInvalidHardware.id
        ? activeInvalidHardware
        : invalidHardwareList?.length
        ? findHardwareInInvalidList(invalidHardwareList, id)
        : 0,
    [activeInvalidHardware, invalidHardwareList]
  );

  const parseSettings = useCallback(() => {
    const newSettings = JSON.parse(JSON.stringify(settings));
    if (newSettings) {
      const newMainOptions = { ...fiveThousandOptions };
      replacedOptionValues(isDeviceOptionsChange, newSettings, newMainOptions);
      const invalidData = getInvalidData(newSettings.id);

      if (invalidData) {
        if (invalidData.invalidParams && invalidData.invalidParams.length > 0) {
          invalidData.invalidParams.forEach((id: any) => {
            const param = newSettings.accessPointTypeParams?.find((item: IHardwareParam) => item.id === id);
            if (param) {
              param.isError = true;
            }
          });
        }
        setInvalidParams(invalidData, newMainOptions);
      }
      setFiveThousandOptionsOptions(newMainOptions);
      setSettingsCopy(newSettings);
    }
  }, [settings, fiveThousandOptions, isDeviceOptionsChange, getInvalidData]);

  useEffect(() => {
    parseSettings();
  }, [settings]);

  useEffect(() => {
    if (deviceCategories && deviceCategories.length && nodeType === TreeNodeType.device) {
      if (fiveThousandOptions && !fiveThousandOptions.deviceCategory.value) {
        const value = deviceCategories[0];
        fiveThousandOptions.deviceCategory.value = value;
      }
    }
  }, [deviceCategories]);

  const requestData = useCallback(
    async (
      withoutParams = false,
      id = selectedNode?.itemId,
      selectedDeviceTypeId = fiveThousandOptions.selectedDeviceTypeId.value,
      selectedDeviceRevisionId = fiveThousandOptions.selectedDeviceRevisionId.value,
      selectedDeviceVersionId = fiveThousandOptions.selectedDeviceVersionId.value
    ) => {
      if (id) {
        const newMainOptions =
          isDeviceOptionsChange || !withoutParams ? { ...fiveThousandOptions } : { ...defaultFiveThousandOptions };
        updateFiveThousandOptions(
          [
            {
              value: selectedDeviceTypeId,
              fieldName: 'selectedDeviceTypeId',
            },
            {
              value: selectedDeviceRevisionId,
              fieldName: 'selectedDeviceRevisionId',
            },
            {
              value: selectedDeviceVersionId,
              fieldName: 'selectedDeviceVersionId',
            },
            {
              value: false,
              fieldName: 'isUpdateAvailable',
            },
          ],
          false,
          newMainOptions
        );
        if (withoutParams) {
          await requestSettings(getHardwareSettingsUrl(id));
        } else if (selectedDeviceTypeId && selectedDeviceRevisionId && selectedDeviceVersionId) {
          await requestSettings(getHardwareSettingsUrl(id), {
            params: { selectedDeviceTypeId, selectedDeviceRevisionId, selectedDeviceVersionId },
          });
        }
      }
    },
    [isDeviceOptionsChange, fiveThousandOptions, requestSettings, selectedNode?.itemId, updateFiveThousandOptions]
  );

  const handleOnChangeDeviceType = useCallback(
    async (
      selectedDeviceTypeId: string | null,
      selectedDeviceRevisionId: string | null,
      selectedDeviceVersionId: string | null
    ) => {
      if (selectedNode) {
        setSelected(true);
        setChange(true);
        await requestData(
          false,
          selectedNode.itemId,
          selectedDeviceTypeId,
          selectedDeviceRevisionId,
          selectedDeviceVersionId
        );
      }
    },
    [requestData, selectedNode, setChange]
  );

  const checkValidAdvancedOptions = useCallback(() => {
    let isValid = true;
    if (settingsCopy) {
      isValid = validateDeviceAdvancedOptions(settingsCopy);
      setSettingsCopy({ ...settingsCopy });
    }
    return isValid;
  }, [settingsCopy]);

  const validateInputs = useCallback(
    (mOptions: IFiveThousandthOptionsData) => {
      let mainOptionsValid = true;

      const newMainOptions = { ...mOptions };
      Object.keys(newMainOptions).forEach((name) => {
        mainOptionsValid = validateOption(name, newMainOptions) && mainOptionsValid;
        if (!mainOptionsValid) setActiveTab(EFiveThousandSettingsTab.main);
      });
      setFiveThousandOptionsOptions(newMainOptions);

      return checkValidAdvancedOptions() && mainOptionsValid;
    },
    [checkValidAdvancedOptions]
  );

  const handleOnSave = useCallback(
    async (options: IFiveThousandthOptionsData = fiveThousandOptions) => {
      if (validateInputs(options) && settingsCopy && selectedNode) {
        const params: IHardwareShortParam[] =
          settingsCopy.accessPointTypeParams?.map(({ id, value }) => ({
            paramId: id || '',
            value,
          })) || [];
        const data = {
          ...settingsCopy,
          selectedDeviceTypeId: options.selectedDeviceTypeId.value,
          accessPointTypeValues: params,
          commutatorSettings: settingsCopy.commutatorSettings,
          isUpdateAvailable: !!settingsCopy.isUpdateAvailable,
        };
        const resError = await updateSettings(getHardwareSettingsUrl(selectedNode.itemId), data);
        if (resError?.response?.data) {
          const id = resError?.response?.data?.additionalInformation?.paramId;
          const name = resError?.response?.data?.additionalInformation?.paramName || '';

          if (
            resError?.response?.data?.errorCodes === ESystemTabErrorCodes.SerialNumber ||
            resError?.response?.data?.errorCodes === ESystemTabErrorCodes.SerialNumberFormatValidation ||
            resError?.response?.data?.additionalInformation === ESystemTabErrorCodes.SerialNumber
          ) {
            setFiveThousandOptionsOptions({
              ...fiveThousandOptions,
              serialNumber: {
                ...fiveThousandOptions.serialNumber,
                errorText: resError?.response?.data?.message,
                status: InputStatus.error,
              },
            });
          }
          if (id) {
            const field = settingsCopy?.accessPointTypeParams?.find((item) => item.id === id);
            if (field) {
              field.isError = true;
            }
          }
          if (name) {
            const camelCaseName = name.replace(name[0], name[0].toLowerCase());
            if (fiveThousandOptions[camelCaseName as keyof IFiveThousandthOptionsData]) {
              setFiveThousandOptionsOptions({
                ...fiveThousandOptions,
                [camelCaseName]: {
                  ...fiveThousandOptions[camelCaseName as keyof IFiveThousandthOptionsData],
                  errorText: resError?.response?.data?.message || 'Поле заполнено не верно',
                  status: InputStatus.error,
                },
              });
            }
          }
        } else {
          await updateAfterSave(settingsCopy?.id || '');
          resetChanges();
          updateAvailableTabs();
          getSystemData();
          return true;
        }
      }
      Message.error({
        content: 'Проверьте правильность заполнения полей',
      });
      return false;
    },
    [
      getSystemData,
      fiveThousandOptions,
      resetChanges,
      selectedNode,
      settingsCopy,
      updateAfterSave,
      updateAvailableTabs,
      updateSettings,
      validateInputs,
    ]
  );

  const handleOnCancel = useCallback(async () => {
    resetChanges();
    await requestData(true);
    if (nodeType === TreeNodeType.device) await requestDeviceCategories(getDeviceCategoriesUrl());
  }, [nodeType, requestData, requestDeviceCategories, resetChanges]);

  const handleOnSaveChanges = useCallback(
    (callback: () => void) => async () => {
      if (await handleOnSave(fiveThousandOptions)) {
        callback();
      } else {
        chancelChangeTab();
      }
      setConfirmData(defaultConfirm);
    },
    [chancelChangeTab, handleOnSave, fiveThousandOptions]
  );

  const closeConfirm = useCallback(() => {
    setConfirmData(defaultConfirm);
    if (clickedTab) {
      chancelChangeTab();
    }
  }, [clickedTab, chancelChangeTab]);

  const sendHardwareData = useCallback(async () => {
    if (await handleOnSave()) {
      await sendSettings(sendDeviceSettingsUrl(systemId || '', settings?.id || ''), {});
      requestData();
    }
  }, [handleOnSave, requestData, sendSettings, settings?.id, systemId]);

  const tryToSendHardwareData = useCallback(async () => {
    if (isWasChange)
      setConfirmData({
        isOpen: true,
        description: 'Перед отправкой необходимо сохранить данные. Сохранить данные и отправить на оборудование?',
        buttons: [
          {
            label: 'Сохранить и отправить',
            type: ButtonType.primary,
            onClick: async () => {
              closeConfirm();
              sendHardwareData();
            },
          },
          {
            label: 'Отмена',
            type: ButtonType.secondary,
            onClick: closeConfirm,
          },
        ],
      });
    else sendHardwareData();
  }, [closeConfirm, isWasChange, sendHardwareData]);

  const handleOnResetChanges = useCallback(
    (callback: () => void) => async () => {
      resetChanges();
      setConfirmData(defaultConfirm);
      callback();
    },
    [resetChanges]
  );

  const checkChanges = useCallback(
    async (callback: () => void) => {
      if (isWasChange || isDeviceOptionsChange) {
        setConfirmData(saveChangesModal(handleOnSaveChanges(callback), handleOnResetChanges(callback)));
      } else {
        callback();
      }
    },
    [handleOnResetChanges, handleOnSaveChanges, isDeviceOptionsChange, isWasChange]
  );

  useEffect(() => {
    if (clickedSidebarTab) {
      checkChanges(() => {
        setChange(false);
        navigate(clickedSidebarTab);
      });
      dispatch(setClickedSidebarTab(null));
    }
  }, [clickedSidebarTab]);

  useEffect(() => {
    if (clickedNode) {
      checkChanges(() => repeatSelectNode());
    }
  }, [clickedNode]);

  useEffect(() => {
    if (clickedTab && activeTabKey === tabId) {
      checkChanges(() => repeatChangeTab());
    }
  }, [clickedTab]);

  useEffect(() => {
    const content: any = document.getElementsByClassName('hardware-settings__content-wrapper');
    if (content[0]) {
      content[0].childNodes[1].scrollTo(0, 0);
    }
  }, [activeTab]);

  const isSelectAllOptions = useMemo(
    () =>
      fiveThousandOptions.selectedDeviceTypeId.value &&
      fiveThousandOptions.selectedDeviceRevisionId.value &&
      fiveThousandOptions.selectedDeviceVersionId.value,
    [
      fiveThousandOptions.selectedDeviceRevisionId.value,
      fiveThousandOptions.selectedDeviceTypeId.value,
      fiveThousandOptions.selectedDeviceVersionId.value,
    ]
  );

  const selectedNodeWasChanges = useCallback(async () => {
    if (selectedNode) {
      resetChanges();
      await requestData(true, selectedNode.itemId);
      if (nodeType === TreeNodeType.device) await requestDeviceCategories(getDeviceCategoriesUrl());
    }
  }, [nodeType, requestData, requestDeviceCategories, resetChanges, selectedNode]);

  const onChange = useCallback(
    (index: number, val: string) => {
      if (settingsCopy?.commutatorSettings) {
        const arrayIndex = settingsCopy.commutatorSettings.findIndex((item) => item.sinkIndex === index);
        if (arrayIndex !== -1) {
          if (val) {
            settingsCopy.commutatorSettings[arrayIndex].selectedDeviceId = val;
            changeSettings({ ...settingsCopy });
          } else {
            changeSettings({
              ...settingsCopy,
              commutatorSettings: settingsCopy.commutatorSettings.filter((item, i) => i !== arrayIndex),
            });
          }
          return;
        }
      }
      changeSettings({
        ...settingsCopy,
        commutatorSettings: [
          ...(settingsCopy?.commutatorSettings || []),
          {
            sinkIndex: index,
            selectedDeviceId: val,
          },
        ],
      });
    },
    [changeSettings, settingsCopy]
  );

  useEffect(() => {
    selectedNodeWasChanges();
  }, [selectedNode]);

  const isConnected = useMemo(
    () => settings?.isConnected || !!settings?.lastSyncDateTime,
    [settings?.isConnected, settings?.lastSyncDateTime]
  );

  return (
    <>
      <UniversalModal data={confirmData} onClose={closeConfirm} />
      <TractsModal
        changeSettings={changeSettings}
        permissions={permissions}
        systemId={systemId}
        settings={settingsCopy}
        isOpen={isTractsModalOpen}
        onCancel={() => setTractsModalOpen(false)}
      />
      {settingsLoading ? (
        <div className="hardware-settings__content hardware-settings__content_empty">
          <div className="hardware-settings__loader-container">
            <Loader color={ELoaderColor.blue} />
          </div>
        </div>
      ) : (
        <div className="hardware-settings__scroll default-scrollbar-override">
          {settings?.lastSyncDateTime && (
            <div className="hardware-settings__sync-date">
              Дата последней отправки конфигурации: {moment(settings?.lastSyncDateTime).format(dateDefaultFormat)}
            </div>
          )}
          {nodeType === TreeNodeType.commutator && (
            <Tabs
              activeTabKey={activeTab}
              onChangeActiveTab={setActiveTab}
              tabsClassName="hardware-settings__tabs"
              tabs={[
                {
                  id: EFiveThousandSettingsTab.main,
                  title: 'Основные настройки',
                  position: 0,
                },
                {
                  id: EFiveThousandSettingsTab.tract,
                  title: 'Настройки трактов',
                  position: 1,
                },
              ]}
            />
          )}
          <div className="hardware-settings__content">
            {activeTab === EFiveThousandSettingsTab.main ? (
              <>
                <FiveThousandOptions
                  isConnected={isConnected}
                  isAdmin={isAdmin}
                  nodeType={nodeType}
                  requestData={() => requestData()}
                  systemId={systemId}
                  settings={settingsCopy}
                  options={fiveThousandOptions}
                  onChange={onChangeSevenThousandOptions}
                  permissions={permissions}
                  deviceCategories={deviceCategories}
                  handleOnChangeDeviceType={handleOnChangeDeviceType}
                  changeSettings={(data) => changeSettings(data)}
                  updateOptions={updateFiveThousandOptions}
                  isSelectAllOptions={isSelectAllOptions}
                  handleOnSave={handleOnSave}
                  setConfirmData={setConfirmData}
                  closeConfirm={closeConfirm}
                />
                {settingsCopy?.accessPointTypeParams &&
                  !!settingsCopy.accessPointTypeParams.length &&
                  isSelectAllOptions && (
                    <SettingsSection
                      permissions={permissions}
                      wasSelected={wasSelected}
                      settings={settingsCopy}
                      changeSettings={(data) => changeSettings(data)}
                    />
                  )}
              </>
            ) : (
              <>
                <div className="hardware-settings__tracts">
                  <div className="hardware-settings__tracts-title">Текущие настройки</div>
                  {permissions.edit && (
                    <div
                      role="presentation"
                      onClick={() => setTractsModalOpen(true)}
                      className="hardware-settings__tracts-icon"
                    >
                      <EditSquareIcon />
                    </div>
                  )}
                </div>
                <TractsOptions
                  systemId={systemId}
                  commutatorSettings={settingsCopy?.commutatorSettings || []}
                  permissions={permissions}
                  isDisabled={false}
                  onChange={onChange}
                />
              </>
            )}
          </div>
        </div>
      )}
      <TabNavButtons
        buttons={tabNavButtonsDefault(
          { callBack: handleOnCancel, isHidden: (!isDeviceOptionsChange && !isWasChange) || !permissions.edit },
          {
            callBack: () => handleOnSave(),
            disabled: !isWasChange && !isDeviceOptionsChange,
            isHidden: !permissions.edit,
          },
          {
            callBack: tryToSendHardwareData,
            isHidden: !permissions.edit,
            disabled: !isConnected,
            text: 'Отправить данные на оборудование',
          }
        )}
      />
    </>
  );
};

export default FiveThousandDevice;
