import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { IFlatPageFilters } from './types';
import { useApi } from '../../../hooks/useApi';
import { getRequest } from '../../../api';
import { IApiResponse } from '../../../typings/api';
import { ISystem } from '../../../typings/systems/system';
import { IFlatsPageBuilding, IFlatsPageFlat, IFlatsPageSection } from '../../../typings/flat';
import {
  companyUrl,
  getBuildingsUrl,
  getObjectSystemIdUrl,
  getObjectSystemsListUrl,
  getSectionsUrl,
} from '../../../constants/api';
import { IApartmentApiParams, IApartmentApiSettings } from '../../../api/apartments/types';
import { ISelectOption } from '../../ui/select/types';
import Select from '../../ui/select';
import InputSearch from '../../ui/inputSearch';
import { EFlatPaymentStatus } from '../../../pages/flats/types';
import { IOrganization } from '../../../typings/organization';

const FlatsPageFilters: FC<IFlatPageFilters> = ({
  setFlatsArr = () => {},
  isAdmin = false,
  requestData = () => {},
  eventsApiParams,
  apiSettings,
  setEventsApiParams = () => {},
}) => {
  const [searchValue, setSearchValue] = useState<string>('');

  const { data: companies, sendRequest: getCompanies, loading: companiesLoading } = useApi<IOrganization[]>(getRequest);
  const { data: objects, sendRequest: getObjects, loading: objectsLoading } = useApi<IApiResponse<ISystem>>(getRequest);
  const {
    data: buildings,
    sendRequest: getBuildings,
    loading: buildingsLoading,
  } = useApi<IFlatsPageBuilding[]>(getRequest);
  const {
    data: sections,
    sendRequest: getSections,
    loading: sectionsLoading,
  } = useApi<IFlatsPageSection[]>(getRequest);

  const [objectsList, setObjectsList] = useState<ISystem[]>([]);
  const [buildingsList, setBuildingsList] = useState<IFlatsPageBuilding[]>([]);
  const [sectionsList, setSectionsList] = useState<IFlatsPageSection[]>([]);

  useEffect(() => {
    setBuildingsList(buildings || []);
  }, [buildings]);

  useEffect(() => {
    setSectionsList(sections || []);
  }, [sections]);

  useEffect(() => {
    if (objects?.items?.length && eventsApiParams && apiSettings) {
      const id = objects.items[0].id || '';
      requestData(apiSettings, { ...eventsApiParams, objectId: id });
      getBuildings(getBuildingsUrl(id));
    } else if (objects?.items?.length === 0) {
      setFlatsArr([]);
      setBuildingsList([]);
      setSectionsList([]);
    }
    setObjectsList(objects?.items || []);
  }, [objects]);

  useEffect(() => {
    if (eventsApiParams?.companyId) {
      getObjects(getObjectSystemIdUrl(eventsApiParams.companyId), { params: { count: 0 } });
    } else if (!isAdmin) {
      getObjects(getObjectSystemsListUrl(), { params: { count: 0 } });
    }
  }, [eventsApiParams?.companyId]);

  useEffect(() => {
    if (isAdmin && companies?.length) {
      setEventsApiParams({
        ...eventsApiParams,
        companyId: companies[0].id,
      });
    }
  }, [companies]);

  useEffect(() => {
    if (isAdmin) {
      getCompanies(companyUrl());
    }
  }, [isAdmin]);

  const handleOnSearch = useCallback(
    async (value: string) => {
      setSearchValue(value);
      if (apiSettings && eventsApiParams) {
        const newApiSettings: IApartmentApiSettings<IFlatsPageFlat> = { ...apiSettings, search: value, page: 0 };
        requestData(newApiSettings);
      }
    },
    [apiSettings, eventsApiParams, requestData]
  );

  const handleOnChangeSelect = useCallback(
    (key: string) => (val: string | number) => {
      if (apiSettings && eventsApiParams) {
        const newApiSettings: IApartmentApiSettings<IFlatsPageFlat> = { ...apiSettings, page: 0 };
        const newEventsApiParams: IApartmentApiParams = { ...eventsApiParams, [key]: val };
        requestData(newApiSettings, newEventsApiParams);
      }
    },
    [apiSettings, eventsApiParams, requestData]
  );

  const handleOnChangeSelects = useCallback(
    (newEventsApiParams: IApartmentApiParams) => {
      if (apiSettings) {
        const newApiSettings: IApartmentApiSettings<IFlatsPageFlat> = { ...apiSettings, page: 0 };
        requestData(newApiSettings, newEventsApiParams);
      }
    },
    [apiSettings, requestData]
  );

  const handleOnChangeOrganization = useCallback(
    (val: string | number) => {
      if (eventsApiParams) {
        handleOnChangeSelects({
          ...eventsApiParams,
          companyId: val?.toString(),
          objectId: '',
          buildingId: '',
          sectionId: '',
        });
      }
      setFlatsArr([]);
      setBuildingsList([]);
      setSectionsList([]);
    },
    [eventsApiParams, handleOnChangeSelects, setFlatsArr]
  );

  const handleOnChangeObject = useCallback(
    (val: string | number) => {
      if (eventsApiParams) {
        handleOnChangeSelects({
          ...eventsApiParams,
          objectId: val?.toString(),
          buildingId: '',
          sectionId: '',
        });
      }
      getBuildings(getBuildingsUrl(val?.toString()));
      setSectionsList([]);
    },
    [eventsApiParams, getBuildings, handleOnChangeSelects]
  );

  const handleOnChangeBuilding = useCallback(
    (val: string | number) => {
      if (eventsApiParams) {
        handleOnChangeSelects({
          ...eventsApiParams,
          buildingId: val?.toString(),
          sectionId: '',
        });
      }
      if (eventsApiParams?.objectId && val) {
        getSections(getSectionsUrl(eventsApiParams.objectId, val?.toString()));
      } else {
        setSectionsList([]);
      }
    },
    [eventsApiParams, getSections, handleOnChangeSelects]
  );

  const organizationOptions = useMemo(
    () =>
      isAdmin
        ? companies?.map<ISelectOption>((object) => ({
            value: object.id || '',
            title: object.companyName || '',
          }))
        : [],
    [companies, isAdmin]
  );

  const objectOptions = useMemo(
    () =>
      objectsList.map<ISelectOption>((object) => ({
        value: object.id || '',
        title: object.objectName || '',
      })) || [],
    [objectsList]
  );

  const buildingOptions = useMemo(
    () =>
      buildingsList?.map<ISelectOption>((object) => ({
        value: object.id || '',
        title: object.name || '',
      })) || [],
    [buildingsList]
  );

  const sectionOptions = useMemo(
    () =>
      sectionsList?.map<ISelectOption>((object) => ({
        value: object.id || '',
        title: object.name || '',
      })) || [],
    [sectionsList]
  );

  const payStatusOptions: ISelectOption[] = useMemo(
    () => [
      {
        value: EFlatPaymentStatus.all,
        title: 'Все',
      },
      {
        value: EFlatPaymentStatus.enabled,
        title: 'Включена',
      },
      {
        value: EFlatPaymentStatus.disabled,
        title: 'Отключена',
      },
    ],
    []
  );

  return (
    <div className="flats-filters">
      <div className="flats-filters__item">
        {isAdmin && (
          <Select
            title="Организация"
            value={eventsApiParams?.companyId || ''}
            onChange={handleOnChangeOrganization}
            options={organizationOptions}
            loading={companiesLoading}
          />
        )}
        <Select
          title="Объект (краткое наименование)"
          value={eventsApiParams?.objectId || ''}
          onChange={handleOnChangeObject}
          options={objectOptions}
          loading={objectsLoading}
        />
        <Select
          isAllOption
          title="Адрес строения"
          value={eventsApiParams?.buildingId || ''}
          onChange={handleOnChangeBuilding}
          options={buildingOptions}
          loading={buildingsLoading}
        />
        {!isAdmin && (
          <Select
            isAllOption
            title="Подъезд"
            value={eventsApiParams?.sectionId || ''}
            onChange={handleOnChangeSelect('sectionId')}
            options={sectionOptions}
            loading={sectionsLoading}
          />
        )}
      </div>
      <div className="flats-filters__item">
        {isAdmin && (
          <Select
            isAllOption
            title="Подъезд"
            value={eventsApiParams?.sectionId || ''}
            onChange={handleOnChangeSelect('sectionId')}
            options={sectionOptions}
            loading={sectionsLoading}
          />
        )}
        <Select
          title="Оплата МП"
          value={eventsApiParams?.payStatus || ''}
          onChange={handleOnChangeSelect('payStatus')}
          options={payStatusOptions}
        />
        <InputSearch
          containerClassName="flats-filters__search"
          placeholder="Поиск по номеру квартиры"
          value={searchValue}
          onSearch={handleOnSearch}
        />
      </div>
    </div>
  );
};

export default FlatsPageFilters;
