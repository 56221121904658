import { Input as InputComponent, Slider } from 'antd';
import classNames from 'classnames';
import React, { FC, useCallback, useMemo } from 'react';
import InfoIcon from '../../../assets/svg/icons/info';
import { ONLY_NUMBERS } from '../../../constants/regex';
import Tooltip from '../tooltip';
import { EFieldTypes, IInput, InputStatus, InputType } from './types';

const { TextArea } = InputComponent;

const Input: FC<IInput> = (props) => {
  const {
    title,
    placeholder = '',
    value = '',
    status = InputStatus.normal,
    disabled = false,
    onChange = () => {},
    containerClassName = '',
    inputClassName = '',
    titleClassName = '',
    maxLength,
    minLength,
    errorText = '',
    errorTextClassName = '',
    inputType = InputType.text,
    description = '',
    fieldType = EFieldTypes.default,
    autoSize = { minRows: 1, maxRows: 1 },
    onFocus = () => {},
    onClick = () => {},
    onBlur = () => {},
    isRequired = false,
    isDisabledStyle = false,
    textInfo,
  } = props;

  const validateInput = useCallback(
    (inputString: string) => {
      if (!inputString) {
        return true;
      }

      switch (inputType) {
        case InputType.numbers:
          return ONLY_NUMBERS.test(inputString);
        case InputType.text:
        case InputType.email:
        default:
          return true;
      }
    },
    [inputType]
  );

  const handleOnChange = useCallback(
    (currentValue = '', event?: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      if (validateInput(currentValue)) {
        onChange(currentValue, event);
      }
    },
    [onChange, validateInput]
  );

  const inputProps = useMemo(
    () => ({
      className: classNames('input__input default-scrollbar-override', inputClassName, {
        'input__input_default-disabled': isDisabledStyle,
      }),
      placeholder,
      value,
      status,
      disabled,
      onChange: (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
        handleOnChange(event.target.value, event),
      onWheel: (e: any) => e.target.blur(),
      maxLength,
      minLength,
      onFocus,
      onClick,
      onBlur,
      type: inputType,
    }),
    [
      disabled,
      handleOnChange,
      inputClassName,
      inputType,
      isDisabledStyle,
      maxLength,
      minLength,
      onBlur,
      onClick,
      onFocus,
      placeholder,
      status,
      value,
    ]
  );

  const input = useMemo(() => {
    switch (fieldType) {
      case EFieldTypes.password: {
        return <InputComponent.Password {...inputProps} />;
      }
      case EFieldTypes.textArea: {
        return <TextArea autoSize={autoSize} {...inputProps} />;
      }
      default: {
        return <InputComponent {...inputProps} />;
      }
    }
  }, [autoSize, fieldType, inputProps]);

  return (
    <div className={classNames('input', containerClassName)}>
      {title && (
        <span className={classNames('input__title-container')}>
          <span className={classNames('input__title', titleClassName)}>
            {title}
            {isRequired && <span className="input__title-required">*</span>}
          </span>
          {textInfo && (
            <Tooltip title={textInfo} placement="bottom">
              <div className="input__title-info-icon">
                <InfoIcon />
              </div>
            </Tooltip>
          )}
        </span>
      )}
      {input}
      {description && <div className="input-description">{description}</div>}
      {errorText && <span className={classNames('input__error', errorTextClassName)}>{errorText}</span>}
    </div>
  );
};

export default Input;
