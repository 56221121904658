import React, { FC, useMemo } from 'react';
import { IOrganizationsFilters } from './types';
import Select from '../ui/select';
import InputSearch from '../ui/inputSearch';
import { ISelectOption } from '../ui/select/types';
import PeriodDatePicker from '../ui/periodDatePicker';
import { dateFormatNoTime } from '../../constants/date';

const PaymentsFilters: FC<IOrganizationsFilters> = (props) => {
  const {
    objects = [],
    eventsApiParams = {},
    apiSettings = null,
    isAdmin = false,
    companies = [],
    handleOnChangeDate = () => {},
    handleOnSearch = () => {},
    handleOnChangeSelect = () => () => {},
    statusMap = new Map(),
  } = props;

  const organizationOptions = useMemo(
    () =>
      isAdmin
        ? companies.map<ISelectOption>((object) => ({
            value: object.companyId || '',
            title: object.companyName || '',
          }))
        : [],
    [companies, isAdmin]
  );

  const statusOptions = useMemo(() => {
    const result: ISelectOption[] = [];
    statusMap.forEach((value, key) => {
      result.push({
        value: key || '',
        title: value || '',
      });
    });
    return result;
  }, [statusMap]);

  const objectOptions = useMemo(
    () =>
      objects.map((item) => ({
        value: item.objectId || '',
        title: item.objectName || '',
      })),
    [objects]
  );

  return (
    <div className="payments-filters">
      <div className="payments-filters__inputs">
        {isAdmin && (
          <Select
            isAllOption
            title="Организация"
            value={eventsApiParams.companyId || ''}
            onChange={handleOnChangeSelect('companyId')}
            options={organizationOptions}
          />
        )}
        <Select
          isAllOption
          title="Объект"
          value={eventsApiParams.objectId || ''}
          onChange={handleOnChangeSelect('objectId')}
          options={objectOptions}
        />
        <Select
          isAllOption
          title="Статус"
          value={eventsApiParams.status || ''}
          onChange={handleOnChangeSelect('status')}
          options={statusOptions}
        />
        <PeriodDatePicker
          title="Период"
          placeholder="Выберите период"
          firstDate={eventsApiParams.startDate}
          secondDate={eventsApiParams.endDate}
          onChange={handleOnChangeDate}
          showTime={false}
          format={dateFormatNoTime}
        />
        {!isAdmin && (
          <InputSearch
            placeholder="Поиск по ФИО, телефон, адресу"
            value={apiSettings?.search || ''}
            onSearch={handleOnSearch}
            containerClassName="payments-filters__left-m"
          />
        )}
      </div>
      {isAdmin && (
        <div className="payments-filters__inputs">
          <InputSearch
            placeholder="Поиск по ФИО, телефон, адресу"
            value={apiSettings?.search || ''}
            onSearch={handleOnSearch}
          />
        </div>
      )}
    </div>
  );
};

export default PaymentsFilters;
