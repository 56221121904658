import { EFAQLvl, IFAQQuestion } from '../../../typings/faq';
import { IProfilePermission } from '../../../typings/profile';
import { ISystem } from '../../../typings/systems/system';

export interface IFaqTable {
  blockDragAndDrop?: boolean;
  permissions?: IProfilePermission;
  isChangeMode?: boolean;
  setIsChangeMode?: (val: boolean) => void;
  currPage?: number;
  isSearch?: boolean;
  isLastList?: boolean;
  activeSection?: string;
  requestQuestions?: () => void;
  systems?: ISystem[];
  questions?: IFAQQuestion[];
  isAdmin?: boolean;
  handleOnChangeTablePage?: (page: number) => void;
  activeTab?: string;
}

export const FAQLevelsMap = new Map([
  [EFAQLvl.company, 'Организация'],
  [EFAQLvl.platform, 'Платформа'],
]);
