import classNames from 'classnames';
import React, { FC } from 'react';
import { Tooltip as TooltipComponent } from 'antd';
import { ITooltip } from './types';

const Tooltip: FC<ITooltip> = (props) => {
  const {
    children,
    title = '',
    placement = 'top',
    trigger = 'hover',
    className = '',
    defaultVisible = false,
    styles = {},
    stylesInner = {},
  } = props;

  return (
    <TooltipComponent
      className="tooltip__wrapper"
      overlayClassName={classNames('tooltip', className)}
      title={title}
      placement={placement}
      trigger={trigger}
      defaultVisible={defaultVisible}
      align={{ offset: [0, 0] }}
      overlayStyle={{ ...styles }}
      overlayInnerStyle={{ ...stylesInner }}
    >
      {children}
    </TooltipComponent>
  );
};

export default Tooltip;
