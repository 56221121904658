import { ETariffVersions } from '../management/tariff';

export enum ESystemBaseDeviceSeries {
  D7000 = 'b923fe31-a23b-4731-8aaf-2e9875eb7000',
  D5000 = 'b923fe31-a23b-4731-8aaf-2e9875eb5000',
}

export enum EContactsStatus {
  configured = 'Сonfigured',
  notConfigured = 'NotConfigured',
  partiallyСonfigured = 'PartiallyСonfigured',
}

export interface IConnectionSettings {
  host: string;
  port: string;
  login: string;
  password: string;
  adapterName: string;
}

export interface ISystemBase {
  objectName: string;
  shortName: string;
  address: string;
  companyId: string;
  repeaterNumbers: number;
  withSecurityDesk: boolean;
  isDirectCall: boolean;
  isNotificationCallBack: boolean;
  isWithoutKeys: boolean;
  isContactsConfigured: EContactsStatus;
  isRedirect: boolean;
  isVideo: boolean;
  isCallback: boolean;
  isBlocked: boolean;
  isDefaultKeyNumber: boolean;
  keyNumbers: number | null;
  deviceSeriesId?: ESystemBaseDeviceSeries;
  deviceUpdateTime?: string;
  isUpdateAllowForAllDevices?: boolean;
  fiasId: string;
  actAdm: string;
  connectorSetting: IConnectionSettings | null;
  deviceSeriesName: string;
  nightModeStart: string;
  nightModeEnd: string;
}

export interface IObjectTariffInfo {
  isSetAdditionalCompanyPrice: true;
  isSetMaintenance: false;
  isSetObjectTariffDiscount: true;
  isSetTariffAdditionalCompanyPrice: ETariffVersions[];
}
