import React, { FC } from 'react';
import Button from '../button';
import Modal from '../modal';
import { IConfirmModal } from './types';

const UniversalModal: FC<IConfirmModal> = ({
  onClose = () => {},
  data: { isOpen = false, description = '', buttons = [], text = '' },
}) => (
  <Modal
    isOpen={isOpen}
    title={description}
    onCancel={onClose}
    width={400}
    centered
    wrapClassName="confirm-modal"
    footer={
      <div className="ant-modal-footer__buttons">
        {buttons.map(({ type, onClick, label }, index) => (
          <Button key={`confirm-button-${index}`} type={type} className="access-zone-modal__button" onClick={onClick}>
            {label}
          </Button>
        ))}
      </div>
    }
  >
    {text ? <div className="confirm-modal__text">{text}</div> : ''}
  </Modal>
);

export default UniversalModal;
