import React, { FC, useCallback, useMemo, useRef, useState } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import Editor from '@monaco-editor/react';
import moment from 'moment';
import classNames from 'classnames';
import { useApi } from '../../../../hooks/useApi';
import { defaultConfirm } from '../../../ui/universalModal/config';
import { IConfirmData } from '../../../ui/universalModal/types';
import Input from '../../../ui/input';
import { dateDefaultFormat } from '../../../../constants/date';
import Button from '../../../ui/button';
import { ButtonType } from '../../../ui/button/types';
import { IMailingEditorTabComponent } from '../types';
import UniversalModal from '../../../ui/universalModal';
import { mailingTestUrl } from '../../../../constants/api';
import { postRequest } from '../../../../api';
import Message from '../../../message';
import InformationBlock from '../../../ui/informationBlock';
import { EMailingTypes, mailingStatusNamesMap } from '../../../../typings/mailing';
import { EFieldTypes } from '../../../ui/input/types';

const MailingEditorTab: FC<IMailingEditorTabComponent> = ({
  permissions,
  mailingData,
  onChangeMailingData = () => {},
  haveEmptyFields,
  onlyView,
  mailingType,
  smsCount = 0,
}) => {
  const { sendRequest: sendTestEmail, loading: sendTestEmailLoading } = useApi(postRequest);
  const [confirmData, setConfirmData] = useState<IConfirmData>(defaultConfirm);

  const closeConfirm = useCallback(() => setConfirmData(defaultConfirm), []);

  const [testEmail, onChangeTestEmail] = useState('');

  const iframeRef = useRef<any>();

  const renderHtml = useCallback(
    (body = mailingData?.body) => {
      const { contentWindow } = iframeRef.current;
      if (contentWindow) {
        contentWindow.document.open();
        contentWindow.document.write(body);
        contentWindow.document.close();
      }
    },
    [mailingData?.body]
  );

  const onChangeEditor = useCallback(
    (val = '') => {
      onChangeMailingData('body', val);
      renderHtml(val);
    },
    [onChangeMailingData, renderHtml]
  );

  const onSendTestEmail = useCallback(async () => {
    const resError = await sendTestEmail(mailingTestUrl(), {
      title: mailingData?.title,
      body: mailingData?.body,
      type: 0,
      recipientList: [{ data: testEmail }],
    });
    if (!resError?.response?.data) {
      Message.success({
        content: `Тестовое письмо отправлено на ${testEmail}`,
      });
    } else {
      Message.error({
        content: resError?.response.data.message || 'Не удалось отправить тестовое письмо',
      });
    }
  }, [mailingData, sendTestEmail, testEmail]);

  return (
    <div className="email-editor">
      <UniversalModal data={confirmData} onClose={closeConfirm} />

      <div className="email-editor__title">Основная информация</div>
      <InformationBlock
        data={[
          [
            {
              items: [
                {
                  name: 'Статус',
                  value: (
                    <div className={`mailing__status mailing__status_${mailingData?.status}`}>
                      {mailingData?.status && mailingStatusNamesMap.get(mailingData.status)}
                    </div>
                  ),
                },
                {
                  name: 'Время отправки',
                  value: mailingData?.sendDate ? moment(mailingData?.sendDate).format(dateDefaultFormat) : '-',
                },
              ],
            },
          ],
        ]}
      />

      {mailingType === EMailingTypes.email ? (
        <>
          <div className="email-editor__mail-info">
            <Input
              title="Тема письма"
              isRequired
              value={mailingData?.title}
              onChange={(val) => onChangeMailingData('title', val)}
              placeholder="Тема письма"
              maxLength={200}
              disabled={!permissions?.edit || onlyView}
              isDisabledStyle
            />
          </div>
          <div className="email-editor__text-info">
            Для создания и проверки HTML-кода воспользуйтесь конструктором шаблонов электронных писем, например –{' '}
            <a href="https://stripo.email/ru">https://stripo.email/ru</a> <br /> Для предварительного просмотра письма
            скопируйте HTML-код из конструктора и вставьте в соответствующее поле.
          </div>
          <div className="email-editor__container">
            <div
              className={classNames('email-editor__editor', {
                'email-editor__editor_disabled': !permissions?.edit || onlyView,
              })}
            >
              <div className="email-editor__container-title email-editor__text-info">HTML-код</div>
              <Editor
                className="default-scrollbar-override"
                onMount={(val) => {
                  setTimeout(() => renderHtml(val.getValue()), 100);
                }}
                height="500px"
                defaultLanguage="html"
                value={mailingData?.body}
                onChange={onChangeEditor}
              />
            </div>
            <div className="email-editor__frame">
              <div className="email-editor__container-title email-editor__text-info">Просмотр</div>
              <iframe className="default-scrollbar-override" title="1" ref={iframeRef} />
            </div>
          </div>
          <div className="email-editor__text-info">
            В случае ошибок в HTML-коде, изображение может исказиться в почтовом клиенте. Перед рассылкой проверьте еще
            раз код в конструктореи отправьте тестовое письмо.
          </div>
          <div className="email-editor__test-send">
            <Input
              title="E-mail для тестовой отправки"
              value={testEmail}
              onChange={onChangeTestEmail}
              placeholder="E-mail для тестовой отправки"
              maxLength={40}
              disabled={!permissions?.edit || onlyView}
              isDisabledStyle
            />
            <Button
              onClick={onSendTestEmail}
              disabled={!testEmail || sendTestEmailLoading || haveEmptyFields || !permissions?.edit}
              type={ButtonType.outline}
            >
              Отправить
            </Button>
          </div>
        </>
      ) : mailingType === EMailingTypes.sms ? (
        <>
          <div className="email-editor__title">Текст SMS сообщения</div>
          <div className="email-editor__sms-counter">
            Символов {mailingData?.body.length || 0} / {smsCount} SMS
            <br />
            Правила расчета количества SMS: 1 SMS кириллицей = 70 знаков / 1 SMS латиницей = 162 знака
          </div>
          <Input
            containerClassName="email-editor__textarea"
            value={mailingData?.body}
            onChange={(val) => onChangeMailingData('body', val)}
            autoSize={{ minRows: 10, maxRows: 10 }}
            fieldType={EFieldTypes.textArea}
            disabled={!permissions?.edit || onlyView}
            isDisabledStyle
          />
        </>
      ) : (
        <>
          <div className="email-editor__title">Заголовок и текст push-уведомления</div>
          <Input
            title="Заголовок"
            isRequired
            value={mailingData?.title}
            onChange={(val) => onChangeMailingData('title', val)}
            placeholder="Заголовок"
            maxLength={40}
            containerClassName="email-editor__input"
            disabled={!permissions?.edit || onlyView}
            isDisabledStyle
          />
          <Input
            containerClassName="email-editor__textarea"
            value={mailingData?.body}
            onChange={(val) => onChangeMailingData('body', val)}
            autoSize={{ minRows: 10, maxRows: 10 }}
            fieldType={EFieldTypes.textArea}
            disabled={!permissions?.edit || onlyView}
            isDisabledStyle
            maxLength={150}
          />
          <div className="email-editor__sms-counter">{mailingData?.body.length || 0} из 150 символов</div>
        </>
      )}
    </div>
  );
};

export default MailingEditorTab;
