import React, { FC, useCallback, useEffect, useState } from 'react';
import { ITractsModal } from './types';
import Modal from '../../../../../../ui/modal';
import Button from '../../../../../../ui/button';
import { ButtonType } from '../../../../../../ui/button/types';
import TractsOptions from '../tractsOptions';
import { ICommutatorSetting, IHardwareSettings } from '../../../../../../../typings/systems/hardwareSettings';

const TractsModal: FC<ITractsModal> = (props) => {
  const { isOpen = false, onCancel = () => {}, settings, systemId, permissions, changeSettings = () => {} } = props;

  const [localSettings, setLocalSettings] = useState<ICommutatorSetting[]>([]);

  useEffect(() => {
    if (isOpen) setLocalSettings(JSON.parse(JSON.stringify(settings?.commutatorSettings || [])));
  }, [isOpen]);

  const onSave = useCallback(() => {
    changeSettings({ ...settings, commutatorSettings: localSettings } as IHardwareSettings);
    onCancel();
  }, [changeSettings, localSettings, onCancel, settings]);

  const onChange = useCallback(
    (index: number, val: string) => {
      if (localSettings.length) {
        const arrayIndex = localSettings.findIndex((item) => item.sinkIndex === index);
        if (arrayIndex !== -1) {
          if (val) {
            localSettings[arrayIndex].selectedDeviceId = val;
            setLocalSettings([...localSettings]);
          } else {
            setLocalSettings(localSettings.filter((item, i) => i !== arrayIndex));
          }
          return;
        }
      }
      setLocalSettings([
        ...localSettings,
        {
          sinkIndex: index,
          selectedDeviceId: val,
        },
      ]);
    },
    [localSettings]
  );

  return (
    <Modal
      isOpen={isOpen}
      title="Настроить тракты"
      onCancel={onCancel}
      width={900}
      centered
      wrapClassName="tracts-modal__wrapper"
      footer={
        <div className="ant-modal-footer__buttons">
          <Button onClick={onSave}>Сохранить</Button>
          <Button type={ButtonType.secondary} onClick={onCancel}>
            Отмена
          </Button>
        </div>
      }
    >
      <div className="tracts-modal">
        <div className="tracts-modal__title">
          <div className="tracts-modal__title-item">Текущие настройки</div>
          <div className="tracts-modal__title-item">Новые настройки</div>
        </div>
        <div className="tracts-modal__content default-scrollbar-override">
          <TractsOptions
            commutatorSettings={settings?.commutatorSettings || []}
            systemId={systemId}
            permissions={permissions}
            isDisabled
            isDisabledStyle
            inLine
          />
          <TractsOptions
            isDisabled={false}
            commutatorSettings={localSettings}
            systemId={systemId}
            permissions={permissions}
            onChange={onChange}
            inLine
          />
        </div>
      </div>
    </Modal>
  );
};

export default TractsModal;
