import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import classNames from 'classnames';
import { ICategoryTable } from './types';
import ErrorPlaceholder from '../../ui/errorPlaceholder';
import Scrollbar from '../../ui/scrollbar';
import PlusIcon from '../../../assets/svg/icons/plus';
import { useApi } from '../../../hooks/useApi';
import { postRequest } from '../../../api';
import { feedbackCategorySubjectSortUrl } from '../../../constants/api';
import Message from '../../message';
import SearchFailIcon from '../../../assets/svg/icons/searchFail';
import EmptyFAQList from '../../../assets/svg/icons/emptyFAQList';
import SubjectModal from './subjectModal';
import CategoryTableSubject from './categoryTableSubject';
import { IFeedbackSubject } from '../../../typings/feedback';

const CategoryTable: FC<ICategoryTable> = ({
  blockDragAndDrop = false,
  permissions = {},
  activeCategory = '',
  requestSubjects = () => {},
  subjects = [],
  isAdmin = false,
  isSearch = false,
}) => {
  const [currentSubjects, setCurrentSubjects] = useState<IFeedbackSubject[]>([]);

  const [modalOpen, setModalOpen] = useState(false);
  const [editItem, setEditItem] = useState<IFeedbackSubject | null>(null);

  const { sendRequest: sortSubjects } = useApi(postRequest);

  useEffect(() => {
    setCurrentSubjects(subjects);
  }, [subjects]);

  const onEdit = useCallback((data: IFeedbackSubject) => {
    setModalOpen(true);
    setEditItem(data);
  }, []);

  const reorder = useCallback((list: IFeedbackSubject[], startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  }, []);

  const onDragEnd = useCallback(
    (result: any) => {
      if (!result.destination) {
        return;
      }
      const newSubjects = reorder(currentSubjects, result.source.index, result.destination.index);
      setCurrentSubjects(newSubjects);
      sortSubjects(
        feedbackCategorySubjectSortUrl(),
        newSubjects.map((item, index) => ({ id: item.id, order: index }))
      );
    },
    [currentSubjects, reorder, sortSubjects]
  );

  const onCancel = useCallback(() => {
    setModalOpen(false);
    setEditItem(null);
  }, []);

  const onOk = useCallback(() => {
    Message.success({
      content: editItem ? 'Тема отредактирована' : 'Новая тема создана',
    });
    onCancel();
    requestSubjects();
  }, [editItem, onCancel, requestSubjects]);

  const getEmptyMessage = useCallback(
    () =>
      !isSearch ? (
        <ErrorPlaceholder
          text={
            <>
              <span>Пока нет тем в категории.</span>
              <span>Нажмите на «+» в таблице, чтобы добавить.</span>
            </>
          }
          icon={<EmptyFAQList />}
        />
      ) : (
        <ErrorPlaceholder text="По вашему запросу ничего не найдено" icon={<SearchFailIcon />} />
      ),
    [isSearch]
  );

  const isSimpleMode = useMemo(() => isAdmin, [isAdmin]);

  return (
    <div className="category-table">
      <SubjectModal
        categoryId={activeCategory}
        isOpen={modalOpen}
        editItem={editItem}
        onCancel={onCancel}
        onOk={onOk}
      />

      <Scrollbar>
        <div className={classNames('category-table__table default-scrollbar-override')}>
          <div className="category-table__thead">
            <div className="category-table__tr">
              <div
                className={classNames('category-table__th', {
                  'category-table__th_hide': !permissions.edit || isSimpleMode,
                })}
              />
              <div className="category-table__th">Темы</div>
              <div className="category-table__th">
                Примерное время <br /> исполнения
              </div>
              <div
                className={classNames('category-table__th', {
                  'category-table__th_hide': !permissions?.create || isSimpleMode,
                })}
              >
                <div className="category-table__plus" role="presentation" onClick={() => setModalOpen(true)}>
                  <PlusIcon />
                </div>
              </div>
            </div>
          </div>
          <div className="category-table__separator" />
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable1">
              {(provided) => (
                <div
                  className="category-table__tbody default-scrollbar-override"
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                >
                  {currentSubjects?.length && activeCategory
                    ? currentSubjects.map((item, index) => (
                        <CategoryTableSubject
                          setEditItem={onEdit}
                          blockDragAndDrop={blockDragAndDrop}
                          isLastElement={index === currentSubjects.length - 1}
                          showIcon={currentSubjects.length !== 1}
                          isSimpleMode={isSimpleMode}
                          isAdmin={isAdmin}
                          key={`${item.id}-${index}`}
                          subject={item}
                          index={index}
                          requestSubjects={requestSubjects}
                          activeCategory={activeCategory}
                          permissions={permissions}
                        />
                      ))
                    : getEmptyMessage()}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
      </Scrollbar>
    </div>
  );
};

export default CategoryTable;
