import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Moment } from 'moment';
import { IFlatTabComponent } from '../types';
import { useApi } from '../../../hooks/useApi';
import { postRequest } from '../../../api';
import { subscriptionListPaymentUrl } from '../../../constants/api';
import { IApiResponse, IApiSortField } from '../../../typings/api';
import { ISubscription, subscriptionsStatusNamesMap } from '../../../typings/subscriptions';
import { IPaymentApiParams, IPaymentsApiSettings } from '../../../api/payments/types';
import { IPayment } from '../../../typings/payments';
import { getPaymentApiSettings } from '../../../api/payments/config';
import SubscriptionsTable from '../../subscriptionsTable';
import { dateFormatNoTime } from '../../../constants/date';
import Select from '../../ui/select';
import PeriodDatePicker from '../../ui/periodDatePicker';
import InputSearch from '../../ui/inputSearch';
import { ISelectOption } from '../../ui/select/types';
import { useAppSelector } from '../../../hooks/hooks';
import { getProfilePermission } from '../../../store/selectors/profile';
import { ESidebarItemIds } from '../../../typings/sidebar';
import { paths } from '../../../constants/paths';

const FlatSubscriptionsTab: FC<IFlatTabComponent> = (props) => {
  const { flatId = '', tabId, activeTabKey } = props;

  const paymentsPermissions = useAppSelector(getProfilePermission(ESidebarItemIds.payments));

  const {
    data: subscriptions,
    sendRequest: getSubscriptions,
    loading: subscriptionsLoading,
  } = useApi<IApiResponse<ISubscription>>(postRequest);

  const [apiSettings, setApiSettings] = useState<IPaymentsApiSettings<IPayment>>(
    getPaymentApiSettings('subscriptionCreateDate')
  );
  const [eventsApiParams, setEventsApiParams] = useState<IPaymentApiParams>({ flatId });

  const [searchValue, setSearchValue] = useState<string>('');

  const requestData = useCallback(
    async (
      reqSettings: IPaymentsApiSettings<IPayment> = apiSettings,
      newApiParams: IPaymentApiParams = eventsApiParams
    ) => {
      setApiSettings(reqSettings);
      setEventsApiParams(newApiParams);
      await getSubscriptions(subscriptionListPaymentUrl(), reqSettings, { params: newApiParams });
    },
    [apiSettings, eventsApiParams, getSubscriptions]
  );

  useEffect(() => {
    if (tabId === activeTabKey) {
      requestData();
    }
  }, [tabId, activeTabKey]);

  const handleOnSearch = useCallback(
    async (value: string) => {
      setSearchValue(value);
      const newApiSettings: IPaymentsApiSettings<IPayment> = { ...apiSettings, page: 0, search: value };
      await requestData(newApiSettings);
    },
    [apiSettings, requestData]
  );

  const handleOnChangeDate = useCallback(
    async (firstDate?: Moment, secondDate?: Moment) => {
      const newApiSettings: IPaymentsApiSettings<IPayment> = { ...apiSettings, page: 0 };
      const newEventsParams = {
        ...eventsApiParams,
        startDate: firstDate?.startOf('day').toISOString() || '',
        endDate: secondDate?.endOf('day').toISOString() || '',
      };
      await requestData(newApiSettings, newEventsParams);
    },
    [apiSettings, eventsApiParams, requestData]
  );

  const handleOnChangeTablePage = useCallback(
    async (page: number) => {
      const newApiSettings: IPaymentsApiSettings<IPayment> = { ...apiSettings, page: page - 1 };
      await requestData(newApiSettings);
    },
    [apiSettings, requestData]
  );

  const handleOnSort = useCallback(
    async (sortResults: IApiSortField<IPayment>[]) => {
      const newApiSettings: IPaymentsApiSettings<IPayment> = { ...apiSettings, sortFields: sortResults };
      await requestData(newApiSettings);
    },
    [apiSettings, requestData]
  );

  const handleOnChangeSelect = useCallback(
    (key: string) => (val: string | number) => {
      const newApiSettings: IPaymentsApiSettings<IPayment> = { ...apiSettings, page: 0 };
      const newEventsParams = {
        ...eventsApiParams,
        [key]: val,
      };
      requestData(newApiSettings, newEventsParams);
    },
    [apiSettings, eventsApiParams, requestData]
  );

  const statusOptions = useMemo(() => {
    const result: ISelectOption[] = [];
    subscriptionsStatusNamesMap.forEach((value, key) => {
      result.push({
        value: key || '',
        title: value || '',
      });
    });
    return result;
  }, []);

  return (
    <div className="subscriptions">
      <div className="payments-filters">
        <div className="payments-filters__inputs">
          <Select
            isAllOption
            title="Статус"
            value={eventsApiParams.status || ''}
            onChange={handleOnChangeSelect('status')}
            options={statusOptions}
          />
          <PeriodDatePicker
            title="Период"
            placeholder="Выберите период"
            firstDate={eventsApiParams.startDate}
            secondDate={eventsApiParams.endDate}
            onChange={handleOnChangeDate}
            showTime={false}
            format={dateFormatNoTime}
          />
          <div className="payments-filters__left-m">
            <InputSearch
              placeholder="Поиск по ФИО, телефону, тарифу"
              value={apiSettings?.search || ''}
              onSearch={handleOnSearch}
              containerClassName="flat-item__long-search"
            />
          </div>
        </div>
      </div>
      <SubscriptionsTable
        path={paths.flats}
        pageId={flatId}
        tabId={tabId}
        redirectAbility={paymentsPermissions?.view}
        subscriptions={subscriptions?.items || []}
        loading={subscriptionsLoading}
        pageSize={subscriptions?.pageSize}
        total={subscriptions?.totalCount}
        currentPage={subscriptions?.page}
        onChangePage={handleOnChangeTablePage}
        onSort={handleOnSort}
        sortOrders={apiSettings.sortFields}
        isSearch={!!searchValue}
        showSetting={false}
        availableCols={['fio', 'phone', 'amount', 'tariff', 'subscriptionCreateDate', 'subscriptionStatus']}
      />
    </div>
  );
};

export default FlatSubscriptionsTab;
