import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { getSystem } from '../../api/systems';
import CommonHead from '../../components/commonHead';
import SystemsTable from '../../components/systemsTable';
import Button from '../../components/ui/button';
import InputSearch from '../../components/ui/inputSearch';
import { paths } from '../../constants/paths';
import { useAppDispatch, useAppSelector } from '../../hooks/hooks';
import { useApi } from '../../hooks/useApi';
import { checkIsAdmin, getProfilePermission } from '../../store/selectors/profile';
import { setHeaderTitle } from '../../store/slices/header';
import { companyUrl, getObjectSystemIdUrl, getObjectSystemsListUrl } from '../../constants/api';
import { IApiQueryParams, IApiResponse } from '../../typings/api';
import { ESidebarItemIds } from '../../typings/sidebar';
import { ISystem } from '../../typings/systems/system';
import { ESystemTabsIds } from './item/types';
import Select from '../../components/ui/select';
import { getRequest } from '../../api';
import { ISelectOption } from '../../components/ui/select/types';
import { EPageQueryParams } from '../../typings/searchParams';

const Systems: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { data, sendRequest, loading } = useApi<IApiResponse<ISystem>>(getSystem);
  const [apiSettings, setApiSettings] = useState<IApiQueryParams<ISystem>>({
    page: 0,
    count: 10,
    search: '',
  });
  const permissionObject = useAppSelector(getProfilePermission(ESidebarItemIds.objects));

  const { data: companies, sendRequest: getCompanies, loading: companiesLoading } = useApi<ISystem[]>(getRequest);

  const isAdmin = useAppSelector(checkIsAdmin);

  const [companyId, setCompanyId] = useState<string | number>('');

  useEffect(() => {
    dispatch(setHeaderTitle('Объекты'));
  }, [dispatch]);

  useEffect(() => {
    if (isAdmin) {
      getCompanies(companyUrl(), {
        params: {
          page: 0,
          count: 0,
          search: '',
        },
      });
    }
  }, [isAdmin]);

  const getObjects = useCallback(
    async (params = apiSettings, id = companyId) => {
      if (id) {
        await sendRequest(getObjectSystemIdUrl(id.toString()), { params });
      } else {
        await sendRequest(getObjectSystemsListUrl(), { params });
      }
    },
    [apiSettings, companyId, sendRequest]
  );

  useEffect(() => {
    getObjects();
  }, []);

  const handleOnClickButtonNew = useCallback(() => navigate(paths.systemsNew), [navigate]);

  const handleOnClickRow = useCallback(
    (systemId: string) =>
      navigate({
        pathname: `${paths.systems}/${systemId}`,
        search: `?${createSearchParams({ [`${EPageQueryParams.tabId}`]: ESystemTabsIds.basic })}`,
      }),
    [navigate]
  );

  const handleOnChangeTablePage = useCallback(
    async (page: number) => {
      const newApiSettings: IApiQueryParams<ISystem> = { ...apiSettings, page: page - 1 };
      await getObjects(newApiSettings);
      setApiSettings(newApiSettings);
    },
    [apiSettings, getObjects]
  );

  const handleOnSearch = useCallback(
    async (value: string) => {
      const newApiSettings: IApiQueryParams<ISystem> = { ...apiSettings, page: 0, search: value };
      await getObjects(newApiSettings);
      setApiSettings(newApiSettings);
    },
    [apiSettings, getObjects]
  );

  const handleOnChangeCompanyId = useCallback(
    async (id: string | number) => {
      setCompanyId(id);
      const newApiSettings: IApiQueryParams<ISystem> = { ...apiSettings, page: 0 };
      getObjects(newApiSettings, id);
      setApiSettings(newApiSettings);
    },
    [apiSettings, getObjects]
  );

  const organizationOptions = useMemo(
    () =>
      isAdmin && companies
        ? companies.map<ISelectOption>((object) => ({
            value: object.id || '',
            title: object.companyName || '',
          }))
        : [],
    [companies, isAdmin]
  );

  return (
    <>
      <CommonHead seo={{ title: 'Объекты' }} />

      <div className="systems">
        <div className="systems__header">
          <div className="systems__cell">
            <InputSearch value={apiSettings.search} onSearch={handleOnSearch} placeholder="Название объекта, адрес" />
          </div>
          {permissionObject?.create && (
            <div className="systems__cell">
              <Button className="systems__button" onClick={handleOnClickButtonNew}>
                Добавить объект
              </Button>
            </div>
          )}
          {isAdmin && (
            <div className="systems__select">
              <Select
                isAllOption
                title="Организация"
                value={companyId}
                onChange={handleOnChangeCompanyId}
                options={organizationOptions}
              />
            </div>
          )}
        </div>
        <div className="systems__content">
          <SystemsTable
            systems={data?.items?.reverse() || []}
            onClickRow={handleOnClickRow}
            onEditRow={handleOnClickRow}
            permissions={permissionObject}
            loading={loading}
            pagination={{
              pageSize: data?.pageSize || 0,
              currentPage: data?.page || 0,
              total: data?.totalCount || 0,
              onChangePage: handleOnChangeTablePage,
            }}
          />
        </div>
      </div>
    </>
  );
};

export default Systems;
