import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import CommonHead from '../../../components/commonHead';
import { useAppDispatch, useAppSelector } from '../../../hooks/hooks';
import { setHeaderTitle } from '../../../store/slices/header';
import { useApi } from '../../../hooks/useApi';
import { companyUrl, organizationUrl } from '../../../constants/api';
import { checkIsAdmin, getProfilePermission } from '../../../store/selectors/profile';
import OrganizationMainInfo from '../../../components/organization/mainInfo';
import { getRequest } from '../../../api';
import { IOrganization } from '../../../typings/organization';
import Loader from '../../../components/ui/loader';
import { ELoaderColor } from '../../../components/ui/loader/types';
import Select from '../../../components/ui/select';
import { ISelectOption } from '../../../components/ui/select/types';
import { getPaymentsData } from '../../../api/payments';
import ContactsSection from '../../../components/organization/contactsSection';
import { ESidebarItemIds } from '../../../typings/sidebar';

const OrganizationInfo: FC = () => {
  const dispatch = useAppDispatch();
  const isAdmin = useAppSelector(checkIsAdmin);

  const {
    data: companies,
    sendRequest: getCompanies,
    loading: companiesLoading,
  } = useApi<IOrganization[]>(getPaymentsData);
  const { data: company, sendRequest: getCompany, loading: companyLoading } = useApi<IOrganization>(getRequest);

  const [searchParams, setSearchParams] = useSearchParams();

  const permission = useAppSelector(getProfilePermission(ESidebarItemIds.organizationInfo));

  const requestCompany = useCallback(
    async (companyId = company?.id || '') => {
      await getCompany(organizationUrl(), { params: { companyId } });
      searchParams.set('companyId', companyId);
      setSearchParams(searchParams);
    },
    [company?.id, getCompany, searchParams, setSearchParams]
  );

  useEffect(() => {
    dispatch(setHeaderTitle('Информация об организации'));
  }, []);

  useEffect(() => {
    if (isAdmin) {
      getCompanies(companyUrl(), {
        params: {
          page: 0,
          count: 0,
          search: '',
        },
      });
    }
    requestCompany();
  }, [isAdmin]);

  useEffect(() => {
    if (isAdmin) {
      const companyId = searchParams.get('companyId');
      if (companyId) {
        requestCompany(companyId);
      } else if (companies && companies.length && !company) {
        requestCompany(companies[0].id);
      }
    }
  }, [companies]);

  const onChangeCompany = useCallback((e: string | number) => requestCompany(e.toString()), [requestCompany]);

  const organizationOptions = useMemo(
    () =>
      isAdmin && companies
        ? companies.map<ISelectOption>((object) => ({
            value: object.id || '',
            title: object.companyName || '',
          }))
        : [],
    [companies, isAdmin]
  );

  return (
    <>
      <CommonHead seo={{ title: 'Информация об организации' }} />
      <div className="organization-info">
        {companyLoading || companiesLoading ? (
          <Loader color={ELoaderColor.blue} />
        ) : (
          <>
            {isAdmin && (
              <Select
                containerClassName="organization-info__select-org"
                title="Организация"
                value={company?.id || ''}
                onChange={onChangeCompany}
                options={organizationOptions}
              />
            )}
            <OrganizationMainInfo requestCompany={requestCompany} permissions={permission} company={company} />
            <ContactsSection permissions={permission} company={company} />
          </>
        )}
      </div>
    </>
  );
};

export default OrganizationInfo;
