export enum ESystemTabsIds {
  basic = 'basic',
  plan = 'plan',
  distributionFlats = 'distributionFlats',
  securityDeck = 'securityDeck',
  callCodes = 'callCodes',
  objectKeys = 'objectKeys',
  accessGroups = 'accessGroups',
  BVConfiguration = 'BVConfiguration',
  configurationFiles = 'configurationFiles',
  accessMatrix = 'accessMatrix',
}
