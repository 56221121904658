import classNames from 'classnames';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import saveAs from 'file-saver';
import {
  IHardwareRevision,
  IHardwareType,
  IHardwareVersion,
} from '../../../../../../../typings/systems/hardwareSettings';
import Checkbox from '../../../../../../ui/checkbox';
import Input from '../../../../../../ui/input';
import { InputStatus, InputType } from '../../../../../../ui/input/types';
import NotificationModal from '../../../../../../ui/notificationModal';
import RadioGroup from '../../../../../../ui/radioGroup';
import { ERadioGroupDirection, IRadioGroupOption } from '../../../../../../ui/radioGroup/types';
import Select from '../../../../../../ui/select';
import { ISelectOption } from '../../../../../../ui/select/types';
import { ISevenThousandOptions } from './types';
import { emptyFieldErrorText } from '../../advancedOptions/types';
import CloseCircleIcon from '../../../../../../../assets/svg/icons/closeCIrcle';
import { useApi } from '../../../../../../../hooks/useApi';
import { getFileRequest, getRequest, postRequest } from '../../../../../../../api';
import {
  createCertificateUrl,
  getCertificateExportUrl,
  getCertificateStatusUrl,
  getDeviceFileUrl,
  getHardwareTypesUrl,
  getRevisionVersionsUrl,
  getRevisionsListUrl,
} from '../../../../../../../constants/api';
import CheckedIcon from '../../../../../../../assets/svg/icons/checkedIcon';
import DownloadIcon from '../../../../../../../assets/svg/icons/download';
import SerialNumberInput from '../../../../../../ui/input/serialNumberInput';
import { defaultSevenThousandOptions } from '../../../../config';

const SevenThousandOptions: FC<ISevenThousandOptions> = (props) => {
  const {
    systemId,
    options = defaultSevenThousandOptions,
    settings = null,
    onChange = () => {},
    permissions = {},
    deviceCategories = [],
    handleOnChangeDeviceType = () => {},
    changeSettings = () => {},
    selectedNode = null,
    isSelectAllOptions,
    requestData = () => {},
    isAdmin = false,
  } = props;

  const { sendRequest: getFileData } = useApi(getFileRequest);

  const { sendRequest: createCertificate } = useApi<boolean>(postRequest);
  const { data: certificateStatus, sendRequest: getCertificateStatus } = useApi<boolean>(getRequest);
  const { data: certificateExport, loading, sendRequest: getCertificateExport } = useApi<any>(getRequest);

  const {
    data: hardwareDeviceTypes,
    sendRequest: getDeviceTypes,
    loading: deviceTypesLoading,
  } = useApi<IHardwareType[]>(getRequest);
  const {
    data: revisionsList,
    sendRequest: getAllRevisions,
    loading: revisionLoading,
  } = useApi<IHardwareRevision[]>(getRequest);
  const {
    data: versionsList,
    sendRequest: getAllVersions,
    loading: versionsLoading,
  } = useApi<IHardwareVersion[]>(getRequest);

  const [deviceCategoryModal, setDeviceCategoryModal] = useState<{
    value?: string;
    isOpen: boolean;
  }>({ isOpen: false });

  useEffect(() => {
    if (selectedNode?.itemId) {
      getCertificateStatus(getCertificateStatusUrl(selectedNode?.itemId));
    }
  }, [selectedNode]);

  const saveData = useCallback(async () => {
    const fileOptions = {
      suggestedName: `${selectedNode?.itemId}.cert`,
      types: [
        {
          description: 'cert',
          accept: {
            'text/plain': '.cert',
          },
        },
      ],
      excludeAcceptAllOption: true,
    };

    const fileHandle = await (window as any).showSaveFilePicker(fileOptions);
    const writableStream = await fileHandle.createWritable();

    await writableStream.write(certificateExport);
    await writableStream.close();
  }, [certificateExport, selectedNode?.itemId]);

  useEffect(() => {
    if (certificateExport && !loading) {
      saveData();
    }
  }, [certificateExport, loading]);

  const onDownload = useCallback(() => {
    if (selectedNode?.itemId) {
      getCertificateExport(getCertificateExportUrl(selectedNode?.itemId));
    }
  }, [getCertificateExport, selectedNode]);

  const updateCategoryDevice = useCallback(
    (deviceCategoryId: string) => {
      onChange(deviceCategories?.find((item) => item.id === deviceCategoryId) || null, 'deviceCategory');
    },
    [deviceCategories, onChange]
  );

  const handleOnChangeCategoryDevice = useCallback(
    (deviceCategoryId: string) => {
      if (options.selectedDeviceTypeId && settings) {
        setDeviceCategoryModal({ value: deviceCategoryId, isOpen: true });
      } else {
        updateCategoryDevice(deviceCategoryId);
      }
    },
    [options.selectedDeviceTypeId, settings, updateCategoryDevice]
  );

  const handleOnCloseCategoryDeviceModal = useCallback(() => {
    setDeviceCategoryModal({ isOpen: false });
  }, []);

  const handleOnOkCategoryDeviceModal = useCallback(() => {
    if (deviceCategoryModal.value) {
      onChange(null, 'selectedDeviceTypeId');
      updateCategoryDevice(deviceCategoryModal.value);
      if (settings) {
        changeSettings({ ...settings, accessPointTypeParams: null });
      }
    }
    setDeviceCategoryModal({ isOpen: false });
  }, [changeSettings, deviceCategoryModal.value, onChange, settings, updateCategoryDevice]);

  const onGetTypes = useCallback(async () => {
    if (!hardwareDeviceTypes?.length) {
      await getDeviceTypes(getHardwareTypesUrl(), { params: { objectId: systemId } });
    }
  }, [getDeviceTypes, hardwareDeviceTypes?.length]);

  const onGetRevisions = useCallback(
    async (selectedDeviceTypeId = options.selectedDeviceTypeId.value || '') => {
      const res = await getAllRevisions(getRevisionsListUrl(selectedDeviceTypeId));
      return res;
    },
    [getAllRevisions, options.selectedDeviceTypeId.value]
  );

  const onGetVersions = useCallback(
    async (
      selectedDeviceTypeId = options.selectedDeviceTypeId.value || '',
      selectedDeviceRevisionId = options.selectedDeviceRevisionId.value || ''
    ) => {
      const res = await getAllVersions(getRevisionVersionsUrl(selectedDeviceTypeId, selectedDeviceRevisionId));
      return res;
    },
    [options.selectedDeviceTypeId.value, options.selectedDeviceRevisionId.value, getAllVersions]
  );

  const onChangeRevision = useCallback(
    (e: string | number) => handleOnChangeDeviceType(options.selectedDeviceTypeId.value, e.toString(), null),
    [handleOnChangeDeviceType, options.selectedDeviceTypeId.value]
  );

  const onChangeVersion = useCallback(
    (e: string | number) =>
      handleOnChangeDeviceType(
        options.selectedDeviceTypeId.value,
        options.selectedDeviceRevisionId.value,
        e.toString()
      ),
    [handleOnChangeDeviceType, options.selectedDeviceTypeId.value, options.selectedDeviceRevisionId.value]
  );

  const onCreateCertificate = useCallback(async () => {
    await createCertificate(createCertificateUrl(settings?.id || ''), {});
    requestData();
  }, [createCertificate, requestData, settings?.id]);

  const onChangeType = useCallback(
    async (e: string | number) => {
      const revisions = (await onGetRevisions(e.toString())) as IHardwareRevision[];
      if (revisions?.length === 1) {
        onChangeRevision(revisions[0].id);
        const versions = (await onGetVersions(e.toString(), revisions[0].id)) as IHardwareVersion[];
        if (versions?.length === 1) {
          onChangeRevision(versions[0].id);
          handleOnChangeDeviceType(e.toString(), revisions[0].id, versions[0].id);
        } else {
          handleOnChangeDeviceType(e.toString(), revisions[0].id, null);
        }
      } else {
        handleOnChangeDeviceType(e.toString(), null, null);
      }
    },
    [handleOnChangeDeviceType, onChangeRevision, onGetRevisions, onGetVersions]
  );

  const onDownloadFile = useCallback(async () => {
    if (settings?.selectedDeviceVersionRevisionId) {
      const fileData = await getFileData(getDeviceFileUrl(settings?.selectedDeviceVersionRevisionId));
      if (fileData) {
        const name =
          fileData.headers['content-disposition']?.split('filename=')[1]?.split(';')[0] ||
          `${settings?.selectedDeviceVersion}.zip`;
        saveAs(fileData.data, name);
      }
    }
  }, [getFileData, settings?.selectedDeviceVersion, settings?.selectedDeviceVersionRevisionId]);

  const filteredHardwareDeviceTypes = useMemo<IHardwareType[]>(
    () => hardwareDeviceTypes?.filter((item: any) => item.deviceCategoryId === options.deviceCategory.value?.id) || [],
    [hardwareDeviceTypes, options.deviceCategory.value?.id]
  );

  const deviceCategoriesOptions = useMemo(
    () =>
      deviceCategories?.map<IRadioGroupOption>((category) => ({
        title: category.displayName || '',
        value: category.id,
      })) || [],
    [deviceCategories]
  );

  const hardwareDeviceTypesData = useMemo<ISelectOption[]>(
    () =>
      filteredHardwareDeviceTypes.length
        ? filteredHardwareDeviceTypes.map<ISelectOption>((object) => ({
            value: object.id || '',
            title: `${object.name} ${object.code}`,
          }))
        : options.selectedDeviceTypeId.value
        ? [
            {
              value: options.selectedDeviceTypeId.value || '',
              title: `${settings?.selectedDeviceType || ''} ${settings?.selectedDeviceTypeCode || ''}`,
            },
          ]
        : [],
    [
      filteredHardwareDeviceTypes,
      options.selectedDeviceTypeId.value,
      settings?.selectedDeviceType,
      settings?.selectedDeviceTypeCode,
    ]
  );

  const revisionsOptions = useMemo(
    () =>
      revisionsList?.length
        ? revisionsList?.map<ISelectOption>((object) => ({
            value: object.id || '',
            title: object.name,
          }))
        : options.selectedDeviceRevisionId.value
        ? [
            {
              value: options.selectedDeviceRevisionId.value || '',
              title: settings?.selectedDeviceRevision || '',
            },
          ]
        : [],
    [options.selectedDeviceRevisionId.value, revisionsList, settings?.selectedDeviceRevision]
  );

  const versionsOptions = useMemo(
    () =>
      versionsList?.length
        ? versionsList?.map<ISelectOption>((object) => ({
            value: object.id || '',
            title: object.name.toString(),
          }))
        : options.selectedDeviceVersionId.value
        ? ([
            {
              value: options.selectedDeviceVersionId.value || '',
              title: settings?.selectedDeviceVersion || '',
            },
          ] as ISelectOption[])
        : [],
    [options.selectedDeviceVersionId.value, settings?.selectedDeviceVersion, versionsList]
  );

  return (
    <div className="main-options">
      <NotificationModal
        isOpen={deviceCategoryModal.isOpen}
        title="При изменении категории оборудования указанные настройки оборудования будут удалены. Вы хотите внести изменение?"
        onOk={handleOnOkCategoryDeviceModal}
        onCancel={handleOnCloseCategoryDeviceModal}
        onClose={handleOnCloseCategoryDeviceModal}
        okButtonText="Да"
        cancelButtonText="Нет"
      />
      <div className="main-options__container">
        <div className="main-options__options main-options__options_gap">
          <Input
            title="Название оборудования"
            placeholder="Название оборудования"
            isRequired
            value={options.name.value || ''}
            onChange={(e: string) => onChange(e, 'name')}
            status={options.name.status}
            errorText={options.name.errorText}
            maxLength={100}
            inputType={InputType.text}
            disabled={!permissions.edit}
          />
          <SerialNumberInput
            title="Серийный номер"
            placeholder="Серийный номер"
            isRequired
            value={options.serialNumber.value || ''}
            onChange={(e: string) => onChange(e, 'serialNumber')}
            status={options.serialNumber.status}
            errorText={options.serialNumber.errorText}
            maxLength={20}
            inputType={InputType.text}
            disabled={!permissions.edit}
          />
          <Input
            title="Sip Номер"
            value={options.sipNumber.value || ''}
            status={options.sipNumber.status}
            errorText={options.sipNumber.errorText}
            inputType={InputType.text}
            isDisabledStyle
            disabled
          />
          <Input
            title="Sip Пароль"
            value={options.sipPassword.value || ''}
            status={options.sipPassword.status}
            errorText={options.sipPassword.errorText}
            inputType={InputType.text}
            isDisabledStyle
            disabled
          />
        </div>
        <div className="main-options__options-additionally-content">
          <Checkbox
            onChange={(e: boolean) => onChange(e, 'isUpdateAvailable')}
            checked={options.isUpdateAvailable.value}
            label="Разрешить обновление ПО домофона"
            disabled={!permissions.edit}
          />
        </div>
      </div>
      <div className="main-options__radio-container main-options__radio-container_mb">
        <RadioGroup
          title="Категория оборудования"
          options={deviceCategoriesOptions}
          value={options.deviceCategory.value?.id || ''}
          onChange={(value) => handleOnChangeCategoryDevice(value)}
          direction={ERadioGroupDirection.horizontal}
          disabled={!permissions.edit}
        />
        {!certificateStatus ? (
          <div className="main-options__certificate">
            <div className="main-options__certificate-label">Сертификат оборудования</div>
            <div className="main-options__certificate-content">
              <div className="main-options__certificate-icon">
                <CloseCircleIcon />
              </div>
              <div className="main-options__certificate-status">Нет сертификата</div>
              {isAdmin && (
                <div className="main-options__certificate-create" role="presentation" onClick={onCreateCertificate}>
                  Выпустить
                </div>
              )}
            </div>
          </div>
        ) : (
          <div className="main-options__certificate">
            <div className="main-options__certificate-label">Сертификат оборудования</div>
            <div className="main-options__certificate-content">
              <div className="main-options__certificate-icon">
                <CheckedIcon />
              </div>
              <div className="main-options__certificate-status">Есть сертификат</div>
              <div className="main-options__certificate-download" role="presentation" onClick={onDownload}>
                Скачать
                <div className="main-options__certificate-download-icon">
                  <DownloadIcon />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <div
        className={classNames('main-options__select', {
          'main-options__select_error': options.selectedDeviceTypeId.status === InputStatus.error,
        })}
      >
        <Select
          lengthLimit={false}
          title="Тип оборудования"
          placeholder="Тип оборудования"
          value={options.selectedDeviceTypeId.value || ''}
          onChange={onChangeType}
          disabled={!permissions.edit}
          onLoadData={onGetTypes}
          loading={deviceTypesLoading}
          placement="topLeft"
          isRequired
          options={hardwareDeviceTypesData}
          isError={options.selectedDeviceTypeId.status === InputStatus.error}
          errorText={
            options.selectedDeviceTypeId.status === InputStatus.error
              ? options.selectedDeviceTypeId.errorText || emptyFieldErrorText
              : ''
          }
        />
      </div>
      {options.selectedDeviceTypeId.value && (
        <div className="main-options__revision">
          <Select
            lengthLimit={false}
            loading={revisionLoading}
            title="Ревизия"
            placement="topLeft"
            value={options.selectedDeviceRevisionId.value || ''}
            onChange={onChangeRevision}
            disabled={!permissions.edit}
            onLoadData={onGetRevisions}
            isRequired
            options={revisionsOptions}
            isError={options.selectedDeviceRevisionId.status === InputStatus.error}
            errorText={
              options.selectedDeviceRevisionId.status === InputStatus.error
                ? options.selectedDeviceRevisionId.errorText || emptyFieldErrorText
                : ''
            }
          />
          {options.selectedDeviceRevisionId.value && (
            <Select
              lengthLimit={false}
              loading={versionsLoading}
              title="Версия"
              placement="topLeft"
              value={options.selectedDeviceVersionId.value || ''}
              onChange={onChangeVersion}
              disabled={!permissions.edit}
              onLoadData={onGetVersions}
              isRequired
              options={versionsOptions}
              isError={options.selectedDeviceVersionId.status === InputStatus.error}
              errorText={
                options.selectedDeviceVersionId.status === InputStatus.error
                  ? options.selectedDeviceVersionId.errorText || emptyFieldErrorText
                  : ''
              }
            />
          )}
        </div>
      )}

      {settings?.isFileDownloadAvailable && isSelectAllOptions && (
        <div className="hardware-settings__download" role="presentation" onClick={onDownloadFile}>
          <div className="hardware-settings__download-label">
            Файл для обновления ПО домофона {settings.selectedDeviceVersionFileName}
          </div>{' '}
          <div className="hardware-settings__download-icon">
            <DownloadIcon />
          </div>
        </div>
      )}
    </div>
  );
};

export default SevenThousandOptions;
