import { ISystemBase } from '../../../../typings/systems/base';
import { IProfilePermission } from '../../../../typings/profile';

export const invalidFieldToMainOption = new Map<string, string>([
  ['isIpCamDescriptionValid', 'ipCamDescription'],
  ['isIpCamMacValid', 'ipCamMac'],
  ['isIpCamNameValid', 'ipCamName'],
  ['isRevisionValid', 'revision'],
  ['isRtspSelected', 'ipCamRtspServerId'],
  ['isSerialNumberValid', 'serialNumber'],
  ['isTypeSelected', 'deviceType'],
]);

export interface IHardwareValidateButtons {
  isFiveThousandth?: boolean;
  permissions?: IProfilePermission;
  system?: ISystemBase;
  systemId?: string;
  invalidHardware?: IValidHardware[];
  selectedNode?: any;
  getSystemData?: () => void;
  setInvalidHardware?: (data: IValidHardware[]) => void;
  onChangeActive?: (hardware: IValidHardware) => void;
}

export interface IValidHardware {
  id: string;
  invalidParams: [] | null;
  isIpCamDescriptionValid: boolean;
  isIpCamMacValid: boolean;
  isIpCamNameValid: boolean;
  isRevisionValid: boolean;
  isRtspSelected: boolean;
  isSerialNumberValid: boolean;
  isTypeSelected: boolean;
}
