import React, { FC, useCallback, useEffect, useState } from 'react';
import moment, { Moment } from 'moment';
import { AxiosError } from 'axios';
import { IDisablingPaymentModal } from './types';
import Modal from '../../../../ui/modal';
import Button from '../../../../ui/button';
import Input from '../../../../ui/input';
import { postRequest } from '../../../../../api';
import { useApi } from '../../../../../hooks/useApi';
import PeriodDatePicker from '../../../../ui/periodDatePicker';
import { dateFormatNoTime } from '../../../../../constants/date';
import { setFlatPaymentDisableUrl } from '../../../../../constants/api';

const DisablingPaymentModal: FC<IDisablingPaymentModal> = (props) => {
  const { isOpen = false, onClose = () => {}, onAccept = () => {}, flatId = '' } = props;

  const { sendRequest: setDisablePayment, loading } = useApi(postRequest);

  const [comment, setComment] = useState<string>('');
  const [fromDate, setFromDate] = useState<string | null>(moment().format());
  const [toDate, setToDate] = useState<string | null>(null);

  useEffect(() => {
    if (isOpen) {
      setComment('');
      setFromDate(moment().format());
      setToDate(null);
    }
  }, [isOpen]);

  const handleOnClose = useCallback(() => {
    onClose();
    setComment('');
    setFromDate(moment().format());
    setToDate('');
  }, [onClose]);

  const handleOnSend = useCallback(async () => {
    const resError = (await setDisablePayment(setFlatPaymentDisableUrl(flatId), {
      description: comment,
      startDate: fromDate ? moment(fromDate)?.toISOString() : null,
      endDate: toDate ? moment(toDate)?.startOf('day').toISOString() : null,
    })) as AxiosError;

    if (!resError?.response?.data) {
      onAccept();
    }
  }, [comment, flatId, fromDate, onAccept, setDisablePayment, toDate]);

  const handleOnChangeDate = useCallback((firstDate?: Moment, secondDate?: Moment) => {
    setFromDate(firstDate?.toISOString() || null);
    setToDate(secondDate?.toISOString() || null);
  }, []);

  return (
    <Modal
      isOpen={isOpen}
      title="Отключение оплаты"
      centered
      width={840}
      onCancel={handleOnClose}
      showCloseIcon
      wrapClassName="disabling-payment-modal__wrapper"
      footer={
        <div className="disabling-payment-modal__button-container">
          <Button disabled={!comment || !comment.trim()} loading={loading} onClick={handleOnSend}>
            Применить
          </Button>
        </div>
      }
    >
      <div className="disabling-payment-modal">
        <div className="disabling-payment-modal__title">
          Отключение оплаты предоставляет бесплатное использование всех функций МП за установленный период всем
          абонентам квартиры. При необходимости вы сможете завершить отключение раньше
        </div>
        <div className="disabling-payment-modal__inputs">
          <PeriodDatePicker
            onlyFuture
            textInfo="При выборе периода обратите внимание на наличие текущей подписки и срок ее действия. Период отключения оплаты не продлевает срок действия текущей подписки"
            isCanUnlimited
            title="Период действия"
            firstDate={fromDate || ''}
            secondDate={toDate || ''}
            format={dateFormatNoTime}
            onChange={handleOnChangeDate}
            placeholder="Не ограничен"
          />
          <Input
            isRequired
            title="Комментарий"
            placeholder="Оставьте комментарий"
            value={comment}
            onChange={setComment}
            maxLength={100}
          />
        </div>
      </div>
    </Modal>
  );
};

export default DisablingPaymentModal;
