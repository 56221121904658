import React from 'react';
import { ITooltip } from '../ui/tooltip/types';

export enum EAvailableTabs {
  isAccessGroupsAvailable = 'isAccessGroupsAvailable',
  isAccessGroupsCheck = 'isAccessGroupsCheck',
  isAccessPointSettingsAvailable = 'isAccessPointSettingsAvailable',
  isBaseAvailable = 'isBaseAvailable',
  isCallCodesAvailable = 'isCallCodesAvailable',
  isFlatsAvailable = 'isFlatsAvailable',
  isObjectKeysAvailable = 'isObjectKeysAvailable',
  isPlanAvailable = 'isPlanAvailable',
  isSynchronizationAvailable = 'isSynchronizationAvailable',
  isSecurityDeckAvailable = 'isSecurityDeckAvailable',
}

export interface IAvailableTabs {
  isAccessGroupsAvailable: boolean;
  isAccessPointSettingsAvailable: boolean;
  isAccessGroupsCheck: boolean;
  isBaseAvailable: boolean;
  isCallCodesAvailable: boolean;
  isFlatsAvailable: boolean;
  isObjectKeysAvailable: boolean;
  isPlanAvailable: boolean;
  isSynchronizationAvailable: boolean;
  isSecurityDeckAvailable: boolean;
  isTariffAvailable: boolean;
}

export interface ITab {
  id: string;
  title: string;
  children?: React.ReactNode;
  position: number;
  disabled?: boolean;
  availableTab?: EAvailableTabs;
  notification?: string;
  tooltipProps?: ITooltip;
  component?: React.FC<any>;
}

export interface ITabs {
  tabs: ITab[];
  activeTabKey?: string;
  onChangeActiveTab?: (key: any) => void;
  containerClassName?: string;
  tabsClassName?: string;
  tabsPaneClassName?: string;
  showTabNumber?: boolean;
  tabBarExtraContent?: React.ReactNode;
}
