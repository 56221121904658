import moment, { Moment } from 'moment';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { getEntitiesForSubscriber } from '../../../../api/subscribers';
import { IAccessPointLogApiParams } from '../../../../api/subscribers/accessPointLog';
import { subscriberDeviceLogInitApiParams } from '../../../../api/subscribers/config';
import { getSubscriberDeviceLogUrl } from '../../../../constants/api';
import { dateFormatNoTime, dateISOFormatNoTimeZone } from '../../../../constants/date';
import { selectAllOptionKey } from '../../../../constants/select';
import { useApi } from '../../../../hooks/useApi';
import { IApiResponse } from '../../../../typings/api';
import { IDeviceLog } from '../../../../typings/subscribers/deviceLog';
import PeriodDatePicker from '../../../ui/periodDatePicker';
import Select from '../../../ui/select';
import { ISelectOption } from '../../../ui/select/types';
import SelectMultiple from '../../../ui/selectMultiple';
import { ISelectMultipleOption } from '../../../ui/selectMultiple/types';
import DeviceLogList from '../deviceLogList';
import { IDeviceLogData } from './types';

const DeviceLogData: FC<IDeviceLogData> = (props) => {
  const { flatId = '', devices = [], actionTypeList = [], actionTypeListLoading = false } = props;

  const [selectedDeviceId, setSelectedDeviceId] = useState<string>(selectAllOptionKey);
  const [selectedActionTypeList, setSelectedActionTypeList] = useState<string[]>([selectAllOptionKey]);
  const {
    data: logs,
    loading: logsLoading,
    sendRequest: sendRequestLogs,
  } = useApi<IApiResponse<IDeviceLog>>(getEntitiesForSubscriber);
  const [dateFrom, setDateFrom] = useState<string>(
    moment().subtract(1, 'month').startOf('day').format(dateISOFormatNoTimeZone)
  );
  const [dateTo, setDateTo] = useState<string>(moment().endOf('day').format(dateISOFormatNoTimeZone));
  const [accessPointLogApiParams, setAccessPointLogApiParams] = useState<IAccessPointLogApiParams>({
    ...subscriberDeviceLogInitApiParams,
    dateFrom: dateFrom,
    dateTo: dateTo,
  });

  useEffect(() => {
    if (flatId) {
      sendRequestLogs(getSubscriberDeviceLogUrl(flatId), { params: { ...accessPointLogApiParams } });
    }
  }, [flatId]);

  useEffect(() => {
    setSelectedActionTypeList([selectAllOptionKey]);
    const updatedApiParams: IAccessPointLogApiParams = {
      ...accessPointLogApiParams,
      type: actionTypeList?.map((item) => item.type || '').filter((item) => item),
    };
    setAccessPointLogApiParams(updatedApiParams);
    if (flatId) {
      sendRequestLogs(getSubscriberDeviceLogUrl(flatId), { params: { ...updatedApiParams } });
    }
  }, [actionTypeList]);

  const deviceSelectDisable = useMemo(() => !devices || !(devices?.length > 0), [devices]);
  const actionTypeListSelectDisable = useMemo(() => !actionTypeList || !(actionTypeList?.length > 0), [actionTypeList]);

  const handleOnChangeSelectDevice = useCallback(
    async (value: string | number) => {
      const updatedApiParams: IAccessPointLogApiParams = {
        ...accessPointLogApiParams,
        deviceId: value === selectAllOptionKey ? '' : value.toString(),
        page: 0,
      };
      await sendRequestLogs(getSubscriberDeviceLogUrl(flatId), { params: { ...updatedApiParams } });
      setSelectedDeviceId(value.toString());
      setAccessPointLogApiParams(updatedApiParams);
    },
    [accessPointLogApiParams, flatId, sendRequestLogs]
  );

  const handleOnChangeSelectActionTypeList = useCallback(
    async (values: string[]) => {
      const updatedApiParams: IAccessPointLogApiParams = {
        ...accessPointLogApiParams,
        type: values.includes(selectAllOptionKey)
          ? actionTypeList?.map((item) => item.type || '').filter((item) => item)
          : values,
      };
      await sendRequestLogs(getSubscriberDeviceLogUrl(flatId), {
        params: { ...updatedApiParams },
      });
      setSelectedActionTypeList(values);
      setAccessPointLogApiParams(updatedApiParams);
    },
    [accessPointLogApiParams, actionTypeList, flatId, sendRequestLogs]
  );

  const handleOnChangeDate = useCallback(
    async (firstDate?: Moment, secondDate?: Moment) => {
      const currentDateFrom = firstDate?.startOf('day').format(dateISOFormatNoTimeZone);
      const currentDateTo = secondDate?.endOf('day').format(dateISOFormatNoTimeZone);

      setDateFrom(currentDateFrom || '');
      setDateTo(currentDateTo || '');

      const updatedApiParams: IAccessPointLogApiParams = {
        ...accessPointLogApiParams,
        dateFrom: currentDateFrom || '',
        dateTo: currentDateTo || '',
        page: 0,
      };
      await sendRequestLogs(getSubscriberDeviceLogUrl(flatId), { params: { ...updatedApiParams } });
      setAccessPointLogApiParams(updatedApiParams);
    },
    [accessPointLogApiParams, flatId, sendRequestLogs]
  );

  const handleOnChangePageDeviceLogList = useCallback(
    async (page: number) => {
      const updatedApiParams: IAccessPointLogApiParams = {
        ...accessPointLogApiParams,
        page: page - 1,
      };
      await sendRequestLogs(getSubscriberDeviceLogUrl(flatId), { params: { ...updatedApiParams } });
      setAccessPointLogApiParams(updatedApiParams);
    },
    [accessPointLogApiParams, flatId, sendRequestLogs]
  );

  const selectDeviceOptions = useMemo(
    () => [
      { value: selectAllOptionKey, data: 'Все', title: 'Все' },
      ...devices.map<ISelectOption>((device) => ({ value: device.id || '', title: device.name || '' })),
    ],
    [devices]
  );

  const selectActionTypeListOptions = useMemo(
    () => [
      { value: selectAllOptionKey, title: 'Все' },
      ...actionTypeList.map<ISelectMultipleOption>((action) => ({
        value: action.type || '',
        title: action.displayName || '',
      })),
    ],
    [actionTypeList]
  );

  return (
    <div className="device-log-data">
      <div className="device-log-data__tools-container">
        <div className="device-log-data__tools-cell">
          <Select
            title="Оборудование"
            value={selectedDeviceId}
            onChange={handleOnChangeSelectDevice}
            disabled={deviceSelectDisable}
            options={selectDeviceOptions}
          />
        </div>
        <div className="device-log-data__tools-cell">
          <SelectMultiple
            title="Тип события"
            values={selectedActionTypeList}
            onChange={handleOnChangeSelectActionTypeList}
            disabled={actionTypeListSelectDisable}
            options={selectActionTypeListOptions}
            loading={actionTypeListLoading}
          />
        </div>
        <div className="device-log-data__tools-cell">
          <PeriodDatePicker
            title="Период"
            placeholder="дд.мм.гггг - дд.мм.гггг"
            format={dateFormatNoTime}
            firstDate={dateFrom}
            secondDate={dateTo}
            onChange={handleOnChangeDate}
          />
        </div>
      </div>
      <div className="device-log-data__content-wrapper">
        <DeviceLogList
          logs={logs?.items}
          loading={logsLoading}
          pagination={{
            pageSize: logs?.pageSize || 0,
            currentPage: logs?.page || 0,
            total: logs?.totalCount || 0,
            onChangePage: handleOnChangePageDeviceLogList,
          }}
        />
      </div>
    </div>
  );
};

export default DeviceLogData;
