import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import moment, { Moment } from 'moment';
import ArrowBackIcon from '../../../../assets/svg/icons/arrowBack';
import CommonHead from '../../../../components/commonHead';
import ButtonLink from '../../../../components/ui/buttonLink';
import { paths } from '../../../../constants/paths';
import { useAppDispatch } from '../../../../hooks/hooks';
import { useApi } from '../../../../hooks/useApi';
import { setHeaderTitle } from '../../../../store/slices/header';
import { getPaymentsData, setPayments } from '../../../../api/payments';
import { ISubscription, subscriptionsStatusNamesMap } from '../../../../typings/subscriptions';
import { paymentsListUrl, subscriptionPaymentUrl } from '../../../../constants/api';
import { IPaymentApiParams, IPaymentsApiSettings } from '../../../../api/payments/types';
import { IApiResponse, IApiSortField } from '../../../../typings/api';
import { IPayment, paymentStatusNamesMap } from '../../../../typings/payments';
import { getPaymentApiSettings } from '../../../../api/payments/config';
import SubscriptionTable from '../../../../components/subscriptionTable';
import Select from '../../../../components/ui/select';
import { ISelectOption } from '../../../../components/ui/select/types';
import InputSearch from '../../../../components/ui/inputSearch';
import PeriodDatePicker from '../../../../components/ui/periodDatePicker';
import { dateDefaultFormat, dateFormatNoTime, dateISOFormatNoTimeZone } from '../../../../constants/date';
import InformationBlock from '../../../../components/ui/informationBlock';
import { EPageQueryParams, IQueryParamsObject } from '../../../../typings/searchParams';
import { getFullPath } from '../../../../functions';

const SubscriptionInfo: FC = () => {
  const params = useParams();
  const dispatch = useAppDispatch();

  const [apiSettings, setApiSettings] = useState<IPaymentsApiSettings<IPayment>>(
    getPaymentApiSettings('paymentCreateDate')
  );
  const [eventsApiParams, setEventsApiParams] = useState<IPaymentApiParams>({});

  const [searchValue, setSearchValue] = useState<string>('');

  const [searchParams] = useSearchParams();

  const [queryParams, setQueryParams] = useState<IQueryParamsObject>({ prevPage: paths.mpManagementSubscriptions });

  const {
    data: subscription,
    sendRequest: getSubscription,
    loading: subscriptionLoading,
  } = useApi<ISubscription>(getPaymentsData);

  const {
    data: payments,
    sendRequest: getPayments,
    loading: paymentsLoading,
  } = useApi<IApiResponse<IPayment>>(setPayments);

  useEffect(() => {
    dispatch(setHeaderTitle('Информация о подписке'));
  }, [dispatch]);

  useEffect(() => {
    const { subscriptionId } = params;
    if (subscriptionId) {
      getSubscription(subscriptionPaymentUrl(subscriptionId));
    }
    const prev = searchParams.get(EPageQueryParams.prevPage);
    if (prev) {
      setQueryParams({
        prevPage: prev || '',
        prevPageId: searchParams.get(EPageQueryParams.prevPageId) || '',
        prevTabId: searchParams.get(EPageQueryParams.prevTabId) || '',
      });
    }
  }, []);

  const requestData = useCallback(
    async (
      reqSettings: IPaymentsApiSettings<IPayment> = apiSettings,
      newApiParams: IPaymentApiParams = eventsApiParams
    ) => {
      const param = { ...newApiParams, subscriptionId: subscription?.subscriptionId };
      setEventsApiParams(param);
      setApiSettings(reqSettings);
      await getPayments(paymentsListUrl(), reqSettings, { params: param });
    },
    [apiSettings, eventsApiParams, getPayments, subscription?.subscriptionId]
  );

  useEffect(() => {
    if (subscription) {
      requestData(apiSettings, {});
    }
  }, [subscription]);

  const handleOnSearch = useCallback(
    async (value: string) => {
      setSearchValue(value);
      const newApiSettings: IPaymentsApiSettings<IPayment> = { ...apiSettings, page: 0 };
      const newEventsParams = { ...eventsApiParams, paymentIdSearch: value };
      await requestData(newApiSettings, newEventsParams);
    },
    [apiSettings, eventsApiParams, requestData]
  );

  const handleOnChangeDate = useCallback(
    async (firstDate?: Moment, secondDate?: Moment) => {
      const newApiSettings: IPaymentsApiSettings<IPayment> = { ...apiSettings, page: 0 };
      const newEventsParams = {
        ...eventsApiParams,
        startDate: firstDate?.startOf('day').format(dateISOFormatNoTimeZone) || '',
        endDate: secondDate?.endOf('day').format(dateISOFormatNoTimeZone) || '',
      };
      await requestData(newApiSettings, newEventsParams);
    },
    [apiSettings, eventsApiParams, requestData]
  );

  const handleOnChangeTablePage = useCallback(
    async (page: number) => {
      const newApiSettings: IPaymentsApiSettings<IPayment> = { ...apiSettings, page: page - 1 };
      await requestData(newApiSettings);
    },
    [apiSettings, requestData]
  );

  const handleOnSort = useCallback(
    async (sortResults: IApiSortField<IPayment>[]) => {
      const newApiSettings: IPaymentsApiSettings<IPayment> = { ...apiSettings, sortFields: sortResults };
      await requestData(newApiSettings);
    },
    [apiSettings, requestData]
  );

  const handleOnChangeSelect = useCallback(
    (key: string) => (val: string | number) => {
      const newApiSettings: IPaymentsApiSettings<IPayment> = { ...apiSettings, page: 0 };
      const newEventsParams = {
        ...eventsApiParams,
        [key]: val,
      };
      requestData(newApiSettings, newEventsParams);
    },
    [apiSettings, eventsApiParams, requestData]
  );

  const statusOptions = useMemo(() => {
    const result: ISelectOption[] = [];
    paymentStatusNamesMap.forEach((value, key) => {
      result.push({
        value: key || '',
        title: value || '',
      });
    });
    return result;
  }, []);

  const backLabel = useMemo(
    () =>
      queryParams.prevPage === paths.mpManagementSubscriptions
        ? 'Все подписки'
        : queryParams.prevPage === paths.subscribers
        ? 'К абоненту'
        : queryParams.prevPage === paths.mpManagementPayments
        ? 'К платежу'
        : queryParams.prevPage === paths.flats
        ? 'К квартире'
        : '',
    [queryParams]
  );

  return (
    <>
      <CommonHead seo={{ title: 'Информация о подписке' }} />
      <div className="payment">
        <div className="subscription__back-button-wrapper">
          <ButtonLink url={getFullPath(queryParams)} leftIcon={<ArrowBackIcon />} content={backLabel} />
        </div>
        <div className="subscription__content">
          <InformationBlock
            data={[
              [
                {
                  items: [
                    {
                      name: 'Организация',
                      value: subscription?.companyName || '',
                    },
                    {
                      name: 'Объект',
                      value: subscription?.objectName || '',
                    },
                    {
                      name: 'Адрес',
                      value: subscription?.address || '',
                    },
                    {
                      name: 'ФИО',
                      value: subscription?.fio || '',
                    },
                    {
                      name: 'Телефон',
                      value: subscription?.phone || '',
                    },
                    {
                      name: 'Тариф',
                      value: `${subscription?.tariff} (${subscription?.monthInTariff} мес.)`,
                    },
                    {
                      name: 'Статус',
                      value:
                        (subscription?.subscriptionStatus &&
                          subscriptionsStatusNamesMap.get(subscription?.subscriptionStatus)) ||
                        '',
                    },
                    {
                      name: 'Источник платежа',
                      value: subscription?.provider || '',
                    },
                    {
                      name: 'Дата создания',
                      value: subscription?.subscriptionCreateDate
                        ? moment(subscription?.subscriptionCreateDate).format(dateDefaultFormat)
                        : '-',
                    },
                    {
                      name: 'Дата изменения',
                      value: subscription?.subscriptionUpdateDate
                        ? moment(subscription?.subscriptionUpdateDate).format(dateDefaultFormat)
                        : '-',
                    },
                    {
                      name: 'Дата следующего платежа',
                      value: subscription?.nextPaymentDate
                        ? moment(subscription?.nextPaymentDate).format(dateDefaultFormat)
                        : '-',
                    },
                  ],
                },
              ],
              [
                {
                  title: 'Стоимость одного месяца',
                  items: [
                    {
                      name: 'Стоимость Eltis',
                      value: subscription?.fixCommission?.toFixed(2) || '',
                    },
                    {
                      name: 'Стоимость наценки организации',
                      value: subscription?.additionalCompanyPrice?.toFixed(2) || '',
                    },
                    {
                      name: 'Стоимость тех. обслуживания',
                      value: subscription?.maintenancePrice?.toFixed(2) || '',
                    },
                    {
                      name: 'Размер скидки',
                      value: `${subscription?.discountAmount?.toFixed(2)} (${subscription?.discount}% от наценки)`,
                    },
                    {
                      name: 'Итоговая стоимость',
                      value: subscription?.amount?.toFixed(2) || '',
                    },
                  ],
                },
                {
                  title: 'Платежи за за весь период',
                  items: [
                    {
                      name: 'Сумма Eltis',
                      value: subscription?.totalFixCommission?.toFixed(2) || '',
                    },
                    {
                      name: 'Сумма наценки организации',
                      value: subscription?.totalAdditionalCompanyPrice?.toFixed(2) || '',
                    },
                    {
                      name: 'Сумма тех. обслуживания',
                      value: subscription?.totalMaintenancePrice?.toFixed(2) || '',
                    },
                    {
                      name: 'Итоговая сумма',
                      value: subscription?.totalAmount?.toFixed(2) || '',
                    },
                  ],
                },
              ],
            ]}
          />

          <div className="subscription__filters">
            <InputSearch
              placeholder="Поиск по ID транзакции"
              value={eventsApiParams?.paymentIdSearch || ''}
              onSearch={handleOnSearch}
            />
            <PeriodDatePicker
              title="Период"
              placeholder="Выберите период"
              firstDate={eventsApiParams.startDate}
              secondDate={eventsApiParams.endDate}
              onChange={handleOnChangeDate}
              format={dateFormatNoTime}
            />
            <Select
              isAllOption
              title="Статус"
              value={eventsApiParams.status || ''}
              onChange={handleOnChangeSelect('status')}
              options={statusOptions}
            />
          </div>

          <SubscriptionTable
            subscriberId={params.subscriptionId}
            payments={payments?.items || []}
            loading={paymentsLoading || subscriptionLoading}
            pageSize={payments?.pageSize}
            total={payments?.totalCount}
            currentPage={payments?.page}
            onChangePage={handleOnChangeTablePage}
            onSort={handleOnSort}
            sortOrders={apiSettings.sortFields}
            isSearch={!!searchValue}
          />
        </div>
      </div>
    </>
  );
};

export default SubscriptionInfo;
