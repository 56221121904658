import { MAC_ADDRESS_VALIDATE_REGEX, ONLY_LETTERS_NUMBERS_REGEX } from '../../../constants/regex';
import { InputStatus } from '../../ui/input/types';
import { IHardwareInput } from './types';

export interface ICameraOptionsData {
  mac: IHardwareInput;
  innerLogin: IHardwareInput;
  innerPassword: IHardwareInput;
  videoServerId: IHardwareInput;
  rtspServerId: IHardwareInput;
}

export interface ISevenThousandOptionsData {
  name: IHardwareInput;
  serialNumber: IHardwareInput;
  networkAddress: IHardwareInput;
  sipNumber: IHardwareInput;
  sipPassword: IHardwareInput;
  selectedDeviceTypeId: IHardwareInput;
  deviceCategory: IHardwareInput;
  isUpdateAvailable: IHardwareInput;
  selectedDeviceRevisionId: IHardwareInput;
  selectedDeviceVersionId: IHardwareInput;
}

export interface IFiveThousandthOptionsData {
  name: IHardwareInput;
  serialNumber: IHardwareInput;
  netAddress: IHardwareInput;
  netPassword: IHardwareInput;
  isLocalConnection: IHardwareInput;
  deviceCategory: IHardwareInput;
  selectedDeviceTypeId: IHardwareInput;
  selectedDeviceRevisionId: IHardwareInput;
  selectedDeviceVersionId: IHardwareInput;
}

export const defaultValue: IHardwareInput = {
  value: null,
  status: InputStatus.normal,
  errorText: '',
  isRequired: false,
};

export const defaultRequiredValue: IHardwareInput = {
  ...defaultValue,
  isRequired: true,
};

export const defaultSevenThousandOptions: ISevenThousandOptionsData = {
  name: { ...defaultRequiredValue },
  serialNumber: { ...defaultRequiredValue },
  networkAddress: { ...defaultValue },
  selectedDeviceTypeId: { ...defaultRequiredValue },
  deviceCategory: { ...defaultValue },
  sipNumber: { ...defaultValue },
  sipPassword: { ...defaultValue },
  isUpdateAvailable: { ...defaultValue },
  selectedDeviceRevisionId: { ...defaultRequiredValue },
  selectedDeviceVersionId: { ...defaultRequiredValue },
};

export const defaultFiveThousandOptions: IFiveThousandthOptionsData = {
  name: { ...defaultRequiredValue },
  serialNumber: { ...defaultRequiredValue },
  netAddress: { ...defaultRequiredValue },
  netPassword: { ...defaultRequiredValue },
  isLocalConnection: { ...defaultRequiredValue },
  selectedDeviceTypeId: { ...defaultRequiredValue },
  deviceCategory: { ...defaultValue },
  selectedDeviceRevisionId: { ...defaultRequiredValue },
  selectedDeviceVersionId: { ...defaultRequiredValue },
};

export const defaultCameraOptions: ICameraOptionsData = {
  mac: {
    ...defaultRequiredValue,
    defaultErrorText: 'Введите адрес в формате FF:FF:FF:FF:FF:FF',
    test: MAC_ADDRESS_VALIDATE_REGEX,
  },
  innerLogin: {
    ...defaultValue,
    defaultErrorText: 'Введите латинские буквы и цифры без пробелов',
    test: ONLY_LETTERS_NUMBERS_REGEX,
  },
  innerPassword: {
    ...defaultValue,
    defaultErrorText: 'Введите латинские буквы и цифры без пробелов',
    test: ONLY_LETTERS_NUMBERS_REGEX,
  },
  videoServerId: { ...defaultRequiredValue },
  rtspServerId: { ...defaultRequiredValue },
};
