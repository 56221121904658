import { AxiosError } from 'axios';
import moment from 'moment';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { useNavigate } from 'react-router';
import { addSubscriber, editSubscriber, getEntitiesForSubscriber } from '../../../api/subscribers';
import {
  addSubscriberUrl,
  editSubscriberUrl,
  getSubscriberObjectsUrl,
  getSubscriberSubscriptionsUrl,
  subscriberValidateMainFlatDeleteUrl,
} from '../../../constants/api';
import { EMAIL_REGEX } from '../../../constants/regex';
import { useApi } from '../../../hooks/useApi';
import { ESubscribersTabsIds } from '../../../pages/subscribers/types';
import { IApiError, IApiParams, IApiResponse } from '../../../typings/api';
import { Status } from '../../../typings/status';
import {
  IMainFlatChangeNotConfirmed,
  ISubscriber,
  ISubscriberApartment,
  ISubscriberObject,
  ISubscriberSubscription,
  SubscriberErrorCodes,
} from '../../../typings/subscribers';
import Message from '../../message';
import TabNavButtons from '../../tabs/tabNavButtons';
import { tabNavButtonsDefault } from '../../tabs/tabNavButtons/utils';
import Button from '../../ui/button';
import Input from '../../ui/input';
import { EFieldTypes, IInputValue, InputStatus } from '../../ui/input/types';
import NotificationModal from '../../ui/notificationModal';
import { ISubscriberTabComponent, SubscriberEditMode } from '../types';
import SubscriberData from './subscriberData';
import SubscriberObjectAddress from './subscriberObjectAddress';
import ChangeMainSubscriberModal from '../../changeMainSubscribersModal';
import { dateDefaultFormat } from '../../../constants/date';
import { IConfirmData } from '../../ui/universalModal/types';
import { defaultConfirm, infoConfirmModal, simpleConfirmModal } from '../../ui/universalModal/config';
import UniversalModal from '../../ui/universalModal';
import { getRequest } from '../../../api';
import { useAppDispatch, useAppSelector } from '../../../hooks/hooks';
import { getProfilePermission } from '../../../store/selectors/profile';
import { ESidebarItemIds } from '../../../typings/sidebar';
import { getClickedSidebarTab } from '../../../store/selectors/sidebar';
import { setClickedSidebarTab } from '../../../store/slices/sidebar';
import CheckedIcon from '../../../assets/svg/icons/checkedIcon';
import CloseCircleIcon from '../../../assets/svg/icons/closeCIrcle';

const getFlatsFromSubscriberObjects = (subscriberObjects: ISubscriberObject[]): ISubscriberApartment[] =>
  subscriberObjects.reduce<ISubscriberApartment[]>((result, object) => result.concat(object.flats || []), []);

const errorText = 'Поле обязательно для заполнения';

const SubscribersBaseTab: FC<ISubscriberTabComponent> = (props) => {
  const {
    subscriber,
    editMode = SubscriberEditMode.viewing,
    onSave = () => {},
    onNextTab = () => {},
    nextButtonText = 'Готово',
    wasChange = false,
    setWasChange = () => {},
    nextTabId = '',
    resetNextTabId = () => {},
    isActiveTab = false,
    onChangeDisableAllTabs = () => {},
  } = props;

  const { data: objects, sendRequest: sendObjectsRequest } =
    useApi<IApiResponse<Omit<ISubscriberObject, 'flats'>>>(getEntitiesForSubscriber);
  const {
    data: newSubscriberId,
    sendRequest: sendRequestCreateSubscriber,
    loading: createSubscriberLoading,
  } = useApi<string>(addSubscriber);
  const { sendRequest: sendRequestEditSubscriber, loading: editSubscriberLoading } = useApi(editSubscriber);
  const {
    data: subscriptions,
    sendRequest: getSubscriptions,
    loading: subscriptionsLoading,
  } = useApi<ISubscriberSubscription[]>(getEntitiesForSubscriber);
  const { sendRequest: validateDelete, loading: validateDeleteLoading } = useApi(getRequest);

  const [objectApiParams] = useState<IApiParams<Omit<ISubscriberObject, 'flats'>>>({
    sort: [{ name: 'name' }],
    search: '',
    page: 0,
    count: 0,
  });

  const [firstName, setFirstName] = useState<IInputValue>({
    value: subscriber?.firstName || '',
    status: InputStatus.normal,
    errorText: '',
  });
  const [lastName, setLastName] = useState<IInputValue>({
    value: subscriber?.lastName || '',
    status: InputStatus.normal,
    errorText: '',
  });
  const [middleName, setMiddleName] = useState<IInputValue>({
    value: subscriber?.middleName || '',
    status: InputStatus.normal,
    errorText: '',
  });
  const [shortName, setShortName] = useState<IInputValue>({
    value: subscriber?.addressing || '',
    status: InputStatus.normal,
    errorText: '',
  });
  const [phoneNumber, setPhoneNumber] = useState<IInputValue>({
    value: subscriber?.phone ? `+${subscriber.phone}` : '',
    status: InputStatus.normal,
    errorText: '',
  });
  const [email, setEmail] = useState<IInputValue>({ value: subscriber?.email || '', status: InputStatus.normal });
  const [commentary, setCommentary] = useState<IInputValue>({
    value: subscriber?.commentary || '',
    status: InputStatus.normal,
    errorText: '',
  });
  const [subscriberFlats, setSubscriberFlats] = useState<ISubscriberApartment[]>([]);
  const [subscriberFlatsIsMainErrors, setSubscriberFlatsIsMainErrors] = useState<string[]>([]);
  const [subscriberFlatsIsStatusErrors, setSubscriberFlatsIsStatusErrors] = useState<string[]>([]);
  const [flatIsMainModal, setFlatIsMainModal] = useState<{ isOpen: boolean; title?: string }>({ isOpen: false });
  const [mainSubscriberModal, setMainSubscriberModal] = useState<boolean>(false);

  const [mainFlatChangeNotConfirmedData, setMainFlatChangeNotConfirmedData] = useState<IMainFlatChangeNotConfirmed[]>(
    []
  );

  const [confirmData, setConfirmData] = useState<IConfirmData>(defaultConfirm);

  const closeConfirm = useCallback(() => setConfirmData(defaultConfirm), []);
  const permission = useAppSelector(getProfilePermission(ESidebarItemIds.subscribers));

  const navigate = useNavigate();

  const clickedSidebarTab = useAppSelector(getClickedSidebarTab);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (subscriber) {
      setSubscriberFlats(getFlatsFromSubscriberObjects(subscriber?.subscriberObjects || []));
      if (permission?.view) {
        getSubscriptions(getSubscriberSubscriptionsUrl(subscriber.id || ''));
      }
    }
  }, [subscriber]);

  useEffect(() => {
    if (editMode !== SubscriberEditMode.viewing) {
      sendObjectsRequest(getSubscriberObjectsUrl(), { params: objectApiParams });
    }
  }, [editMode]);

  useEffect(() => {
    if (newSubscriberId) {
      onSave({ id: newSubscriberId });
    }
  }, [newSubscriberId]);

  const handleOnChangeField = useCallback(
    (setter: React.Dispatch<React.SetStateAction<IInputValue>>, value: IInputValue) => {
      setter({ ...value, status: InputStatus.normal, errorText: '' });
      setWasChange(true);
    },
    [setWasChange]
  );

  useEffect(() => {
    handleOnChangeField(setFirstName, { ...firstName, value: subscriber?.firstName || '' });
    handleOnChangeField(setLastName, { ...lastName, value: subscriber?.lastName || '' });
    handleOnChangeField(setMiddleName, { ...middleName, value: subscriber?.middleName || '' });
    handleOnChangeField(setShortName, { ...shortName, value: subscriber?.addressing || '' });
    handleOnChangeField(setPhoneNumber, { ...phoneNumber, value: subscriber?.phone ? `+${subscriber.phone}` : '' });
    handleOnChangeField(setEmail, { ...email, value: subscriber?.email || '' });
    handleOnChangeField(setCommentary, { ...commentary, value: subscriber?.commentary || '' });
    setSubscriberFlats(getFlatsFromSubscriberObjects(subscriber?.subscriberObjects || []));
    setWasChange(false);
    resetNextTabId();
  }, [subscriber]);

  const validateBaseInfo = useCallback(() => {
    let result = true;
    if (!firstName.value || !firstName.value.trim()) {
      setFirstName({ ...firstName, status: InputStatus.error, errorText });
      result = false;
    }

    if (!lastName.value || !lastName.value.trim()) {
      setLastName({ ...lastName, status: InputStatus.error, errorText });
      result = false;
    }

    if (!isValidPhoneNumber(phoneNumber.value)) {
      setPhoneNumber({ ...phoneNumber, status: InputStatus.error, errorText });
      result = false;
    }

    if (!EMAIL_REGEX.test(email.value)) {
      setEmail({ ...email, status: InputStatus.error, errorText });
      result = false;
    }

    return result;
  }, [email, firstName, lastName, phoneNumber]);

  const validateSubscriberApartments = useCallback(() => {
    let isError = false;
    const newSubscriberFlats = [...subscriberFlats];
    newSubscriberFlats.forEach((flat) => {
      if (!flat.buildingId || !flat.flatId || !flat.objectId) {
        flat.isError = true;
        isError = true;
      } else {
        delete flat.isError;
      }
    });
    setSubscriberFlats(newSubscriberFlats);
    return isError;
  }, [subscriberFlats]);

  const calculateErrorMessage = (errorCodes: string, message?: string, defaultMessage?: string) => {
    const defaultErrorMessage = defaultMessage || 'Не удалось создать абонента';
    switch (errorCodes) {
      case SubscriberErrorCodes.validation:
        return message || defaultErrorMessage;
      case SubscriberErrorCodes.isMainFlatDeleteValidation:
        return message || defaultErrorMessage;
      case SubscriberErrorCodes.isMainFlatValidation:
        return message || 'У квартиры может быть только один главный абонент';
      default:
        return defaultErrorMessage;
    }
  };

  const resetIsMainInSubscriberErrorFlats = useCallback(
    (errorSubscriberFlatsIds: string[]) => {
      const updatedSubscriberFlats = [...subscriberFlats];

      errorSubscriberFlatsIds.forEach((flatId) => {
        const findFlatIndex = updatedSubscriberFlats.findIndex((flat) => flat.flatId === flatId);
        if (findFlatIndex !== -1) {
          updatedSubscriberFlats[findFlatIndex].isMain = false;
        }
      });

      setSubscriberFlats(updatedSubscriberFlats);
    },
    [subscriberFlats]
  );

  const handleOnClickButton = useCallback(
    async (isSave?: boolean, tabId?: string, isConfirmed = false) => {
      if (validateBaseInfo() && !validateSubscriberApartments()) {
        const newSubscriber: ISubscriber = {
          id: subscriber?.id,
          addressing: shortName.value.trim() ? shortName.value : '',
          firstName: firstName.value,
          lastName: lastName.value,
          middleName: middleName.value.trim() ? middleName.value : '',
          phone: phoneNumber.value.replace('+', ''),
          email: email.value,
          commentary: commentary.value,
          subscriberApartments: subscriberFlats.filter((flat) => flat.flatId) || [],
        };

        if (editMode === SubscriberEditMode.edit) {
          if (newSubscriber.id) {
            if (isSave) {
              onChangeDisableAllTabs(true);
              const resError = await sendRequestEditSubscriber(
                editSubscriberUrl(),
                newSubscriber,
                isConfirmed ? { params: { IsConfirmed: true } } : null
              );
              onChangeDisableAllTabs(false);
              if (!resError?.response?.data) {
                onSave(newSubscriber, tabId);
              } else {
                const error = resError.response.data as IApiError;

                if (error.errorCodes === SubscriberErrorCodes.isMainFlatChangeNotConfirmed) {
                  setMainSubscriberModal(true);
                  setMainFlatChangeNotConfirmedData(error.additionalInformation as IMainFlatChangeNotConfirmed[]);
                } else {
                  setFlatIsMainModal({
                    isOpen: true,
                    title: calculateErrorMessage(error.errorCodes || '', error.message, 'Не удалось обновить абонента'),
                  });

                  if (error.errorCodes === SubscriberErrorCodes.isMainFlatValidation) {
                    setSubscriberFlatsIsMainErrors(error.additionalInformation || []);
                    resetIsMainInSubscriberErrorFlats(error.additionalInformation || []);
                  } else if (error.errorCodes === SubscriberErrorCodes.isStatusValidation) {
                    setSubscriberFlatsIsStatusErrors(error.additionalInformation || []);
                  }
                }

                setWasChange(true);
                return false;
              }
            } else {
              onSave(newSubscriber, tabId);
            }
            return true;
          }
        } else if (editMode === SubscriberEditMode.create) {
          if (isSave) {
            onChangeDisableAllTabs(true);
            const resError = (await sendRequestCreateSubscriber(
              addSubscriberUrl(),
              newSubscriber,
              isConfirmed ? { params: { IsConfirmed: true } } : null
            )) as AxiosError;
            onChangeDisableAllTabs(false);
            if (resError?.response?.data) {
              const error = resError.response.data as IApiError;

              if (error.errorCodes === SubscriberErrorCodes.isMainFlatChangeNotConfirmed) {
                setMainSubscriberModal(true);
                setMainFlatChangeNotConfirmedData(error.additionalInformation as IMainFlatChangeNotConfirmed[]);
              } else {
                setFlatIsMainModal({
                  isOpen: true,
                  title: calculateErrorMessage(error.errorCodes || '', error.message),
                });
                if (error.errorCodes === SubscriberErrorCodes.validation) {
                  setPhoneNumber({ ...phoneNumber, status: InputStatus.error });
                } else if (error.errorCodes === SubscriberErrorCodes.isMainFlatValidation) {
                  setSubscriberFlatsIsMainErrors(error.additionalInformation || []);
                  resetIsMainInSubscriberErrorFlats(error.additionalInformation || []);
                }
              }
              return false;
            }
          } else {
            resetNextTabId();
            setWasChange(false);
          }
          return true;
        }
      } else {
        Message.error({
          content: 'Заполните обязательные поля!',
        });
      }
      return false;
    },
    [
      validateBaseInfo,
      validateSubscriberApartments,
      subscriber?.id,
      shortName.value,
      firstName.value,
      lastName.value,
      middleName.value,
      phoneNumber,
      email.value,
      commentary.value,
      subscriberFlats,
      editMode,
      onChangeDisableAllTabs,
      sendRequestEditSubscriber,
      onSave,
      setWasChange,
      resetIsMainInSubscriberErrorFlats,
      sendRequestCreateSubscriber,
      resetNextTabId,
    ]
  );

  const removeSubscriberFlatIsMainError = useCallback(
    (flatId: string) => {
      setSubscriberFlatsIsMainErrors(subscriberFlatsIsMainErrors.filter((item) => item !== flatId));
    },
    [subscriberFlatsIsMainErrors]
  );

  const removeSubscriberFlatIsStatusError = useCallback(
    (flatId: string) => {
      setSubscriberFlatsIsStatusErrors(subscriberFlatsIsStatusErrors.filter((item) => item !== flatId));
    },
    [subscriberFlatsIsStatusErrors]
  );

  const handleOnClickAddAddressButton = useCallback(() => {
    const updatedSubscriberFlats = [...subscriberFlats];
    const initFlat: ISubscriberApartment = { isMain: false, status: Status.active, sipNumber: '' };
    updatedSubscriberFlats.unshift(initFlat);
    setSubscriberFlats(updatedSubscriberFlats);
    setWasChange(true);
  }, [setWasChange, subscriberFlats]);

  const handleOnChangeAddress = useCallback(
    async (flatIndex: number, updatedFlat: ISubscriberApartment) => {
      const updatedSubscriberFlats = [...subscriberFlats];
      updatedSubscriberFlats[flatIndex] = { ...updatedFlat };
      setSubscriberFlats(updatedSubscriberFlats);
      setWasChange(true);

      removeSubscriberFlatIsStatusError(subscriberFlats[flatIndex].flatId || '');
      removeSubscriberFlatIsMainError(subscriberFlats[flatIndex].flatId || '');
    },
    [subscriberFlats, setWasChange, removeSubscriberFlatIsStatusError, removeSubscriberFlatIsMainError]
  );

  const handleOnDeleteAddress = useCallback(
    (flatIndex: number) => {
      setSubscriberFlats(subscriberFlats.filter((item, index) => index !== flatIndex));
      setWasChange(true);

      removeSubscriberFlatIsMainError(subscriberFlats[flatIndex].flatId || '');
    },
    [setWasChange, subscriberFlats, removeSubscriberFlatIsMainError]
  );

  const tryToDeleteAddress = useCallback(
    (flat: ISubscriberApartment, flatIndex: number) => async () => {
      if (flat.flatId) {
        const resError = (await validateDelete(subscriberValidateMainFlatDeleteUrl(flat.flatId || ''), {
          params: { subscriberId: subscriber?.id },
        })) as AxiosError;
        if (resError?.response?.data) {
          const { errorCodes, message } = resError.response.data as any;
          if (errorCodes === SubscriberErrorCodes.isMainFlatDeleteValidation) {
            setConfirmData(infoConfirmModal(message, closeConfirm));
          } else if (errorCodes === SubscriberErrorCodes.cancelPaidSubscription) {
            setConfirmData(
              simpleConfirmModal(
                'Вы уверены, что хотите удалить последнего абонента из квартиры? В этом случае подписка, оформленная на данную квартиру, будет отменена',
                () => {
                  closeConfirm();
                  handleOnDeleteAddress(flatIndex);
                },
                closeConfirm
              )
            );
          }
        } else {
          handleOnDeleteAddress(flatIndex);
        }
      } else {
        handleOnDeleteAddress(flatIndex);
      }
    },
    [closeConfirm, handleOnDeleteAddress, subscriber?.id, validateDelete]
  );

  const handleOnClickOkModal = useCallback(async () => {
    const result = await handleOnClickButton(true, nextTabId);
    if (result) {
      if (clickedSidebarTab) {
        navigate(clickedSidebarTab);
        dispatch(setClickedSidebarTab(null));
      } else {
        if (
          (nextTabId === ESubscribersTabsIds.equipment &&
            !subscriberFlats.find((flat) => flat.status === Status.active)) ||
          (nextTabId === ESubscribersTabsIds.accessPointLog && !(subscriberFlats.length > 0))
        ) {
          return;
        }

        onNextTab(nextTabId);
      }
    } else {
      dispatch(setClickedSidebarTab(null));
    }
  }, [clickedSidebarTab, dispatch, handleOnClickButton, navigate, nextTabId, onNextTab, subscriberFlats]);

  const handleOnCancelModal = useCallback(async () => {
    if (clickedSidebarTab) {
      setWasChange(false);
      navigate(clickedSidebarTab);
      dispatch(setClickedSidebarTab(null));
    } else {
      await handleOnClickButton(false, nextTabId);
      onNextTab(nextTabId);
    }
  }, [clickedSidebarTab, dispatch, handleOnClickButton, navigate, nextTabId, onNextTab, setWasChange]);

  const handleOnCloseModal = useCallback(() => {
    resetNextTabId();
    dispatch(setClickedSidebarTab(null));
  }, [dispatch, resetNextTabId]);

  const handleOnCloseFlatIsMainModal = useCallback(() => {
    setFlatIsMainModal({ isOpen: false, title: '' });
  }, []);

  const handleOnCloseMainSubscriberModal = useCallback(() => {
    setMainSubscriberModal(false);
    setMainFlatChangeNotConfirmedData([]);
  }, []);

  const handleOnOkMainSubscriberModal = useCallback(() => {
    setMainSubscriberModal(false);
    handleOnClickButton(true, nextTabId, true);
  }, [handleOnClickButton, nextTabId]);

  const getSubscriptionById = useCallback(
    (id: string) => subscriptions?.find((item) => item.flatId === id),
    [subscriptions]
  );

  return (
    <div className="subscribers-base-tab">
      <UniversalModal data={confirmData} onClose={closeConfirm} />
      <NotificationModal
        title="Вы хотите сохранить изменения?"
        okButtonText="Да"
        cancelButtonText="Нет"
        isOpen={(Boolean(nextTabId) || Boolean(clickedSidebarTab)) && wasChange && isActiveTab}
        onOk={handleOnClickOkModal}
        onCancel={handleOnCancelModal}
        onClose={handleOnCloseModal}
      />
      <NotificationModal
        title={flatIsMainModal.title}
        okButtonText="Ок"
        cancelButtonText=""
        isOpen={flatIsMainModal.isOpen}
        onOk={handleOnCloseFlatIsMainModal}
        onCancel={handleOnCloseFlatIsMainModal}
        onClose={handleOnCloseFlatIsMainModal}
      />
      <ChangeMainSubscriberModal
        isOpen={mainSubscriberModal}
        newMainSubscriber={`${lastName.value} ${firstName.value} ${middleName.value} `}
        data={mainFlatChangeNotConfirmedData}
        subscriberFlats={subscriberFlats}
        onCancel={handleOnCloseMainSubscriberModal}
        onChange={handleOnChangeAddress}
        onOk={handleOnOkMainSubscriberModal}
      />
      <div className="subscribers-base-tab__content-block">
        <span className="subscribers-base-tab__title">Персональные данные</span>
        <div className="subscribers-base-tab__content-wrapper">
          <SubscriberData
            editMode={editMode}
            disabled={editSubscriberLoading}
            firstName={{
              value: firstName.value,
              onChange: (value) => handleOnChangeField(setFirstName, { ...firstName, value }),
              status: firstName.status,
              errorText: firstName.errorText,
            }}
            lastName={{
              value: lastName.value,
              onChange: (value) => handleOnChangeField(setLastName, { ...lastName, value }),
              status: lastName.status,
              errorText: lastName.errorText,
            }}
            middleName={{
              value: middleName.value,
              onChange: (value) => handleOnChangeField(setMiddleName, { ...middleName, value }),
            }}
            shortName={{
              value: shortName.value,
              onChange: (value) => handleOnChangeField(setShortName, { ...shortName, value }),
            }}
            phoneNumber={{
              value: phoneNumber.value,
              onChange: (value) => handleOnChangeField(setPhoneNumber, { ...phoneNumber, value }),
              status: phoneNumber.status,
              errorText: phoneNumber.errorText,
            }}
            email={{
              value: email.value,
              onChange: (value) => handleOnChangeField(setEmail, { ...email, value }),
              status: email.status,
              errorText: email.errorText,
            }}
          />
        </div>
        <div className="subscribers-base-tab__approval">
          {subscriber?.hasPushNotificationConsent ? (
            <div className="subscribers-base-tab__approval-item">
              <CheckedIcon />
              Согласие на push-рассылку
            </div>
          ) : (
            <div className="subscribers-base-tab__approval-item">
              <CloseCircleIcon /> Нет согласия на отправку рассылок
            </div>
          )}
          {subscriber?.hasEmailNotificationConsent ? (
            <div className="subscribers-base-tab__approval-item">
              <CheckedIcon /> Согласие на Email-рассылку
            </div>
          ) : (
            <div className="subscribers-base-tab__approval-item">
              <CloseCircleIcon /> Нет согласия на Email-рассылку
            </div>
          )}
          {subscriber?.hasSmsNotificationConsent ? (
            <div className="subscribers-base-tab__approval-item">
              <CheckedIcon /> Согласия на SMS-рассылку
            </div>
          ) : (
            <div className="subscribers-base-tab__approval-item">
              <CloseCircleIcon /> Нет согласия на SMS-рассылку
            </div>
          )}
        </div>
      </div>
      <div className="subscribers-base-tab__content-block">
        <div className="subscribers-base-tab__title-container">
          <span className="subscribers-base-tab__title">Адреса абонента</span>
          {editMode !== SubscriberEditMode.viewing && (
            <div className="subscribers-base-tab__title-buttons">
              <Button
                className="subscribers-base-tab__button"
                onClick={handleOnClickAddAddressButton}
                disabled={editSubscriberLoading || !permission?.edit}
              >
                Добавить адрес
              </Button>
            </div>
          )}
        </div>
        <div className="subscribers-base-tab__address-content">
          {subscriberFlats.length > 0 ? (
            subscriberFlats.map((flat, flatIndex) => (
              <div
                className="subscribers-base-tab__content-wrapper"
                key={`subscriber-object-address-item-${flatIndex}-${flat.flatId}`}
              >
                <SubscriberObjectAddress
                  subscriberId={subscriber?.id}
                  isError={flat.isError}
                  editMode={editSubscriberLoading ? SubscriberEditMode.viewing : editMode}
                  selectObjects={
                    editMode === SubscriberEditMode.viewing ? subscriber?.subscriberObjects || [] : objects?.items || []
                  }
                  deleteLoading={validateDeleteLoading}
                  subscription={getSubscriptionById(flat.flatId || '') || null}
                  flat={flat}
                  allFlats={subscriberFlats}
                  onChangeAddress={(updatedFlat) => handleOnChangeAddress(flatIndex, updatedFlat)}
                  onDeleteAddress={tryToDeleteAddress(flat, flatIndex)}
                  order={flatIndex + 1}
                  isMainSubscriberError={Boolean(subscriberFlatsIsMainErrors.find((flatId) => flatId === flat.flatId))}
                  isStatusError={Boolean(subscriberFlatsIsStatusErrors.find((flatId) => flatId === flat.flatId))}
                />
              </div>
            ))
          ) : (
            <div className="subscribers-base-tab__content-wrapper">
              <span className="subscribers-base-tab__text">Нет добавленных адресов абонента</span>
            </div>
          )}
        </div>
      </div>
      <div className="subscribers-base-tab__content-block">
        <span className="subscribers-base-tab__title">Дополнительно</span>
        <div className="subscribers-base-tab__content-wrapper-grid">
          <Input
            title="Комментарий"
            placeholder="Добавьте комментарий, если это необходимо"
            fieldType={EFieldTypes.textArea}
            disabled={editMode === SubscriberEditMode.viewing || editSubscriberLoading}
            value={commentary.value}
            autoSize={{ minRows: 6, maxRows: 6 }}
            onChange={(value) => handleOnChangeField(setCommentary, { ...commentary, value })}
          />
          {editMode !== SubscriberEditMode.create && (
            <div className="subscribers-base-tab__author-wrapper">
              <span className="subscribers-base-tab__author-field">Автор</span>
              <span className="subscribers-base-tab__author-data">{subscriber?.creator || '-'}</span>
              <span className="subscribers-base-tab__author-field">Дата создания</span>
              <span className="subscribers-base-tab__author-data">
                {subscriber?.createDate ? moment(subscriber?.createDate || '').format(dateDefaultFormat) : '-'}
              </span>
              <span className="subscribers-base-tab__author-field">Автор изменения</span>
              <span className="subscribers-base-tab__author-data">{subscriber?.updater || '-'}</span>
              <span className="subscribers-base-tab__author-field">Дата изменения</span>
              <span className="subscribers-base-tab__author-data">
                {subscriber?.createDate ? moment(subscriber?.updateDate || '').format(dateDefaultFormat) : '-'}
              </span>
              <span className="subscribers-base-tab__author-field">ID</span>
              <span className="subscribers-base-tab__author-data">{subscriber?.id || '-'}</span>
            </div>
          )}
        </div>
      </div>
      {editMode !== SubscriberEditMode.viewing && (
        <TabNavButtons
          containerClassName="subscribers-base-tab__button-container"
          buttons={tabNavButtonsDefault(
            { isHidden: true },
            { isHidden: true },
            {
              disabled: !wasChange,
              callBack: () => handleOnClickButton(true, nextTabId),
              classNameOption: 'subscribers-base-tab__button-next',
              text: nextButtonText,
              loading: subscriptionsLoading || createSubscriberLoading || editSubscriberLoading,
            }
          )}
        />
      )}
    </div>
  );
};

export default SubscribersBaseTab;
