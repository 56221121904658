import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import { getHardwareSettings, setHardwareSettings } from '../../../../../../api/hardwareSettings';
import { getSystem } from '../../../../../../api/systems';
import { getDeviceCategoriesUrl, getHardwareSettingsUrl } from '../../../../../../constants/api';
import { useApi } from '../../../../../../hooks/useApi';
import {
  IHardwareCameraSettings,
  IHardwareParam,
  IHardwareSettings,
  IHardwareShortParam,
} from '../../../../../../typings/systems/hardwareSettings';
import { IAccessPointDeviceCategory } from '../../../../../../typings/treeNode';
import Message from '../../../../../message';
import TabNavButtons from '../../../../../tabs/tabNavButtons';
import { tabNavButtonsDefault } from '../../../../../tabs/tabNavButtons/utils';
import { InputStatus } from '../../../../../ui/input/types';
import Loader from '../../../../../ui/loader';
import { ELoaderColor } from '../../../../../ui/loader/types';
import UniversalModal from '../../../../../ui/universalModal';
import { IConfirmData } from '../../../../../ui/universalModal/types';
import SettingsSection from '../advancedOptions';
import { IHardwarePage, IHardwareOptionValue } from '../../../types';
import SevenThousandOptions from './sevenThousandOptions';
import { defaultConfirm, saveChangesModal } from '../../../../../ui/universalModal/config';
import { ESystemTabErrorCodes } from '../../../../../../api/systems/types';
import { ButtonType } from '../../../../../ui/button/types';
import { useAppDispatch, useAppSelector } from '../../../../../../hooks/hooks';
import { getClickedSidebarTab } from '../../../../../../store/selectors/sidebar';
import { setClickedSidebarTab } from '../../../../../../store/slices/sidebar';
import Tabs from '../../../../../tabs';
import {
  findHardwareInInvalidList,
  replacedOptionValues,
  setInvalidParams,
  updateOptionValues,
  validateCameraAdvancedOptions,
  validateDeviceAdvancedOptions,
  validateOption,
} from '../../../calculations';
import CameraSection from '../../camera/cameraSection';
import { ESevenThousandSettingsTab } from './types';
import {
  defaultCameraOptions,
  defaultRequiredValue,
  defaultSevenThousandOptions,
  ICameraOptionsData,
  ISevenThousandOptionsData,
} from '../../../config';
import { checkIsAdmin } from '../../../../../../store/selectors/profile';

const SevenThousandDevice: FC<IHardwarePage> = (props) => {
  const {
    systemId,
    onNextTab = () => {},
    tabId = '',
    activeTabKey = '',
    updateAvailableTabs = () => {},
    isWasChange = false,
    setChange = () => {},
    repeatChangeTab = () => {},
    chancelChangeTab = () => {},
    clickedTab = '',
    nextTabIsAvailable,
    permissions = {},
    getSystemData = () => {},
    selectedNode,
    clickedNode,
    repeatSelectNode = () => {},
    activeInvalidHardware,
    invalidHardwareList = [],
    updateAfterSave = () => {},
  } = props;

  const isAdmin = useAppSelector(checkIsAdmin);

  const {
    data: settings,
    sendRequest: requestSettings,
    loading: settingsLoading,
  } = useApi<IHardwareSettings>(getHardwareSettings);

  const { sendRequest: updateSettings } = useApi(setHardwareSettings);
  const { data: deviceCategories, sendRequest: requestDeviceCategories } =
    useApi<IAccessPointDeviceCategory[]>(getSystem);

  const [activeTab, setActiveTab] = useState<string>(ESevenThousandSettingsTab.main);

  const [settingsCopy, setSettingsCopy] = useState<IHardwareSettings | null>(null);
  const [cameraSettingsCopy, setCameraSettingsCopy] = useState<IHardwareCameraSettings | null>(null);

  const [wasSelected, setSelected] = useState<boolean>(false);
  const [isCameraOptionsChange, setCameraOptionsChange] = useState<boolean>(false);
  const [isDeviceOptionsChange, setDeviceOptionsChange] = useState<boolean>(false);

  const [isIpCamAvailable, setIsIpCamAvailable] = useState<boolean>(true);

  const [confirmData, setConfirmData] = useState<IConfirmData>(defaultConfirm);

  const [sevenThousandOptions, setSevenThousandOptionsOptions] = useState<ISevenThousandOptionsData>({
    ...defaultSevenThousandOptions,
  });
  const [cameraOptions, setCameraOptions] = useState<ICameraOptionsData>({ ...defaultCameraOptions });

  const navigate = useNavigate();

  const clickedSidebarTab = useAppSelector(getClickedSidebarTab);

  const dispatch = useAppDispatch();

  const changeSettings = useCallback(
    (data: any, setter: (data: any) => void, wasChange = true) => {
      if (!isWasChange && wasChange) {
        setChange(true);
      }
      setter({ ...data });
    },
    [isWasChange, setChange]
  );

  const updateOptions = useCallback(
    (
        optionWasChange: boolean,
        currentSettings: any,
        settingsSetter: (data: any) => void,
        setOptionsChange: (flag: boolean) => void,
        optionsSetter: (data: any) => void
      ) =>
      (values: IHardwareOptionValue[], optionsValue: any, wasChange = true) => {
        const [newOptionsCopy, newSettingsCopy] = updateOptionValues(values, optionsValue, currentSettings);
        if (values.find((item) => item.fieldName === 'deviceCategory')) {
          setIsIpCamAvailable(false);
          newOptionsCopy.selectedDeviceTypeId = { ...defaultRequiredValue };
        }
        if (!optionWasChange && wasChange) {
          setOptionsChange(true);
        }
        changeSettings(newSettingsCopy, settingsSetter, wasChange);
        optionsSetter(newOptionsCopy);
      },
    [changeSettings]
  );

  const updateSevenThousandOptions = useCallback(
    (values: IHardwareOptionValue[], wasChange = true, optionsValue = sevenThousandOptions) =>
      updateOptions(
        isDeviceOptionsChange,
        settingsCopy,
        setSettingsCopy,
        setDeviceOptionsChange,
        setSevenThousandOptionsOptions
      )(values, optionsValue, wasChange),
    [isDeviceOptionsChange, sevenThousandOptions, settingsCopy, updateOptions]
  );

  const onChangeSevenThousandOptions = useCallback(
    (value: any, fieldName: string) => updateSevenThousandOptions([{ value, fieldName }]),
    [updateSevenThousandOptions]
  );

  const updateCameraOptions = useCallback(
    (values: IHardwareOptionValue[], wasChange = true, optionsValue = cameraOptions) =>
      updateOptions(
        isCameraOptionsChange,
        cameraSettingsCopy,
        setCameraSettingsCopy,
        setCameraOptionsChange,
        setCameraOptions
      )(values, optionsValue, wasChange),
    [cameraOptions, cameraSettingsCopy, isCameraOptionsChange, setCameraOptions, setCameraSettingsCopy, updateOptions]
  );

  const onChangeCameraOptions = useCallback(
    (value: any, fieldName: string) => updateCameraOptions([{ value, fieldName }]),
    [updateCameraOptions]
  );

  const resetChanges = useCallback(() => {
    if (isWasChange) {
      setChange(false);
    }
    if (isDeviceOptionsChange) {
      setDeviceOptionsChange(false);
    }
    if (wasSelected) {
      setSelected(false);
    }
    if (isCameraOptionsChange) {
      setCameraOptionsChange(false);
    }
  }, [isCameraOptionsChange, isDeviceOptionsChange, isWasChange, setChange, wasSelected]);

  useEffect(() => {
    if (tabId === activeTabKey) {
      setSettingsCopy(null);
      setCameraSettingsCopy(null);
      resetChanges();
    }
  }, [activeTabKey]);

  const getInvalidData = useCallback(
    (id: string) =>
      activeInvalidHardware && id === activeInvalidHardware.id
        ? activeInvalidHardware
        : invalidHardwareList?.length
        ? findHardwareInInvalidList(invalidHardwareList, id)
        : 0,
    [activeInvalidHardware, invalidHardwareList]
  );

  const parseSettings = useCallback(() => {
    const newSettings = JSON.parse(JSON.stringify(settings));
    if (newSettings) {
      const newMainOptions = { ...sevenThousandOptions };
      replacedOptionValues(isDeviceOptionsChange, newSettings, newMainOptions);
      setIsIpCamAvailable(newSettings.isIpCamAvailable);
      const invalidData = getInvalidData(newSettings.id);

      if (invalidData) {
        if (invalidData.invalidParams && invalidData.invalidParams.length > 0) {
          invalidData.invalidParams.forEach((id: any) => {
            const param = newSettings.accessPointTypeParams?.find((item: IHardwareParam) => item.id === id);
            if (param) {
              param.isError = true;
            }
          });
        }
        setInvalidParams(invalidData, newMainOptions);
      }
      setSevenThousandOptionsOptions(newMainOptions);
      setSettingsCopy(newSettings);
    }
  }, [getInvalidData, isDeviceOptionsChange, sevenThousandOptions, settings]);

  useEffect(() => {
    parseSettings();
  }, [settings]);

  useEffect(() => {
    if (deviceCategories && deviceCategories.length) {
      if (sevenThousandOptions && !sevenThousandOptions.deviceCategory.value) {
        const value = deviceCategories[0];
        sevenThousandOptions.deviceCategory.value = value;
      }
    }
  }, [deviceCategories]);

  const requestData = useCallback(
    async (
      withoutParams = false,
      id = selectedNode?.itemId,
      selectedDeviceTypeId = sevenThousandOptions.selectedDeviceTypeId.value,
      selectedDeviceRevisionId = sevenThousandOptions.selectedDeviceRevisionId.value,
      selectedDeviceVersionId = sevenThousandOptions.selectedDeviceVersionId.value
    ) => {
      if (id) {
        const newMainOptions =
          isDeviceOptionsChange || !withoutParams ? { ...sevenThousandOptions } : { ...defaultSevenThousandOptions };
        updateSevenThousandOptions(
          [
            {
              value: selectedDeviceTypeId,
              fieldName: 'selectedDeviceTypeId',
            },
            {
              value: selectedDeviceRevisionId,
              fieldName: 'selectedDeviceRevisionId',
            },
            {
              value: selectedDeviceVersionId,
              fieldName: 'selectedDeviceVersionId',
            },
            {
              value: false,
              fieldName: 'isUpdateAvailable',
            },
          ],
          false,
          newMainOptions
        );
        if (withoutParams) {
          await requestSettings(getHardwareSettingsUrl(id));
        } else if (selectedDeviceTypeId && selectedDeviceRevisionId && selectedDeviceVersionId) {
          await requestSettings(getHardwareSettingsUrl(id), {
            params: { selectedDeviceTypeId, selectedDeviceRevisionId, selectedDeviceVersionId },
          });
        } else {
          setIsIpCamAvailable(false);
        }
      }
    },
    [isDeviceOptionsChange, sevenThousandOptions, requestSettings, selectedNode?.itemId, updateSevenThousandOptions]
  );

  const handleOnChangeDeviceType = useCallback(
    async (
      selectedDeviceTypeId: string | null,
      selectedDeviceRevisionId: string | null,
      selectedDeviceVersionId: string | null
    ) => {
      if (selectedNode) {
        setSelected(true);
        setChange(true);
        await requestData(
          false,
          selectedNode.itemId,
          selectedDeviceTypeId,
          selectedDeviceRevisionId,
          selectedDeviceVersionId
        );
      }
    },
    [requestData, selectedNode, setChange]
  );

  const checkValidAdvancedOptions = useCallback(() => {
    let isValid = true;
    if (settingsCopy) {
      isValid = validateDeviceAdvancedOptions(settingsCopy);
      setSettingsCopy({ ...settingsCopy });
    }
    return isValid;
  }, [settingsCopy]);

  const checkValidCameraAdvancedOptions = useCallback(() => {
    let isValid = true;
    if (cameraSettingsCopy) {
      isValid = validateCameraAdvancedOptions(cameraSettingsCopy);
      setCameraSettingsCopy({ ...cameraSettingsCopy });
    }
    return isValid;
  }, [cameraSettingsCopy]);

  const validateInputs = useCallback(
    (mOptions: ISevenThousandOptionsData, cOptions = cameraOptions) => {
      let mainOptionsValid = true;

      const newMainOptions = { ...mOptions };
      Object.keys(newMainOptions).forEach((name) => {
        mainOptionsValid = validateOption(name, newMainOptions) && mainOptionsValid;
        if (!mainOptionsValid) setActiveTab(ESevenThousandSettingsTab.main);
      });
      setSevenThousandOptionsOptions(newMainOptions);

      let camOptionsValid = true;
      if (isIpCamAvailable) {
        const newCamOptions = { ...cOptions };
        Object.keys(newCamOptions).forEach((name) => {
          camOptionsValid = validateOption(name, newCamOptions) && camOptionsValid;
          if (!camOptionsValid) setActiveTab(ESevenThousandSettingsTab.camera);
        });
        setCameraOptions(newCamOptions);
      }

      return checkValidAdvancedOptions() && checkValidCameraAdvancedOptions() && mainOptionsValid && camOptionsValid;
    },
    [cameraOptions, checkValidAdvancedOptions, checkValidCameraAdvancedOptions, isIpCamAvailable]
  );

  const handleOnSave = useCallback(
    async (options: ISevenThousandOptionsData = sevenThousandOptions) => {
      if (validateInputs(options) && settingsCopy && selectedNode) {
        const params: IHardwareShortParam[] =
          settingsCopy.accessPointTypeParams?.map(({ id, value }) => ({
            paramId: id || '',
            value,
          })) || [];
        const data = {
          ...settingsCopy,
          cameraSetting: cameraSettingsCopy,
          selectedDeviceTypeId: options.selectedDeviceTypeId.value,
          AccessPointTypeValues: params,
          isUpdateAvailable: !!settingsCopy.isUpdateAvailable,
        };
        const resError = await updateSettings(getHardwareSettingsUrl(selectedNode.itemId), data);
        if (resError?.response?.data) {
          const id = resError?.response?.data?.additionalInformation?.paramId;
          const name = resError?.response?.data?.additionalInformation?.paramName || '';

          if (resError.response.data.errorCodes === ESystemTabErrorCodes.CameraMacDuplicate) {
            const newOptionsCopy = { ...cameraOptions };
            newOptionsCopy.mac = {
              ...newOptionsCopy.mac,
              errorText: resError.response.data.message || 'Не уникальный мак адрес',
              status: InputStatus.error,
            };
            setCameraOptions(newOptionsCopy);
          } else if (resError?.response?.data?.errorCodes === ESystemTabErrorCodes.CameraAlreadyConnected) {
            Message.error({
              content: 'Выберите версию с камерой или отвяжите камеру у текущего оборудования',
            });
          } else if (
            resError?.response?.data?.errorCodes === ESystemTabErrorCodes.SerialNumber ||
            resError?.response?.data?.errorCodes === ESystemTabErrorCodes.SerialNumberFormatValidation ||
            resError?.response?.data?.additionalInformation === ESystemTabErrorCodes.SerialNumber
          ) {
            setSevenThousandOptionsOptions({
              ...sevenThousandOptions,
              serialNumber: {
                ...sevenThousandOptions.serialNumber,
                errorText: resError?.response?.data?.message,
                status: InputStatus.error,
              },
            });
          } else if (resError?.response?.data?.errorCodes === ESystemTabErrorCodes.CameraIsConnected) {
            Message.error({
              content: resError.response.data.message,
            });
          }

          if (id) {
            const field = settingsCopy?.accessPointTypeParams?.find((item) => item.id === id);
            if (field) {
              field.isError = true;
            }
          }
          if (name) {
            const camelCaseName = name.replace(name[0], name[0].toLowerCase());
            if (sevenThousandOptions[camelCaseName as keyof ISevenThousandOptionsData]) {
              setSevenThousandOptionsOptions({
                ...sevenThousandOptions,
                [camelCaseName]: {
                  ...sevenThousandOptions[camelCaseName as keyof ISevenThousandOptionsData],
                  errorText: resError?.response?.data?.message || 'Поле заполнено не верно',
                  status: InputStatus.error,
                },
              });
            }
          }
        } else {
          await updateAfterSave(settingsCopy?.id || '');
          resetChanges();
          updateAvailableTabs();
          getSystemData();
          return true;
        }
      } else {
        Message.error({
          content: 'Проверьте правильность заполнения полей',
        });
      }

      return false;
    },
    [
      sevenThousandOptions,
      validateInputs,
      settingsCopy,
      selectedNode,
      cameraSettingsCopy,
      updateSettings,
      cameraOptions,
      updateAfterSave,
      resetChanges,
      updateAvailableTabs,
      getSystemData,
    ]
  );

  const handleOnCancel = useCallback(async () => {
    resetChanges();
    await requestData(true);
    await requestDeviceCategories(getDeviceCategoriesUrl());
  }, [requestData, requestDeviceCategories, resetChanges]);

  const handleOnSaveChanges = useCallback(
    (callback: () => void) => async () => {
      if (await handleOnSave(sevenThousandOptions)) {
        callback();
      } else {
        chancelChangeTab();
      }
      setConfirmData(defaultConfirm);
    },
    [chancelChangeTab, handleOnSave, sevenThousandOptions]
  );

  const handleOnResetChanges = useCallback(
    (callback: () => void) => async () => {
      resetChanges();
      setConfirmData(defaultConfirm);
      callback();
    },
    [resetChanges]
  );

  const closeConfirm = useCallback(() => {
    setConfirmData(defaultConfirm);
    if (clickedTab) {
      chancelChangeTab();
    }
  }, [clickedTab, chancelChangeTab]);

  const tryToSave = useCallback(() => {
    if (!sevenThousandOptions.isUpdateAvailable.value) {
      setConfirmData({
        isOpen: true,
        description: 'Оборудование не будет обновляться',
        buttons: [
          {
            label: 'Ок',
            type: ButtonType.primary,
            onClick: () => {
              closeConfirm();
              handleOnSave();
            },
          },
          {
            label: 'Отмена',
            type: ButtonType.secondary,
            onClick: closeConfirm,
          },
        ],
      });
    } else {
      handleOnSave();
    }
  }, [closeConfirm, handleOnSave, sevenThousandOptions.isUpdateAvailable.value]);

  const checkChanges = useCallback(
    async (callback: () => void) => {
      if (isWasChange || isDeviceOptionsChange) {
        setConfirmData(saveChangesModal(handleOnSaveChanges(callback), handleOnResetChanges(callback)));
      } else {
        callback();
      }
    },
    [handleOnResetChanges, handleOnSaveChanges, isDeviceOptionsChange, isWasChange]
  );

  useEffect(() => {
    if (clickedSidebarTab) {
      checkChanges(() => {
        setChange(false);
        navigate(clickedSidebarTab);
      });
      dispatch(setClickedSidebarTab(null));
    }
  }, [clickedSidebarTab]);

  useEffect(() => {
    if (clickedNode) {
      checkChanges(() => repeatSelectNode());
    }
  }, [clickedNode]);

  useEffect(() => {
    if (clickedTab && activeTabKey === tabId) {
      checkChanges(() => repeatChangeTab());
    }
  }, [clickedTab]);

  const isSelectAllOptions = useMemo(
    () =>
      sevenThousandOptions.selectedDeviceTypeId.value &&
      sevenThousandOptions.selectedDeviceRevisionId.value &&
      sevenThousandOptions.selectedDeviceVersionId.value,
    [
      sevenThousandOptions.selectedDeviceRevisionId.value,
      sevenThousandOptions.selectedDeviceTypeId.value,
      sevenThousandOptions.selectedDeviceVersionId.value,
    ]
  );

  useEffect(() => {
    const content: any = document.getElementsByClassName('hardware-settings__content-wrapper');
    if (content[0]) {
      content[0].childNodes[1].scrollTo(0, 0);
    }
  }, [activeTab]);

  const selectedNodeWasChanges = useCallback(async () => {
    if (selectedNode) {
      setActiveTab(ESevenThousandSettingsTab.main);
      resetChanges();
      await requestData(true, selectedNode.itemId);
      await requestDeviceCategories(getDeviceCategoriesUrl());
    }
  }, [requestData, requestDeviceCategories, resetChanges, selectedNode]);

  useEffect(() => {
    selectedNodeWasChanges();
  }, [selectedNode]);

  const renderMainOptions = useMemo(
    () => (
      <div className="hardware-settings__content">
        <SevenThousandOptions
          systemId={systemId}
          isAdmin={isAdmin}
          requestData={() => requestData(true)}
          settings={settingsCopy}
          options={sevenThousandOptions}
          onChange={onChangeSevenThousandOptions}
          permissions={permissions}
          deviceCategories={deviceCategories}
          handleOnChangeDeviceType={handleOnChangeDeviceType}
          changeSettings={(data) => changeSettings(data, setSettingsCopy)}
          selectedNode={selectedNode}
          updateOptions={updateSevenThousandOptions}
          isSelectAllOptions={isSelectAllOptions}
        />
        {settingsCopy?.accessPointTypeParams && !!settingsCopy.accessPointTypeParams.length && isSelectAllOptions && (
          <SettingsSection
            permissions={permissions}
            wasSelected={wasSelected}
            settings={settingsCopy}
            changeSettings={(data) => changeSettings(data, setSettingsCopy)}
          />
        )}
      </div>
    ),
    [
      changeSettings,
      deviceCategories,
      handleOnChangeDeviceType,
      isAdmin,
      isSelectAllOptions,
      onChangeSevenThousandOptions,
      permissions,
      requestData,
      selectedNode,
      settingsCopy,
      sevenThousandOptions,
      systemId,
      updateSevenThousandOptions,
      wasSelected,
    ]
  );

  return (
    <>
      <UniversalModal data={confirmData} onClose={closeConfirm} />
      {settingsLoading ? (
        <div className="hardware-settings__content hardware-settings__content_empty">
          <div className="hardware-settings__loader-container">
            <Loader color={ELoaderColor.blue} />
          </div>
        </div>
      ) : (
        <div className="hardware-settings__scroll default-scrollbar-override">
          <div className="hardware-settings__title">Настройки оборудования</div>
          {isIpCamAvailable ? (
            <Tabs
              activeTabKey={activeTab}
              onChangeActiveTab={setActiveTab}
              tabsClassName="hardware-settings__tabs"
              tabs={[
                {
                  id: ESevenThousandSettingsTab.main,
                  title: 'Основные настройки',
                  position: 0,
                  children: renderMainOptions,
                },
                {
                  id: ESevenThousandSettingsTab.camera,
                  title: 'Настройки камеры',
                  position: 1,
                  children: (
                    <div className="hardware-settings__content">
                      <CameraSection
                        isCameraOptionsChange={isCameraOptionsChange}
                        permissions={permissions}
                        selectedNode={selectedNode}
                        cameraSettings={settings?.cameraSetting}
                        handleOnSave={handleOnSave}
                        closeConfirm={closeConfirm}
                        requestData={requestData}
                        getInvalidData={getInvalidData}
                        onChangeCameraOptions={onChangeCameraOptions}
                        changeSettings={(data, wasChange) => changeSettings(data, setCameraSettingsCopy, wasChange)}
                        cameraSettingsCopy={cameraSettingsCopy}
                        setConfirmData={setConfirmData}
                        cameraOptions={cameraOptions}
                        setCameraOptions={setCameraOptions}
                      />
                    </div>
                  ),
                },
              ]}
            />
          ) : (
            renderMainOptions
          )}
        </div>
      )}
      <TabNavButtons
        buttons={tabNavButtonsDefault(
          { callBack: handleOnCancel, isHidden: (!isDeviceOptionsChange && !isWasChange) || !permissions.edit },
          {
            callBack: tryToSave,
            disabled: !isWasChange && !isDeviceOptionsChange,
            isHidden: !permissions.edit,
          },
          { callBack: onNextTab, disabled: !nextTabIsAvailable, isHidden: !permissions.edit }
        )}
      />
    </>
  );
};

export default SevenThousandDevice;
