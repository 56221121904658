import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { IContactInput, IContactsSection } from './types';
import { useApi } from '../../../hooks/useApi';
import { IBuildingContact, IOrganizationObject } from '../../../typings/organization';
import { getRequest, postRequest, putRequest } from '../../../api';
import {
  companyContactUpdateUrl,
  getObjectListUrl,
  getSubscriberBuildingsUrl,
  organizationUpdateContactsUrl,
} from '../../../constants/api';
import { ICustomTab } from '../../tabsCustom/types';
import ContactsForSubscribers from './contactsForSubscribers';
import InputSearch from '../../ui/inputSearch';
import TabsCustom from '../../tabsCustom';
import TabNavButtons from '../../tabs/tabNavButtons';
import { tabNavButtonsDefault } from '../../tabs/tabNavButtons/utils';
import { IConfirmData } from '../../ui/universalModal/types';
import { defaultConfirm, deleteModal, saveChangesModal } from '../../ui/universalModal/config';
import UniversalModal from '../../ui/universalModal';

import { IInputValue, InputStatus, defaultNotRequiredValue, defaultRequiredValue } from '../../ui/input/types';
import Message from '../../message';
import { ISubscriberBuilding } from '../../../typings/subscribers';
import { IApiResponse } from '../../../typings/api';
import { getEntitiesForSubscriber } from '../../../api/subscribers';
import { ButtonType } from '../../ui/button/types';
import Input from '../../ui/input';
import MaskInput from '../../ui/input/maskInput';
import { useAppDispatch, useAppSelector } from '../../../hooks/hooks';
import { getWasChange } from '../../../store/selectors/changes';
import { setChange } from '../../../store/slices/changes';
import { getClickedSidebarTab } from '../../../store/selectors/sidebar';
import { setClickedSidebarTab } from '../../../store/slices/sidebar';

const ContactsSection: FC<IContactsSection> = (props) => {
  const { company, permissions = {} } = props;

  const { data: objects, sendRequest: getObjects, loading: objectsLoading } = useApi<IOrganizationObject[]>(getRequest);
  const { sendRequest: sendContacts, loading: sendContactsLoading } = useApi(putRequest);
  const { sendRequest: updateContactsData, loading: updateContactsDataLoading } = useApi(postRequest);
  const { data: buildings, sendRequest: getBuildings } =
    useApi<IApiResponse<ISubscriberBuilding>>(getEntitiesForSubscriber);

  const [searchValue, setSearchValue] = useState<string>('');
  const [activeTabId, setActiveTabId] = useState<string>('');

  const [addressForSubscriber, setAddressForSubscriber] = useState('');
  const [phoneForSubscriber, setPhoneForSubscriber] = useState('');
  const [scheduleForSubscriber, setScheduleForSubscriber] = useState('');
  const [phoneForSubscriberError, setPhoneForSubscriberError] = useState('');

  const [contacts, setContacts] = useState<IContactInput[]>([]);

  const [confirmData, setConfirmData] = useState<IConfirmData>(defaultConfirm);

  const closeConfirm = useCallback(() => setConfirmData(defaultConfirm), []);

  const [searchParams, setSearchParams] = useSearchParams();

  const navigate = useNavigate();

  const clickedSidebarTab = useAppSelector(getClickedSidebarTab);

  const dispatch = useAppDispatch();

  const wasChange = useAppSelector(getWasChange);

  const setWasChange = useCallback(
    (value: boolean) => {
      dispatch(setChange(value));
    },
    [dispatch]
  );

  useEffect(() => {
    if (phoneForSubscriberError) {
      setPhoneForSubscriberError('');
    }
  }, [phoneForSubscriber]);

  const setActiveObject = useCallback(
    (id: string) => {
      setActiveTabId(id);
      searchParams.set('systemId', id);
      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams]
  );

  const requestObjects = useCallback(
    async (search = searchValue, companyId = company?.id) => {
      await getObjects(getObjectListUrl(), { params: { search, companyId } });
    },
    [company, getObjects, searchValue]
  );

  useEffect(() => {
    if (company?.id) {
      requestObjects('', company.id);
    }
  }, [company]);

  const setCompanyContacts = useCallback(() => {
    setAddressForSubscriber(company?.addressForSubscriber || '');
    setPhoneForSubscriber(company?.phoneForSubscriber || '');
    setScheduleForSubscriber(company?.scheduleForSubscriber || '');
  }, [company]);

  useEffect(() => {
    setCompanyContacts();
  }, [company]);

  const updateContacts = useCallback((data: IContactInput[], change = true) => {
    setContacts(data);
    setWasChange(change);
  }, []);

  const getContact = useCallback(
    (isNew = false, item: IBuildingContact | null = null): IContactInput => ({
      buildingId: { ...defaultRequiredValue, value: item?.buildingId || '' },
      objectId: activeTabId,
      address: { ...defaultNotRequiredValue, value: item?.address || '' },
      schedule: { ...defaultNotRequiredValue, value: item?.schedule || '' },
      phone: { ...defaultRequiredValue, value: item?.phone || '' },
      isNew,
    }),
    [activeTabId]
  );

  const setInitialContacts = useCallback(
    (tab = activeTabId) => {
      if (tab) {
        const activeObject = objects?.find((item) => item.id === tab);
        if (activeObject) {
          getBuildings(getSubscriberBuildingsUrl(), {
            params: {
              sort: [{ name: 'address' }],
              search: '',
              page: 0,
              count: 0,
              objectId: tab,
            },
          });
          return updateContacts(
            activeObject.buildingContacts.map((item) => getContact(false, item)),
            false
          );
        }
        updateContacts([], false);
        setCompanyContacts();
      }
    },
    [activeTabId, getBuildings, getContact, objects, setCompanyContacts, updateContacts]
  );

  useEffect(() => {
    let tab = activeTabId;
    if (!tab) {
      tab = searchParams.get('systemId') || '';
    }
    if (objects?.length && (!tab || !objects.find((item) => item.id === activeTabId))) {
      tab = objects[0].id;
    }
    setActiveObject(tab);
    setInitialContacts(tab);
  }, [objects, activeTabId]);

  const validateInfo = useCallback((): boolean => {
    let isError = false;

    const newContacts = [...contacts];

    newContacts.forEach((item) => {
      Object.keys(item).forEach((name) => {
        const field = item[name as keyof IContactInput] as IInputValue;
        if (field.isRequired) {
          if (name === 'phone' && !(isValidPhoneNumber(field.value) || isValidPhoneNumber(`+${field.value}`))) {
            isError = true;
            field.errorText = 'Поле заполнено некорректно';
            field.status = InputStatus.error;
          } else if (!field.value || !field.value.trim()) {
            isError = true;
            field.errorText = 'Поле обязательно для заполнения';
            field.status = InputStatus.error;
          }
        }
      });
    });

    if (
      phoneForSubscriber &&
      !(isValidPhoneNumber(phoneForSubscriber) || isValidPhoneNumber(`+${phoneForSubscriber}`))
    ) {
      setPhoneForSubscriberError('Поле заполнено некорректно');
      isError = true;
    }

    setContacts(newContacts);

    return isError;
  }, [contacts, phoneForSubscriber]);

  const saveContacts = useCallback(async (): Promise<boolean> => {
    const saveData: IBuildingContact[] = contacts.map((item) => ({
      buildingId: item.buildingId.value,
      objectId: item.objectId,
      address: item.address.value.trim() ? item.address.value : '',
      schedule: item.schedule.value.trim() ? item.schedule.value : '',
      phone: item.phone.value,
    }));

    const address = addressForSubscriber.trim() ? addressForSubscriber : '';
    const schedule = scheduleForSubscriber.trim() ? scheduleForSubscriber : '';

    setScheduleForSubscriber(schedule);
    setAddressForSubscriber(address);

    await updateContactsData(companyContactUpdateUrl(), {
      ...company,
      addressForSubscriber: address,
      scheduleForSubscriber: schedule,
      phoneForSubscriber: phoneForSubscriber === '+7 (___) ___ __ __' ? '' : phoneForSubscriber,
      companyId: company?.id,
    });
    const resError = await sendContacts(organizationUpdateContactsUrl(activeTabId), saveData);
    if (resError?.response?.data) {
      Message.error({
        content: 'Не удалось добавить контакты',
      });
    } else {
      setWasChange(false);
      Message.success({
        content: 'Контакты успешно добавлены',
      });
      requestObjects();
      return true;
    }
    return false;
  }, [
    activeTabId,
    addressForSubscriber,
    company,
    contacts,
    phoneForSubscriber,
    requestObjects,
    scheduleForSubscriber,
    sendContacts,
    setWasChange,
    updateContactsData,
  ]);

  const tryToSaveContacts = useCallback(
    async (callBack = () => {}) => {
      if (!validateInfo()) {
        if ((buildings?.items.length || 0) > contacts.length) {
          setConfirmData({
            isOpen: true,
            description: 'Вы добавили контакты не для всех строений объекта',
            buttons: [
              {
                label: 'Все равно сохранить',
                type: ButtonType.primary,
                onClick: async () => {
                  closeConfirm();
                  if (await saveContacts()) {
                    callBack();
                  }
                },
              },
              {
                label: 'Добавить',
                type: ButtonType.secondary,
                onClick: closeConfirm,
              },
            ],
          });
        } else if (await saveContacts()) {
          callBack();
        }
      } else {
        Message.error({
          content: 'Проверьте правильность заполнения полей',
        });
      }
    },
    [buildings?.items.length, closeConfirm, contacts.length, saveContacts, validateInfo]
  );

  const checkChanges = useCallback(
    (callBack: () => void) => {
      if (wasChange) {
        setConfirmData(
          saveChangesModal(
            async () => {
              closeConfirm();
              tryToSaveContacts(callBack);
            },
            () => {
              closeConfirm();
              callBack();
            }
          )
        );
      } else {
        callBack();
      }
    },
    [closeConfirm, tryToSaveContacts, wasChange]
  );

  useEffect(() => {
    if (clickedSidebarTab) {
      checkChanges(() => {
        setWasChange(false);
        navigate(clickedSidebarTab);
      });
      dispatch(setClickedSidebarTab(null));
    }
  }, [clickedSidebarTab]);

  const handleOnChangeActiveFlatId = useCallback(
    (value: string) => checkChanges(() => setActiveObject(value)),
    [checkChanges, setActiveObject]
  );

  const handleOnSearch = useCallback(
    (value: string) =>
      checkChanges(async () => {
        setSearchValue(value);
        await requestObjects(value);
      }),
    [checkChanges, requestObjects]
  );

  const onDeleteContact = useCallback(
    (index: number) => {
      updateContacts(contacts.filter((_, i) => index !== i));
    },
    [contacts, updateContacts]
  );

  const tryToDeleteContact = useCallback(
    (index: number) => () =>
      contacts[index].isNew
        ? onDeleteContact(index)
        : setConfirmData(
            deleteModal(
              'этот контакт',
              () => {
                closeConfirm();
                onDeleteContact(index);
              },
              closeConfirm
            )
          ),
    [closeConfirm, contacts, onDeleteContact]
  );

  const tabs = useMemo(
    () =>
      objects?.map<ICustomTab>((object) => ({
        tabId: object.id,
        data: [
          { fieldTitle: 'Объект', fieldData: object.objectName },
          { fieldTitle: 'Адрес', fieldData: object.address },
        ],
        children: null,
      })),
    [objects]
  );

  const onChange = useCallback(
    (setter: (val: string) => void) => (e: string) => {
      setter(e);
      if (!wasChange) {
        setWasChange(true);
      }
    },
    [wasChange]
  );

  const onBlur = useCallback(() => {
    if (phoneForSubscriber === '+7 (___) ___ __ __') {
      setPhoneForSubscriber('');
    }
  }, [phoneForSubscriber]);

  return (
    <div className="contacts-section">
      <UniversalModal data={confirmData} onClose={closeConfirm} />

      <div className="contacts-section__title">Контакты организации для абонента</div>
      <div className="contacts-section__notify">Контактные данные будут отображены в МП абонента</div>

      <div className="contacts-section__contacts">
        <Input
          title="Адрес"
          value={addressForSubscriber}
          onChange={onChange(setAddressForSubscriber)}
          placeholder="Адрес"
          maxLength={100}
          disabled={!permissions.edit}
        />
        <Input
          title="График работы"
          value={scheduleForSubscriber}
          onChange={onChange(setScheduleForSubscriber)}
          placeholder="График работы"
          maxLength={100}
          disabled={!permissions.edit}
        />
        <MaskInput
          onBlur={onBlur}
          onChange={onChange(setPhoneForSubscriber)}
          title="Номер телефона"
          placeholder="+7 (123) 456 78 89"
          value={phoneForSubscriber}
          disabled={!permissions.edit}
          errorText={phoneForSubscriberError}
          status={phoneForSubscriberError ? InputStatus.error : InputStatus.normal}
        />
      </div>

      <div className="contacts-section__title">Контакты для абонента</div>

      <InputSearch placeholder="Поиск по адресу и наименованию объекта" value={searchValue} onSearch={handleOnSearch} />

      <TabsCustom
        activeTabKey={activeTabId}
        onChangeActiveTab={handleOnChangeActiveFlatId}
        tabPaneContainerClassName="device-log__tab-pane-container"
        tabPaneFieldTitleClassName="device-log__tab-pane-text"
        tabs={tabs}
        emptyText="По вашему запросу ничего не найдено"
        loading={objectsLoading || sendContactsLoading || updateContactsDataLoading}
      />
      {!(!tabs?.length || objectsLoading || sendContactsLoading || updateContactsDataLoading) && (
        <ContactsForSubscribers
          contacts={contacts}
          buildings={buildings?.items || []}
          permissions={permissions}
          objectId={activeTabId}
          updateContacts={updateContacts}
          onDeleteContact={tryToDeleteContact}
          getContact={getContact}
        />
      )}
      <TabNavButtons
        buttons={tabNavButtonsDefault(
          {
            isHidden: !permissions.edit,
            text: 'Отмена',
            callBack: setInitialContacts,
            disabled: !wasChange,
          },
          {
            isHidden: !permissions.edit,
            text: 'Сохранить',
            callBack: tryToSaveContacts,
            disabled: !wasChange,
          },
          { isHidden: true }
        )}
      />
    </div>
  );
};

export default ContactsSection;
