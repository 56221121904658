export interface IConnectionId {
  connectionId: string;
  connectionToken: string;
  negotiateVersion: number;
  availableTransports: any[];
}

export interface IDisableNotificationsStatus {
  isAllDisabled: boolean | null;
  isDisableExist: boolean | null;
  nextEvent?: number | null;
}

export enum ENotifyWebSocketEvents {
  connected = 'Connected',
  connectToCompanyResult = 'ConnectToCompanyResult',
  errorsCount = 'ErrorsCount',
  statusEvent = 'StatusEvent',
  notification = 'Notification',
}

export interface INotifyWebSocketData {
  status: string;
  statusDisplayMessage: string;
  text: string;
  objectId: string;
  deviceId: string;
  eventId: string;
  id: string;
  notificationId: string;
  type: number;
  ignoredForUsers: string[];
  errorsCount: number;
}

export interface INotifyWebSocketEvent {
  type: number;
  target: ENotifyWebSocketEvents;
  arguments: INotifyWebSocketData[];
}

export enum ENotifyType {
  status = 'status',
  reminder = 'reminder',
}
