import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { getSystem } from '../../../api/systems';
import CommonHead from '../../../components/commonHead';
import ArrowBackIcon from '../../../assets/svg/icons/arrowBack';
import Tabs from '../../../components/tabs';
import { EAvailableTabs, IAvailableTabs, ITab } from '../../../components/tabs/types';
import { paths } from '../../../constants/paths';
import { useAppDispatch, useAppSelector } from '../../../hooks/hooks';
import { useApi } from '../../../hooks/useApi';
import { getProfilePermission, getSettingsInStore } from '../../../store/selectors/profile';
import { setHeaderTitle } from '../../../store/slices/header';
import { ESidebarItemIds } from '../../../typings/sidebar';
import { ESystemBaseDeviceSeries, ISystemBase } from '../../../typings/systems/base';
import { getAvailableTabsUrl, getObjectBaseUrl } from '../../../constants/api';
import ButtonLink from '../../../components/ui/buttonLink';
import Loader from '../../../components/ui/loader';
import { ELoaderColor } from '../../../components/ui/loader/types';
import { ESystemTabsIds } from './types';
import { EPageQueryParams } from '../../../typings/searchParams';
import { getWasChange } from '../../../store/selectors/changes';
import { setChange } from '../../../store/slices/changes';
import { tabs } from './config';

const SystemItem: FC = () => {
  const params = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useAppDispatch();
  const { data, sendRequest, loading: systemLoading } = useApi<ISystemBase>(getSystem);
  const {
    data: availableTabs,
    sendRequest: getAvailableTabs,
    loading: tabsLoading,
  } = useApi<IAvailableTabs | null>(getSystem);
  const [currentSystem, setCurrentSystem] = useState<ISystemBase | null>(null);
  const [clickedTab, setClickerTab] = useState<ITab | null>(null);
  const permissionObject = useAppSelector(getProfilePermission(ESidebarItemIds.objects));

  const isFiveThousandth = useMemo(
    () => currentSystem?.deviceSeriesId === ESystemBaseDeviceSeries.D5000,
    [currentSystem?.deviceSeriesId]
  );

  const systemTabs = useMemo(
    () =>
      tabs
        .filter((item) => (item.isOnlyFiveThousandth ? isFiveThousandth : true))
        .map((item, index) => {
          item.position = index;
          return item;
        }),
    [availableTabs, isFiveThousandth]
  );

  const [activeTab, setActiveTab] = useState<ITab>(
    systemTabs.find((tab) => tab.id === searchParams.get(EPageQueryParams.tabId)) || systemTabs[0]
  );

  const settings = useAppSelector(getSettingsInStore);

  const wasChange = useAppSelector(getWasChange);

  const setWasChange = useCallback(
    (value: boolean) => {
      dispatch(setChange(value));
    },
    [dispatch]
  );

  const updateAvailableTabs = useCallback(() => {
    getAvailableTabs(getAvailableTabsUrl(params.systemId || ''));
  }, [getAvailableTabs, params.systemId]);

  const getSystemData = useCallback(
    async (id = params.systemId) => {
      await sendRequest(getObjectBaseUrl(id || ''));
      if (!searchParams.get(EPageQueryParams.tabId)) {
        searchParams.set(EPageQueryParams.tabId, ESystemTabsIds.basic);
        setSearchParams(searchParams);
      }
      updateAvailableTabs();
    },
    [params.systemId, searchParams, sendRequest, setSearchParams, updateAvailableTabs]
  );

  useEffect(() => {
    sendRequest(getObjectBaseUrl(params.systemId || ''));
    if (!searchParams.get(EPageQueryParams.tabId)) {
      searchParams.set(EPageQueryParams.tabId, ESystemTabsIds.basic);
      setSearchParams(searchParams);
    }
    updateAvailableTabs();
  }, [params.systemId]);

  useEffect(() => {
    if (data) {
      setCurrentSystem({ ...data });
      dispatch(setHeaderTitle(data.shortName || ''));
    }
  }, [data]);

  const chancelChangeTab = useCallback(() => {
    setClickerTab(null);
  }, []);

  const repeatChangeTab = useCallback(() => {
    if (clickedTab) {
      setActiveTab(clickedTab);
      setChange(false);
      setClickerTab(null);
    }
  }, [clickedTab]);

  const onChangeTab = useCallback(
    (id: string, searchArr?: { name: string; val: any }[]) => {
      const newActiveTab = systemTabs.find((tab) => tab.id === id) || systemTabs[0];
      if (wasChange) {
        setClickerTab(newActiveTab);
      } else {
        setActiveTab(newActiveTab);
        searchParams.set(EPageQueryParams.tabId, newActiveTab.id);
        if (searchArr) {
          searchArr.forEach((item) => searchParams.set(item.name, item.val));
        }
        setSearchParams(searchParams);
      }
    },
    [systemTabs, wasChange, searchParams, setSearchParams]
  );

  useEffect(() => {
    if (availableTabs) {
      if (!availableTabs[(activeTab.availableTab || EAvailableTabs.isBaseAvailable) as keyof IAvailableTabs]) {
        onChangeTab(ESystemTabsIds.basic);
      }
    }
  }, [availableTabs]);

  const handleOnChangeNextTab = useCallback(() => {
    const newActiveTab = systemTabs.find((tab) => tab.position === activeTab.position + 1) || systemTabs[0];
    if (wasChange) {
      setClickerTab(newActiveTab);
    } else {
      setActiveTab(newActiveTab);
      searchParams.set(EPageQueryParams.tabId, newActiveTab.id);
      setSearchParams(searchParams);
    }
  }, [systemTabs, wasChange, activeTab.position, searchParams, setSearchParams]);

  const nextTabIsAvailable = useMemo(() => {
    const tab = systemTabs[activeTab.position + 1];
    if (tab && availableTabs) {
      return availableTabs[tab.availableTab as keyof IAvailableTabs];
    }
    return false;
  }, [activeTab.position, availableTabs, systemTabs]);

  if (!currentSystem) {
    return (
      <div className="system-item__loader-wrapper">
        <Loader color={ELoaderColor.blue} />
      </div>
    );
  }

  return (
    <>
      <CommonHead seo={{ title: currentSystem.shortName }} />
      <div className="system-item">
        <div className="system-item__button-wrapper">
          <ButtonLink url={paths.systems} leftIcon={<ArrowBackIcon />} content="Все объекты" />
        </div>
        <div className="system-item__content">
          {(systemLoading || tabsLoading) && (
            <div className="system-item__loader-wrapper">
              <Loader color={ELoaderColor.blue} />
            </div>
          )}
          {currentSystem && (
            <Tabs
              activeTabKey={activeTab.id}
              onChangeActiveTab={onChangeTab}
              tabs={systemTabs.map((tab) => ({
                ...tab,
                disabled: availableTabs ? !availableTabs[tab.availableTab as keyof IAvailableTabs] : false,
                children: (
                  <tab.component
                    isFiveThousandth={isFiveThousandth}
                    settings={settings}
                    getSystemData={getSystemData}
                    isWasChange={wasChange}
                    setChange={setWasChange}
                    systemId={params.systemId}
                    system={currentSystem}
                    onNextTab={handleOnChangeNextTab}
                    onChangeTab={onChangeTab}
                    tabId={tab.id}
                    activeTabKey={activeTab.id}
                    updateAvailableTabs={updateAvailableTabs}
                    isAccessGroupsCheck={!!availableTabs?.isAccessGroupsCheck}
                    repeatChangeTab={repeatChangeTab}
                    chancelChangeTab={chancelChangeTab}
                    clickedTab={clickedTab?.id}
                    permissions={permissionObject}
                    nextTabIsAvailable={nextTabIsAvailable}
                  />
                ),
              }))}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default SystemItem;
