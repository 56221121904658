import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import CommonHead from '../../../components/commonHead';
import { useAppDispatch, useAppSelector } from '../../../hooks/hooks';
import { setHeaderTitle } from '../../../store/slices/header';
import { useApi } from '../../../hooks/useApi';
import { feedbackSubjectsListUrl } from '../../../constants/api';
import { checkIsAdmin, getProfilePermission } from '../../../store/selectors/profile';
import { ESidebarItemIds } from '../../../typings/sidebar';
import InputSearch from '../../../components/ui/inputSearch';
import { getRequest } from '../../../api';
import CategorySections from '../../../components/categoryGuide/categorySections';
import CategoryTable from '../../../components/categoryGuide/categoryTable';
import { IFeedbackSubject } from '../../../typings/feedback';

const CategoryGuidePage: FC = () => {
  const dispatch = useAppDispatch();
  const isAdmin = useAppSelector(checkIsAdmin);

  const [activeCategory, setActiveCategory] = useState<string>('');

  const { data: subjects, sendRequest: getSubjects } = useApi<IFeedbackSubject[]>(getRequest);

  const permissions = useAppSelector(getProfilePermission(ESidebarItemIds.feedbackCategory));

  const [search, setSearch] = useState('');

  useEffect(() => {
    dispatch(setHeaderTitle('Категории обращений'));
  }, []);

  const requestSubjects = useCallback(
    async (categoryId = activeCategory, searchValue = search) => {
      if (categoryId) {
        await getSubjects(feedbackSubjectsListUrl(categoryId), { params: { search: searchValue } });
      }
    },
    [activeCategory, getSubjects, search]
  );

  useEffect(() => {
    requestSubjects();
  }, [activeCategory]);

  const handleOnSearch = useCallback(
    async (value: string) => {
      setSearch(value);
      await requestSubjects(activeCategory, value);
    },
    [activeCategory, requestSubjects]
  );

  const onChangeActiveSection = useCallback(
    (section: string) => {
      if (section !== activeCategory) {
        setActiveCategory(section);
      }
    },
    [activeCategory]
  );

  const blockDragAndDrop = useMemo(() => search !== '' || !permissions?.edit, [permissions?.edit, search]);

  return (
    <>
      <CommonHead seo={{ title: 'Категории обращений' }} />
      <div className="faq-subscribers">
        <div className="faq-subscribers__admin-filters">
          <InputSearch showClear placeholder="Поиск по категории и теме" value={search} onSearch={handleOnSearch} />
        </div>

        <div className="faq-subscribers__content">
          <CategorySections
            search={search}
            blockDragAndDrop={blockDragAndDrop}
            permissions={permissions}
            isAdmin={isAdmin}
            activeSection={activeCategory}
            setActiveSection={onChangeActiveSection}
          />
          <CategoryTable
            blockDragAndDrop={blockDragAndDrop}
            permissions={permissions}
            isSearch={!!search}
            isAdmin={isAdmin}
            activeCategory={activeCategory}
            requestSubjects={requestSubjects}
            subjects={subjects || []}
          />
        </div>
      </div>
    </>
  );
};

export default CategoryGuidePage;
